.login-left {
  display: inline-block;
  width: 38%;
  background: url("./../../../assets/images/login/side.png");
  height: 100vh;
  vertical-align: top;
  padding: 0px 40px;
  background-size: cover;
}
.login-logo {
  width: 180px;
  height: 62px;
  margin-top: 53px;
}
.login-company-descr {
  font-family: Poppins;
  font-size: 20px;
  letter-spacing: -0.7px;
  color: #ffffff;
  margin-top: 12px;
}

.login-right {
  display: inline-block;
  height: 100vh;
  vertical-align: top;
  width: 62%;
  background: white;
  padding: 40px 40px 4px 100px;
}
.right-signup-row {
  width: 100%;
  height: 50px;
}
.wrapper-signup-content {
  float: right;
}
.wrapper-signup-content span {
  font-family: Poppins;
  font-size: 14px;
  letter-spacing: -0.49px;
  text-align: center;
  color: #000000;
}
.button-get-started {
  width: 146px;
  height: 38px;
  border-radius: 30px;
  border: solid 1px #000000;
  background-color: #ffffff;
  font-family: Poppins;
  font-size: 14px;
  text-align: center;
  color: #000000;
  margin-left: 18px;
}
.login-header-wrapper {
  margin-top: 80px;
}
.login-header {
  width: 484px;
  height: 51px;
  font-family: Poppins;
  font-size: 36px;
  letter-spacing: -1.26px;
  color: #000000;
}
.login-header span {
  color: #fb6e00;
}
.login-header-sub {
  font-family: Poppins;
  font-size: 18px;
  letter-spacing: -0.63px;
  color: #000000;
}
.form_login {
  text-align: center;
}
.login-form-wrapper {
  width: 609px;
  max-width: 100%;
}

.login-input {
  width: 609px;
  max-width: 100%;
  height: 60px;
  border-radius: 5px;
  border: solid 1px #c7cad1;
  padding: 18px 18px;
  font-family: Poppins;
  font-size: 16px;
  color: #000000;
}
.login-input:focus {
  border: solid 1px #fb6e00;
}
.login-label {
  text-align: left;
  font-family: Poppins;
  font-size: 14px;
  letter-spacing: -0.49px;
  color: #000000;
  width: 100% !important;
  margin: 10px 0px;
}
.btn-login {
  width: 310px;
  height: 60px;
  border-radius: 30px;
  box-shadow: 0 2px 20px 4px rgba(251, 110, 0, 0.41);
  background-color: #fb6e00;
  border: none;
  display: inline-block;
  font-family: Poppins;
  font-size: 20px;

  text-align: center;
  color: #ffffff;
}
.login-form-fields-wrapper {
  text-align: left;
  margin: 40px 0px;
  text-align: center;
}
/* SIGNUP PAGE CSS AFTER THIS  */
.long {
  height: 818px !important;
}
.signup-header-wrapper {
}
.signup-header {
  font-family: Poppins;
  font-size: 23px;
  letter-spacing: -0.91px;
  color: #000;
}
.signup-header span {
  color: #fb6e00;
}
.div-inline {
  width: 45%;
  /* margin: 0px 20px; */
  display: inline-block;
}
.btn-signup {
  width: 310px;
  height: 60px;
  border-radius: 30px;
  box-shadow: 0 2px 20px 4px rgba(251, 110, 0, 0.41);
  background-color: #fb6e00;
  font-family: Poppins;
  font-size: 20px;
  text-align: center;
  color: #ffffff;
  border: none;
  margin: 20px 0px 100px 00px;
}
.label-terms {
  font-family: Poppins;
  font-size: 14px;
  letter-spacing: -0.49px;
  text-align: center;
  color: #000000;
  font-weight: 300;
}
/* Base for label styling
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 1.95em;
  cursor: pointer;
}

/* checkbox aspect */
/* [type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 1.25em;
  height: 1.25em;
  border: 2px solid #ccc;
  background: #fff;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
} */
/* checked mark aspect */
/* [type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
  content: "\2713\0020";
  position: absolute;
  top: 0.15em;
  left: 0.22em;
  font-size: 1.3em;
  line-height: 0.8;
  color: #fb6e00;
  transition: all 0.2s;
  font-family: "Lucida Sans Unicode", "Arial Unicode MS", Arial;
} */
/* checked mark aspect changes */
/* [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
[type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
} */
/* disabled checkbox */
/* [type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}
[type="checkbox"]:disabled:checked + label:after {
  color: #999;
}
[type="checkbox"]:disabled + label {
  color: #aaa;
}
/* accessibility */
[type="checkbox"]:checked:focus + label:before,
[type="checkbox"]:not(:checked):focus + label:before {
  border: solid 1px #fb6e00;
}
*/

/* hover style just for information */
/* label:hover:before {
  border: solid 1px #fb6e00;
} */

/* Useless styles, just for demo design */

.txtcenter {
  margin-top: 4em;
  font-size: 0.9em;
  text-align: center;
  color: #aaa;
}
*/ .copy {
  margin-top: 2em;
}
.copy a {
  text-decoration: none;
  color: #4778d9;
}
.signup-terms {
  margin-top: 30px;
  text-align: center;
}
.terms-label {
  font-family: Poppins;
  font-size: 14px;
  letter-spacing: -0.49px;
  text-align: center;
  color: #000000;
  font-weight: 300;
}
@media only screen and (max-width: 1100px) {
  .login-left {
    display: none;
  }
  .login-right {
    width: 100%;
    padding: 20px;
  }
  .login-header-wrapper {
    text-align: center;
  }
  .login-header {
    margin: auto;
  }
  .login-form-wrapper {
    margin: auto;
  }
}
@media only screen and (max-width: 500px) {
  .login-header span {
    display: block;
  }
  .login-header-wrapper {
    text-align: left;
    margin-top: 40px;
  }
  .login-header {
    margin: 0 auto;
    height: auto;
    font-size: 26px;
    letter-spacing: -0.91px;
  }
  .login-header-sub {
    font-size: 16px;
    letter-spacing: -0.56px;
  }
  .login-label {
    font-size: 14px;
    letter-spacing: -0.49px;
  }
  .login-input {
    font-size: 16px;
  }
  .btn-login {
    font-size: 20px;
  }
}
