.page747_live_rows > div {
  margin: 0px 0px 60px;
}
.live_draw_header_content {
  text-align: center;
  margin-bottom: 38px;
}
.live_draw_header_content > span {
  font-size: 75.6px;
  text-align: center;
  color: #ffffff;
}
.live_draw_header_content > h2 {
  font-size: 94.5px;
  font-weight: bold;
  color: #fb6e00;
}
.bg_black {
  background-color: black !important ;
  color: white !important;
}
.live_draw_content {
  width: 1100px;
  height: 650px;
}
.live_draw_content_left {
  width: 874px;
  float: left;
  margin-bottom: 80px;
}
.live_draw_content_left > div {
  margin-top: 13px;
}
.live_draw_winning_box_content {
  text-align: center;
  width: 874px;
}
.live_draw_winning_box_content > p {
  width: 215px;
  height: 33px;
  font-family: Poppins;
  font-size: 24px;
  color: #ffffff;
  text-align: left;
  margin-bottom: 10px !important;
}
.winning_box_numbers {
  margin: 0 auto;
  height: 105px;
  border-radius: 14px;
  background-color: #1a1a1a;
  border-radius: 23px;
  background-color: #1e1e1e;
  text-align: center;
}
.winning_box_circle {
  background-position: center;
  background-size: cover;
  width: 64.8px;
  height: 64.8px;
  line-height: 64.8px;
  float: none;
  display: inline-block;
  margin: 24px 25px;
  font-family: Poppins;
  font-size: 26.3px;
  font-weight: 500;
  letter-spacing: -0.13px;
  text-align: center;
  color: #000000;
}
.winning_box_circle_active {
  width: 64.8px;
  height: 64.8px;
  line-height: 64.8px;
  float: left;
  margin: 20px 25px;
  font-family: Poppins;
  font-size: 26.3px;
  font-weight: 500;
  letter-spacing: -0.13px;
  text-align: center;
  color: #fb6e00;
}
.live_draw_in_play {
  width: 238px;
  float: left;
  text-align: left;
  height: 230px;
}
.live_draw_in_play > p {
  width: 107px;
  height: 33px;
  font-family: Poppins;
  font-size: 24px;
  text-align: left;
  color: #ffffff;
  margin-bottom: 10px !important;
}
.live_draw_in_play_timer {
  padding: 10px 0px;
  width: 238px;
  height: 194px;
  border-radius: 14px;
  background-color: #1a1a1a;
}
.live_draw_in_play_circle {
  width: 86.4px;
  height: 86.4px;
  border: solid 1.9px #fb6e00;
  background-color: #ffffff;
  line-height: 86.4px;
  border-radius: 43.2px;
  margin: 20px auto;
  font-size: 46.1px;
  font-weight: 500;
  letter-spacing: -0.23px;
  text-align: center;
  color: #fb6e00;
  text-align: center;
  margin-bottom: 10px;
}
.live_draw_in_play_text {
  font-family: Poppins;
  font-size: 24px;
  color: #ffffff;
  text-align: center;
}
@font-face {
  font-family: "Orbitron";
  font-style: normal;
  font-weight: 400;
  src: local("Orbitron Regular"), local("Orbitron-Regular"),
    url(https://fonts.gstatic.com/s/orbitron/v10/yMJRMIlzdpvBhQQL_Qq7dy0.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

.live_draw_in_play_time {
  font-family: "Orbitron";
  font-size: 84px;
  letter-spacing: -0.4px;
  text-align: center;
  color: #fb6e00;
}
.live_draw_in_play_time > span {
  font-size: 19.5px;
  text-align: center;
  color: #ffffff;
}

.live_draw_my_numbers {
  margin-left: 20px;
  float: left;
  width: 615px;
  text-align: left;
  height: 230px;
}

.live_draw_my_numbers > p {
  width: 155px;
  height: 33px;
  font-family: Poppins;
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 10px;
  margin-bottom: 10px !important;
}
.live_draw_my_numbers span {
  font-size: 30px;
  font-weight: bold;
  color: #fb6e00;
}
.live_draw_my_numbers_edit {
  width: 615px;
  height: 195px;
  border-radius: 14px;
  background-color: #1a1a1a;
  padding-top: 20px;
}

.mleft_top_text {
  height: 24px;
  font-family: Poppins;
  font-size: 17px;
  text-align: center;
  color: #fb6e00;
  margin-top: 20px;
}
.edit_numbers {
  width: 560px;
  max-width: 100%;
  margin: 20px auto;
}
.edit_numbers .edit_numbers_circle {
  margin: 4px 10px;
  float: none;
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 24px;
  border-radius: 29px;
  font-weight: 500;
  letter-spacing: -0.1px;
  text-align: center;
  color: #000000;
  cursor: pointer;
}
.pick_button_active {
  border: 3px solid #fb6e00;
  line-height: 54px !important;
}
.edit_numbers .edit_numbers_circle_active {
  pointer-events: none;
  margin: 4px 10px;
  float: none;
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  padding: 5px;
  line-height: 50px;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.1px;
  text-align: center;
  color: rgba(0, 0, 0, 0.48);
}
.edit_numbers_circle_active img {
  width: 18px;
  height: 22px;
  display: block;
  position: absolute;
  margin-left: 20px;
  margin-top: -7px;
}
.circle_disabled {
  filter: grayscale(100%);
  background: #333333 !important;
}
.circle_disabled .inner-div-select {
  background: #333333 !important;
  border-radius: 30px;
}
.live_draw_my_numbers_notes {
  width: 519px;
  height: 26px;
  opacity: 0.46;
  font-family: Poppins;
  font-size: 18px;
  line-height: 1.44;
  text-align: center;
  color: #ffffff;
  margin: 0 auto !important;
}
.live_draw_my_numbers_matched {
  font-size: 24px;
  text-align: center;
  color: #ffffff;
  text-align: center;
}
.live_draw_my_numbers_matched span {
  font-size: 30px;
  font-weight: bold;
  color: #fb6e00;
}
.live_draw_my_prize_wrapper {
  width: 873px;
  float: left;
  text-align: left;
  padding-top: 15px;
}
.live_draw_my_prize_wrapper > p {
  width: 107px;
  height: 33px;
  font-family: Poppins;
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 10px !important;
}
.live_draw_my_prize {
  width: 873px;
  height: 175px;
  border-radius: 14px;
  background-color: #1a1a1a;
  padding-top: 40px;
  text-align: center;
}
.live_draw_my_prize_won_text {
  height: 60px;
  font-family: Poppins;
  font-size: 43.2px;
  font-weight: bold;
  text-align: center;
  color: #fb6e00;
}
.live_draw_my_prize.winner {
  width: 873px;
  height: 296px;
  border-radius: 14px;
  background-color: #1a1a1a;
}
.live_draw_winner_left {
  margin-top: 15px;
  margin-left: 165px;
  float: left;
  width: 107px;
  height: 107px;
}
.live_draw_winner_note {
  width: 408px;
  height: 33px;
  font-family: Poppins;
  font-size: 24px;
  color: #28c105;
  text-align: left;
  margin-top: -10px !important;
}
.live_draw_winner_note2 {
  width: 408px;
  height: 52px;
  font-family: Poppins;
  font-size: 18px;
  text-align: left;
  color: #ffffff;
  margin-top: 10px !important;
}
.live_draw_winner_note3 {
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  font-style: italic;
  color: #ffffff;
  float: left;
  width: 275px;
}
.live_draw_winner_note4 {
  height: 26px;
  font-family: Poppins;
  font-size: 16px;
  line-height: 1.63;
  text-align: center;
  color: #ffffff;
  margin-top: 20px !important;
  margin-left: -120px !important;
}
.live_draw_winner_note5 {
  height: 31px;
  font-family: Poppins;
  font-size: 18px;
  color: #ffffff;
  text-align: left;
}
.button_pickNumbers {
  width: 434px;
  height: 60px;
  border-radius: 30px;
  box-shadow: 0 2px 18px -1px #fb6e00;
  background-color: #fb6e00;
  font-family: Poppins;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  border: none;
  margin-top: 10px;
}
.live_draw_winner_right {
  float: left;
  margin-left: 20px;
}
.mright_powerplays {
  width: 205px;
  height: 598px;
  border-radius: 23px;
  box-shadow: -5px 2px 12px 0 rgba(0, 0, 0, 0.5);
  background-color: #1a1a1a;
  padding-top: 70px;
}
.mright_top_text {
  width: 178px;
  height: 33px;
  font-family: Poppins;
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 10px !important;
}
.mright_powerplays_item {
  width: 147px;
  height: 61px;
  border-radius: 11px;
  background-color: #0f0f0f;
  margin: 0px 0px 40px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mright_powerplays_item .item_img_wrapper {
  float: left;
  width: 44px;
  height: 44px;
  cursor: pointer;
}
.item_img_wrapper img {
  width: 100%;
}
.mright_powerplays_item .item_right {
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mright_powerplays_item .item_right > p {
  width: 68px;
  font-family: Poppins;
  font-size: 14px;
  color: #ffffff;
}
.mright_powerplays_item .lotto_live_powerplay_amount {
  width: 32px;
  height: 32px;
  line-height: 32px;
  background-color: #fb6e00;
  border-radius: 16px;
  font-weight: bold;
  float: right;
  color: #fff;
  margin-right: -19px;
}

.draw_wrapper {
  width: 1140px;
}
.page747_live_draw_timer {
  font-family: Poppins;
  font-size: 16px;
  color: #ffffff;
}
.page747_main_draw_inner {
  width: 160px;
}
.live_draw_result {
  width: 447px;
  margin: 0 auto;
}
.live_draw_result_top {
  text-align: center;
  margin-top: -20px;
}
.live_draw_loser_note {
  height: 26px;
  font-family: Poppins;
  font-size: 18px;
  line-height: 1.44;
  text-align: center;
  color: #e7304e;
}
.live_draw_loser_note4 {
  height: 26px;
  font-family: Poppins;
  font-size: 16px;
  line-height: 1.63;
  text-align: center;
  color: #ffffff;
}
.live_draw_loser_button {
  width: 434px;
  height: 60px;
  border-radius: 30px;
  box-shadow: 0 2px 18px -1px #fb6e00;
  background-color: #fb6e00;
  font-family: Poppins;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  border: none;
}
.live_draw_loser_note5 {
  height: 31px;
  font-family: Poppins;
  font-size: 18px;
  color: #ffffff;
}
.live_draw_result_left {
  float: left;
  width: 107px;
  height: 107px;
}
.live_draw_result_right {
  float: left;
  margin-left: 15px;
  text-align: left;
}
.live_draw_prize_matches {
  font-family: Poppins;
  font-size: 24px;
  text-align: left;
  color: #ffffff;
  margin-top: -5px !important;
  text-align: center !important;
}
.live_draw_prize_matches span {
  font-size: 30px;
  font-weight: bold;
  color: #fb6e00;
}
.img-checked {
  position: absolute;
  display: block;
  width: 17px !important;
  height: 17px !important;
  margin: -14px 0px 0px 15px !important;
}
.edit_numbers_circle_active.pick_button_active {
  border: none !important;
}
.edit_numbers_circle_active.pick_button_active .inner-div-select {
  border: 3px solid #fb6e00;
  border-radius: 30px;
}
.inner-div-select {
  height: 60px;
  width: 60px;
  margin-left: -5px;
  padding-top: 2px;
}
.secs_draw {
  width: 275px !important;
  margin: 5px auto;
}
.secs_draw > div {
  font-size: 18.2px !important;
}
.secs_draw p {
  width: 54.6px !important;
  height: 44.8px !important;
  font-size: 35px !important;
}
.page747_main_prize {
  width: 1130px;
  margin: 0 auto;
}
.live_draw_content_right {
  float: left;
  margin-left: 20px;
  width: 205px;
  height: 650px;
}
.live_draw_numbers_text {
  height: 23px;
  font-family: Poppins;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 10px;
}
.circle_active {
  font-size: 19px !important;
  font-weight: 500 !important;
  letter-spacing: -0.1px !important;
  text-align: center !important;
  color: #fb6e00;
  margin-top: 20px;
  cursor: pointer;
}
.live_draw_in_play_timer_bottom {
  width: 238px;
  height: 34px;
  border-radius: 0px 0px 14px 14px;
  background-color: #333333;
  margin-top: 21px;
  font-family: Poppins;
  font-size: 12px;
  color: #ffffff;
  line-height: 16px;
  text-align: center;
}
.live_draw_in_play_timer_bottom span {
  color: #fb6e00 !important;
  font-size: 38px;
}
.live_draw_div_jackpot {
  text-align: center;
}
.live_draw_div_jackpot_header {
  height: 105px;
  font-family: Poppins;
  font-size: 75.6px;
  text-align: center;
  color: #ffffff;
}
.live_draw_div_jackpot_amount {
  height: 132px;
  font-family: Poppins;
  font-size: 94.5px;
  font-weight: bold;
  color: #fb6e00;
  margin: 0 auto;
}
.live_draw_div_jackpot_amount.small {
  font-size: 24.5px !important;
  height: 62px !important;
}
.live_draw_div_jackpot_odds {
  height: 36px;
  font-family: Poppins;
  font-size: 25.6px;
  text-align: center;
  color: #ffffff;
}
.live_draw_match_info.show {
  width: 231px;
  height: 28px;
  border-radius: 6px;
  background-color: rgba(40, 193, 5, 0.4);
  margin: 0 auto;
  line-height: 28px;
  text-align: center;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  display: block;
}
.live_draw_match_info.hide {
  display: none;
}
.live_draw_result_bottom img {
  float: left;
  width: 54px;
  height: 54px;
}
.live_draw_result_bottom {
  width: 150px;
  margin: 10px auto;
}
.header-live-draw {
  margin: 0 auto;
  display: block;
  max-width: 100%;
}
.live_draw_in_play_timer_bottom.text_only {
  line-height: 33px;
}
.draw_finished {
  background-color: #fb6e00;
  font-family: Poppins;
  font-size: 20px;
  color: #ffffff;
  text-transform: uppercase;
}
.circle_active.double_line {
  line-height: 20px;
  padding: 16px 0px;
}
.color_orange {
  color: #fb6e00;
}
.powerplay_box {
  width: 235px;
  height: 47.9px;
  text-align: center;
  position: absolute;
  /* margin-top: 75px; */
  border: 1px solid #fb6e00;
  border-radius: 15px;
  background: #0f0f0f;
}
.powerplay_box_wrapper {
  display: inline-block;
  border-radius: 15px;
}
.powerplay_box:before {
  content: "";
  position: absolute;
  bottom: 100%;

  border-width: 36px;
  margin-left: -36px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid #fb6e00;

  z-index: 1;
}
.box_0 {
  margin-left: -5px;
}
.box_0:before {
  left: 71px;
}
.box_1:before {
  left: 148px;
}
.box_2 {
  margin-left: 75px;
}
.box_2:before {
  left: 152px;
}
.box_3 {
  margin-left: 160px;
}
.box_3:before {
  left: 148px;
}
.box_4 {
  margin-left: 240px;
}
.box_4:before {
  left: 148px;
}
.box_5 {
  margin-left: 320px;
}
.box_5:before {
  left: 148px;
}
.box_6 {
  margin-left: 325px;
}
.box_6:before {
  left: 230px;
}
.powerplay_box img {
  cursor: pointer;
  width: 38px;
  height: 38px;
  object-fit: contain;
  box-shadow: 0 1px 12px 0 #000000;
  float: left;
  border-radius: 19px;
  margin: 5px 10px;
}
.prize-none {
  margin-top: -20px !important;
}
.prize-note {
  font-size: 10px;
  margin-top: -4px;
  font-style: italic;
  color: wheat;
}
.live_draw_button_show_result {
  font-family: Poppins;
  font-size: 18px;
  text-align: center;
  color: #000000;
  border: none;
  width: 144px;
  height: 38px;
  border-radius: 30px;
  box-shadow: 0 2px 18px -1px #212121;
  background-color: #ffffff;
}
.results_wrapper {
  width: 420px;
  height: 25px;
}
.live_draw_button_show_result.winner {
  float: left;
}
.live_draw_my_numbers_notes.clickable {
  cursor: pointer;
  text-decoration: underline;
}
.play_agian_button {
  color: wheat;
  text-decoration: underline;
  cursor: pointer;
  font-size: 17px;
}
.with_gif {
  background-image: url("./../../assets/images/747_live/loader.gif");
  background-position: center;
  background-size: cover;
  /* border: none; */
  /* background-color: transparent; */
  background-origin: content-box;
  padding: 25px;
  background-repeat: no-repeat;
}
.with_replace {
  background-image: url("./../../assets/images/747_live/refresh.png");
  background-position: center;
  background-size: cover;
  /* border: none; */
  /* background-color: transparent; */
  background-origin: content-box;
  padding: 25px;
  background-repeat: no-repeat;
  cursor: pointer;
}
.mobile_view {
  display: none;
}
.powerplays_used_text {
  line-height: 47px;
  color: wheat;
}
.mright_powerplays_item:hover .tooltiptext {
  visibility: visible;
}
.tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: rgba(0, 0, 0, 0.9);
  color: rgba(255, 255, 255, 1);
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 999999;
  margin-left: -47px;
  margin-top: 50px;
}
.begin-text {
  line-height: 31px;
}
.partner_button {
  cursor: pointer;
  width: 235px;
  height: 52px;
  border-radius: 26px;
  box-shadow: 0 3px 27px -2px #fb6e00;
  background-color: #fb6e00;
  line-height: 50px;
  font-family: Poppins;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  margin: 15px auto 15px !important;
}
.lotto-button-partner.orange {
  cursor: pointer;
  width: 235px;
  height: 52px;
  border-radius: 26px;
  box-shadow: 0 3px 27px -2px #fb6e00;
  background-color: #fb6e00;
  line-height: 50px;
  font-family: Poppins;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  margin: 5px auto 15px !important;
  border: none;
}
.match_text {
  margin: 10px 0px;
  color: wheat;
}
.match_text span {
  color: #fb6e00;
  font-weight: bold;
}

@media screen and (max-width: 700px) {
  .tooltiptext {
    font-size: 10px;
    margin-left: -76px !important;
    width: 150px;
  }
  .live_draw_my_numbers {
    width: 100%;
    margin: 0px;
    float: none;
  }
  .live_draw_div_jackpot {
    border-radius: 20px;
    box-shadow: 0 2px 24px 7px rgba(0, 0, 0, 0.32);
    background-color: #1e1e1e;
    width: 327px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding: 40px 0px;
    color: grey;
  }
  .live_draw_div_jackpot_header {
    font-size: 16px;
    height: unset;
    text-align: center;
    color: #7f7f7f;
  }
  .live_draw_div_jackpot_amount {
    height: unset;
  }
  .live_draw_div_jackpot_amount.small {
    font-size: 20px !important;
  }
  .live_draw_div_jackpot_odds {
    font-size: 16px;
    text-align: center;
    color: #ffffff;
  }
  .mobile_view {
    display: block;
  }
  .desktop {
    display: none;
  }
  .live_draw_my_numbers > p {
    text-align: center;
    width: unset;
    font-size: 18px;
    margin-top: 25px !important;
  }
  .live_draw_my_numbers_edit {
    width: 319px;
    height: 170px;
    border-radius: 14px;
    background-color: #1a1a1a;
    margin: 0 auto;
    text-align: center;
  }
  .edit_numbers {
    width: 319px;
  }
  .mleft_top_text {
    display: none;
  }
  .edit_numbers .edit_numbers_circle {
    width: 44.1px;
    height: 44.1px;
    line-height: 43px;
    font-size: 17.5px;
    font-weight: 500;
    letter-spacing: -0.09px;
  }
  .edit_numbers .edit_numbers_circle_active {
    width: 44.1px;
    height: 44.1px;
    line-height: 40px;
    font-size: 17.5px;
    font-weight: 500;
    letter-spacing: -0.09px;
  }
  .pick_button_active {
    line-height: 40px !important;
  }
  .live_draw_in_play {
    text-align: unset;
    float: none;
    width: unset;
    height: unset;
  }
  .live_draw_in_play p {
    display: none;
  }
  .live_draw_in_play_timer {
    height: 80px;
    background-color: #1e1e1e;
    max-width: 100%;
    width: 360px;
  }
  .live_draw_numbers_text {
    display: inline-block;
    vertical-align: top;
    width: 72px;
    height: 39px;
    margin: 12px 12px 12px 0px;
    font-size: 16px;
    overflow: hidden;
  }
  .live_draw_in_play_circle {
    display: inline-block;
    vertical-align: top;
    width: 45px;
    height: 45px;
    font-size: 13.5px !important;
    line-height: 42px;
    margin: 12px 0px;
  }
  .live_draw_in_play_timer_bottom {
    display: inline-block;
    vertical-align: top;
    width: 184px;
    height: 40px;
    border-radius: 14px;
    border: solid 1px #fb6e00;
    line-height: 22px;
    margin: 12px 0px 12px 12px;
    font-size: 14px;
  }
  .live_draw_in_play_timer_bottom.text_only {
    line-height: 40px;
  }
  .live_draw_winning_box_content > p {
    width: unset;
    font-size: 18px;
    letter-spacing: -0.09px;
    text-align: center;
  }
  .live_draw_winning_box_content {
    width: unset;
    margin: 30px 0px;
  }
  .inner-div-select {
    height: 44px;
    width: 44px;
  }
  .img-checked {
    margin: -14px 0px 0px 8px !important;
  }
  .edit_numbers_circle_active img {
    margin-left: 13px;
  }
  .number_active {
    font-size: 20px !important;
    font-weight: bold;
  }
  .winning_box_numbers {
    width: 360px;
    background-color: #1e1e1e;
    padding: 12px 0px 6px;
    height: unset;
  }
  .winning_box_circle {
    width: 41.5px;
    height: 41.5px;
    font-size: 16.5px;
    font-weight: 500;
    letter-spacing: -0.08px;
    line-height: 40px;
    display: inline-block;
    margin: 3px;
  }
  .live_draw_prize_matches {
    background-color: #1e1e1e;
    width: 319px;
    font-size: 16px;
    padding: 5px 0px 15px 0px;
    border-radius: 0px 0px 23px 23px;
    margin-top: -26px !important;
  }
  .live_draw_prize_matches span {
    font-size: 17px;
  }
  .mright_top_text {
    font-size: 18px;
    margin-top: 30px !important;
  }
  .mright_powerplays {
    width: 319px;
    height: unset;
    border-radius: 14px;
    background-color: #1a1a1a;
    padding-top: 20px;
    margin-bottom: 30px;
  }
  .mright_powerplays_item {
    float: none;
    display: inline-block;
    width: 80px;
    background: transparent;
    vertical-align: top;
  }
  .mright_powerplays_item .item_img_wrapper {
    float: none;
  }
  .mright_powerplays_item .item_right {
    float: none;
  }
  .mright_powerplays_item .lotto_live_powerplay_amount {
    width: 19.4px;
    height: 19.4px;
    font-size: 14px;
    line-height: 19.4px;
    font-weight: 300;
    margin-top: -90px;
    margin-left: 46px;

    position: absolute;
    z-index: 22;
  }
  .with_replace {
    padding: 10px;
  }
  .with_gif {
    padding: 10px;
  }
  .powerplay_box {
    position: absolute;
    z-index: 10;
  }
  .box_0 {
    margin-left: 22px;
  }
  .box_1 {
    margin-left: 10px;
  }
  .box_2 {
    margin-left: 70px;
  }
  .box_3 {
    margin-left: 136px;
  }
  .box_4 {
    margin-left: -22px;
  }
  .box_5 {
    margin-left: 41px;
  }
  .box_6 {
    margin-left: 26px;
  }
  .begin-text {
    line-height: 40px !important;
  }
  .live_draw_match_info.show {
    width: 231px;
    height: 28px;
    border-radius: 6px;
    background-color: rgba(40, 193, 5, 0.4);
    margin: 53px auto 0px;
    line-height: 28px;
    text-align: center;
  }
}

/* ANIMATIONS */
.animation_pulse {
  box-shadow: 0 0 0 0 rgba(251, 110, 0, 1);
  animation: pulse 2s infinite;
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(251, 110, 0, 0.4);
    box-shadow: 0 0 0 0 rgba(251, 110, 0, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 40px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
#tourstep1 {
  width: 180px;
  height: 480px;
  position: absolute;
  /* left: 186px; */
  margin-left: 14px;
  margin-top: 100px;
  z-index: -1;
}
#tourstep2 {
  width: 72px;
  height: 75px;
  position: absolute;
  margin-left: 82px;
  bottom: 301px;
  z-index: -1;
}
#tourstep3 {
  width: 37px;
  height: 25px;
  position: absolute;
  margin-left: 172px;
  bottom: 254px;
}
#tourstep4 {
  width: 243px;
  height: 50px;
  position: absolute;
  margin-left: 19px;
  margin-top: 178px;
}
