.page747_rows > div {
  margin: 0px 0px 70px 0px;
}
.page747_main_bar {
  margin: 0 auto;
  width: 950px;
  max-width: 90%;
  min-height: 130px;
  border-radius: 102px;
  box-shadow: 0 2px 24px 7px rgba(0, 0, 0, 0.32);
  background-color: #1e1e1e;
  font-family: Poppins;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
}
.page747_main_bar > div {
  background: transparent;
  margin: 0 auto;
  height: 130px;
  display: table;
}
.main_bar_inner_bigger {
  font-size: 45px;
  font-weight: bold;
  color: #fb6e00;
  width: 100%;
  height: 32px;
  border-right: 1px solid #979797;
}
.main_bar_inner {
  margin: 0 auto !important;
  max-height: 60px;
  line-height: 30px;
  display: table-cell;
  vertical-align: middle;
}
.main_bar_button {
  width: 100%;
}
.main_bar_button > button {
  margin-top: -24px;
  width: 458px;
  height: 65px;
  border-radius: 32.5px;
  background-color: #fb6e00;
  font-family: Poppins;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  border: none;
}
.main_bar_button > button:hover {
  box-shadow: 0 2px 18px -1px #fb6e00;
}
.main_bar_inner > div {
  vertical-align: middle;
  border-right: none !important;
  line-height: 28px !important;
}

.page747_main_lower_text {
  text-align: left !important;
  margin-left: 47px;
  font-family: Poppins;
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 40px;
}
.page747_main_lower_text s {
  font-weight: bold !important;
}
.page747_main_left_lower_text {
  font-family: Poppins;
  font-size: 16px;
  color: #ffffff;
  margin-top: 10px;
}
.page747_main_left_lower_text s {
  font-weight: bold !important;
}
.page747_main_draw_box {
  text-align: left !important;
  margin-left: 55px;
  margin-top: 5px;
  text-align: center;
  line-height: 30px;
  width: 286px;
  border-radius: 22px;
  font-size: 24px;
  color: #ffffff;
  font-family: Poppins;
  font-size: 16px;
  color: #ffffff;
}
.page747_main_draw_box > div > p {
  margin-left: -3px !important;
}
.page747_main_draw_inner > div {
  float: left;
  margin: 0px 7px 0px 7px;
  font-size: 13px;
}
.page747_main_draw_inner p {
  width: 39px;
  height: 32px;
  background-color: #000000;
  font-family: Poppins;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
}
@media only screen and (max-width: 850px) {
  .main_bar_inner > div {
    margin: 0px;
    border-right: none;
    border-bottom: solid 1px #979797;
  }
}
.page747_next_draw_time {
  line-height: 20px;
  width: 286px;
  margin: 0 auto;
  text-align: center;
}
.page747_next_draw_time > div {
  margin: 12px;
  display: inline-block;
  text-align: center;
}
.page747_next_draw_time span {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  border: none;
}
.main_bar_inner > button {
  width: 220px;
  height: 46px;
  border-radius: 23px;

  background-color: #fb6e00;
  border: none;
  font-weight: bold;
  text-align: center;
}
.main_bar_inner > button:hover {
  box-shadow: 0 2px 18px -1px #fb6e00;
}
.main_prize_inner {
  margin: 0 auto;
  width: 80%;
  font-family: Poppins;
  font-size: 16px;
  color: #ffffff;
}
.page747_main_prize > div {
  background: transparent;
  margin: 0 auto;
  display: inline-block;
}
.page747_prize_details {
  position: relative;
  margin-left: 30px !important;
  height: 314px;
  width: 800px;
  max-width: 100%;
}
.page747_prize_header span {
  font-size: 58px;
  font-weight: 900;
  letter-spacing: -0.3px;
  color: #ffffff;
  padding: 30px;
}
.page747_prize_image-wraper {
  display: table;
}
.page747_prize_image-wraper img {
  width: 300px;
  height: 314px;
  object-fit: contain;
}
@media only screen and (max-width: 1062px) {
  .page747_prize_image-wraper {
    display: none !important;
  }
}
.page747-prize-box-wrapper {
  display: inline-block;
  width: 22%;
  min-width: 185px;
  margin: 10px;
}

@media only screen and (max-width: 900px) {
  .page747-prize-box-wrapper {
    display: inline-block;
    width: 33%;
    min-width: 185px;
    margin: 10px;
  }
}
@media only screen and (max-width: 400px) {
  .page747-prize-box-wrapper {
    display: inline-block;
    width: 80%;
    min-width: 185px;
    margin: 10px;
  }
}
.page747-prize-box {
  width: 185px;
  height: 90px;
  border-radius: 17px;
  background-color: #1e1e1e;
  display: table-cell;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  vertical-align: middle;
  text-align: center;
}
.page747-prize-box span {
  font-size: 32px;
  font-weight: bold;
  color: #fb6e00;
}
.page747_prize_content {
  max-width: 100%;
}
.page747_prize_note {
  font-size: 20px;
  max-width: 100%;
  letter-spacing: -0.5px;
  color: #ffffff;
  padding: 30px;
}
.page747_power_content{
  text-align: center;
}
.page747_main_power > div {
  background: transparent;
  margin: 0 auto;
  float: left;
}
.page747_power_image-wraper {
  display: table;
}
.page747_power_image-wraper img {
  width: 300px;
  height: 314px;
  object-fit: contain;
  display: inline-block;
}

.page747_power_details {
  position: relative;
  margin-left: 30px !important;
  width: 800px;
  max-width: 100%;
}
@media only screen and (max-width: 900px) {
  .page747_power_image-wraper {
    display: none;
  }
  .page747_power_details {
    margin-left: 0px !important;
  }
}
.page747_power_heading {
  font-size: 58px;
  font-weight: 900;
  letter-spacing: -0.3px;
  color: #ffffff;
}
.power_content_box {
  display: inline-block;
  width: 380px;
  margin-top: 30px;
  float: none;
  margin-top: 20px;
}

.power_content_box_left {
  width: 160px;
  height: 166px;
  border-radius: 16px;
  background-color: #1e1e1e;
  margin: 0 auto;
  float: left;
}
.power_content_box_left_image_wrapper {
  width: 100%;
  height: 120px;
  object-fit: contain;
  padding-top: 30px;
  border-bottom: solid 1px #979797;
}
.power_content_box_left_image {
  width: 80px;
  margin: 0 auto;
}
.power_content_box_left_text {
  margin: 2px;
  font-family: CenturyGothic;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.2px;
  text-align: center;
  color: #ffffff;
}
.power_content_box_right {
  text-align: left;
  margin-right: 10px;
  margin-top: 20px;
  height: 120px;
  font-family: CenturyGothic;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.3px;
  color: #ffffff;
  max-width: 200px;
  float: left;
  margin-left: 10px;
}
.page747_number_header {
  font-size: 58px;
  font-weight: 900;
  letter-spacing: -0.3px;
  text-align: center;
  color: #ffffff;
  margin-top: 100px;
}
.page747_number_rules {
  margin: 20px auto 30px auto;
  width: 434px;
  height: 60px;
  border-radius: 30px;
  border: solid 2px #ffffff;
  font-family: Poppins;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  line-height: 55px;
  text-align: center;
}
.page747_number_box {
  margin: 0 auto;
  padding: 10px;
  text-align: center;
  line-height: 30px;
  width: 286px;
  height: 162px;
  border-radius: 22px;
  font-size: 24px;
  color: #ffffff;
}
.page747_next_draw_box {
  margin: 20px;
  font-size: 19.5px;
  text-align: center;
  color: #ffffff;
}
.page747_next_draw_box > div {
  display: inline-block;
  margin: 5px;
}
.page747_next_draw_box p {
  padding: 10px;
  width: 59px;
  height: 48px;
  background-color: #1e1e1e;
  font-size: 37.5px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
}
.page747_selection_box {
  max-width: 100%;
  border-radius: 23px;
  background-color: #1e1e1e;
  text-align: center;
}
.page747_selection_button {
  width: 62px;
  height: 62px;
  line-height: 62px;
  -webkit-border-radius: 31px;
  -moz-border-radius: 31px;
  border-radius: 31px;
  background: #fff;
  font-size: 25px;
  font-weight: 500;
  letter-spacing: -0.1px;
  vertical-align: middle;
  text-align: center;
  color: #000000;
  box-shadow: 0 2px 18px -1px #979797;
  cursor: pointer;
  margin: 20px;
  display: inline-block;
}
.page747_selection_box_submit {
  cursor: pointer;
  margin: -0px auto;
  font-size: 20px;
  padding: 15px 10px;
  width: 434px;
  height: 60px;
  border-radius: 30px;
  background-color: #fb6e00;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  border: none;
}
.deactivate {
  pointer-events: none;
  filter: grayscale(100%);
  margin: 0px auto !important;
}
.page747_selection_box_submit:hover {
  box-shadow: 0 2px 18px -1px #fb6e00;
}
.page747_selection_box_submit_info {
  font-family: Poppins;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  margin: -85px auto -10px auto;
  width: 209px;
  height: 39px;
  line-height: 30px;
  border-radius: 11px;
  background-color: #000000;
}
.button_show_prize_wrapper {
  float: right;
  margin: 18px 270px 0px 0px;
}
.button_show_prize {
  width: 201px;
  height: 48px;
  border-radius: 24px;
  border: solid 2px #ffffff;
  background: none;
}
.modal-confirm .modal-content {
  width: 753px !important;
 
}
.modal-confirm .modal-header {
  width: 752px !important;
}
.confirm_submit_header {
  width: 164px;
  height: 36px;
  font-family: Poppins;
  font-size: 26px;
  font-weight: 500;
  color: #030303;
  margin: 0 auto;
}
.confirm_submit_numbers {
  padding: 20px 75px 40px;
  width: 753px;
  max-width: 100%;
  text-align: center;
}
.confirm_submit_circle {
  float: none;
  display: inline-block;
  width: 66.1px;
  height: 66.1px;
  line-height: 66px;
  font-family: Poppins;
  font-size: 26.3px;
  font-weight: 500;
  letter-spacing: -0.13px;
  text-align: center;
  color: #000000;
  margin: 8px;

}
.confirm_submit_footer {
  margin: 0 auto;
  width: 487px;
}
.footer_btn_cancel {
  width: 178px;
  height: 60px;
  border-radius: 30px;
  border: solid 2px #000000;
  background: #fff;
  font-family: Poppins;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #000000;
}
.footer_btn_submit {
  width: 294px;
  height: 60px;
  border-radius: 30px;
  box-shadow: 0 2px 18px -1px rgba(251, 110, 0, 0.5);
  background-color: #fb6e00;
  font-family: Poppins;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  border: none;
  text-transform: uppercase;
  margin-left: 15px;
}
.page747_main_power {
  width: 1166px;
  max-width: 100%;
  margin: 60px auto;
}
.pick_numbers_mobile_box {
  display: none;
}
@media screen and (max-width: 1175px) {
  .page747_power_image-wraper {
    display: none;
    float: none;
  }
  .page747_power_details {
    float: none !important;
    max-width: 100%;
    width: 100%;
  }
  .page747_power_heading {
    text-align: center;
    width: 100%;
  }
}

@media screen and (max-width: 700px) {
  .page747_main_bar,
  .main_bar_button {
    display: none;
  }
  .page747_power_heading{
    font-size: 38px;
    font-weight: 900;
    letter-spacing: -0.19px;
    text-align: center;
  }
  .pick_numbers_mobile_box {
    display: block;
    width: 327px;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 2px 24px 7px rgba(0, 0, 0, 0.32);
    background-color: #1e1e1e;
    margin: 0 auto !important;
    text-align: center;
  }
  .mobile_box_text_1 {
    font-family: Poppins;
    font-size: 19px;
    text-align: center;
    color: #7f7f7f;
  }
  .mobile_box_text_2 {
    font-family: Poppins;
    font-size: 19px;
    font-weight: bold;
    line-height: 1.4;
    color: #fb6e00;
    margin-bottom: 20px !important;
  }
  .mobile_box_text_2b {
    font-family: Poppins;
    font-size: 45px;
    font-weight: bold;
    line-height: 1.4;
    color: #fb6e00;
  }
  .mobile_box_text_3 {
    font-family: Poppins;
    font-size: 16px;
    margin: 10px !important;
    color: #ffffff;
  }
  .mobile_box_text_3 span {
    font-weight: bold;
  }
  .mobile_box_text_4 {
    font-family: Poppins;
    font-size: 16px;
    text-align: center;
    color: #7f7f7f;
    margin-top: 20px !important;
  }
  .mobile_box_text_5 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    margin-top: 10px !important;
  }
  .mobile_box_text_6 {
    font-family: Poppins;
    font-size: 16px;
    color: #7f7f7f;
    margin-top: 30px !important;
  }
  .mobile_box_text_7 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: bold;
    color: #fb6e00;
    margin-bottom: 20px !important;
  }
  .mobile_box_button {
    width: 284px;
    height: 52px;
    border-radius: 32.5px;
    box-shadow: 0 2px 18px -1px #fb6e00;
    background-color: #fb6e00;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    border: none;
    position: absolute;
    margin-left: -140px;
  }
  .page747_number_header {
    font-size: 38px;
    font-weight: 900;
    line-height: 1.24;
    letter-spacing: -0.19px;
    text-align: center;
  }
  .page747_number_rules {
    width: 75%;
  }
  .page747_selection_button {
    margin: 6px;
    width: 44.1px;
    height: 44.1px;
    font-size: 17.5px;
    font-weight: 500;
    letter-spacing: -0.09px;
    line-height: 44.1px;
  }
  .page747_selection_box_submit{
    width: 75%;
  }
  .btn-active{
    line-height: 40.1px !important;
  }
  .modal-header, .modal-content{
    max-width: 100%;

  }
  .confirm_submit_numbers{
      padding: 0px;
    
  }
  .confirm_submit_circle{
    width: 44.1px;
    height: 44.1px;
    font-size: 17.5px;
    font-weight: 500;
    line-height: 44.1px;
    display: inline-block;
    float: none;

  }
  .confirm_submit_footer {
    width: 305px;
  }
  .footer_btn_cancel{
    width: 90px;
    height: 45px;
    font-size: 15px;
  }
  .footer_btn_submit{
    width: 200px;
    height: 45px;
    font-size: 15px;
  }
  .page747_power_content{
    width: 100%;
    text-align: center;
  }
  .power_content_box{
    max-width: 100%;
  
    margin: 0 10%;
  }
  .power_content_box_left{
    width: 160px;
    float: left;
  }
  .power_content_box_right{
    width: 165px;
    float: left;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: -0.3px;
  
  }
}
