.__about-us-page{
    .__map{
        padding: 2.5em 0;
        background: url('../../assets/images/about_us/map.jpg') right bottom;
        background-size: cover;
        .__title{
            font-size: 2.25em;
            font-weight: bolder;
            text-transform: uppercase;
            margin-bottom: 1.4em;
        }
        >.__container{
            padding: 2.4em 0;
            background: rgba($color: #111111, $alpha: 0.7);
            border: 1px solid #fb6e00;
            border-radius: 12px;
            text-align: center;
            max-width: 39.5em;
        }
        .__subtitle{
            font-size: 1.25em;
            &:last-child{
                margin-top: 0.2em;
            }
            svg{
                margin-right: 0.7em;
            }
        }
        .__subtitle-2{
            font-size: 1.13em;
            margin: 1.4em auto 2.5em auto;
            max-width: 23em;
        }
    }
}


@media (max-width: 600px){
    .__about-us-page{
        .__map{
            padding-bottom: 19em;
            background-position: 88% top;
            background-size: auto 200%;
            .__container{
                position: relative;
                padding: 4em 0;
            }
            .__title{
                font-size: 1.43em;
            }
            .__subtitle{
                font-size: 1.143em;
                svg{
                    height: 1em;
                    margin-right: 0.2em;
                }
            }
            .__subtitle-2{
                font-size: 1em;
                max-width: 16em;
                margin-bottom: 0;
            }
            .__btn{
                position: absolute;
                bottom: -1.8em;
                background: #fb6e00;
                border: none;
            }
        }
    }
}