.__home-page{
    .__viewport{
        background: url('../../assets/images/home/bitmap-copy@2x.jpg') bottom center;
        background-size: cover;
        text-align: center;
        position: relative;
        z-index: 1;
        .__container{
            flex-direction: column;
            height: 100%;
        }
        .__title{
            border-radius: 6px;
            background: #fb6e00;
            font-size: 2.25em;
            padding: 0.078em 0.5em;
            font-weight: bolder;
            text-transform: uppercase;
            line-height: normal;
            letter-spacing: -0.35px;
            text-shadow: 0 2px 14px rgba(0, 0, 0, 0.5);
            margin-top: 2em;
        }
        .__subtitle{
            font-size: 1.5em;
            margin: 2em;
            text-shadow: 0 2px 10px #000000, 0 2px 4px #000000;
            font-weight: 500;
        }
    }
    .__homepage-btn{
        background: #fb6e00;
        width: 220px;
        text-transform: uppercase;
        font-size: 1.25em;
        font-weight: 600;
        border-radius: 6px;
        border: 2px solid #fb6e00;
        height: 48px;
        line-height: 47px;
        position: relative;
        display: block;
        text-align: center;
    }
}

@media (max-width: 1000px){
    .__home-page{
        font-size: 14px;
        .__viewport{
            .__container{
                height: 100%;
            }
            .__title{
                font-size: 1.715em;
                margin: auto 0 2.5em 0;
            }
            .__subtitle{
                font-size: 1.27em;
                letter-spacing: -0.17px;
                margin: 0 0 auto 0;
                width: 100%;
                
            }
            .__homepage-btn{
                margin-top: 5em;
            }
        }
        .__homepage-btn{
            background: transparent;
            border-color: currentColor;
        }
    }
}

@media (max-width: 700px){
    .__home-page .__homepage-btn{
        font-size: 1em;
        margin-top: auto;
    }
}
@media (max-width: 350px){
    .__home-page{
        font-size: 13px;
    }
}