.nfl-draft-Sponsord {
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  margin-top: 90px;
}
.nfl-draft-NFL-Power-Draft {
  font-size: 36px;
  font-weight: 500;
  text-align: center;
  color: #fb6e00;
  padding: 20px;
}
.nfl-draft-buttons {
  text-align: center;
}
.nfl-draft-Rectangle-Copy {
  max-width: 191px;
  width: 191px;
  line-height: 38px;
  height: 42px;
  border-radius: 21px;
  border: solid 1px #ffffff;
  text-align: left;
  display: inline-block;
  text-align: center;
}
.nfl-draft-document {
  object-fit: contain;
  display: inline-block;
  margin-right: 5px;
}
.nfl-draft-trophy {
  display: inline-block;
  object-fit: contain;
  margin-right: 5px;
}
.nfl-draft-text-style-1 {
  font-weight: normal;
  color: #ffffff;
  text-align: center;
  padding: 20px;
}
.nfl-draft-Use-Your-Powerplays {
  font-size: 24px;
  font-weight: normal;
  text-align: center;
  color: #ffffff;
}

.nfl-draft-text {
  text-align: center;
  margin-top: 40px;
}
.nfl-draft-outer {
  max-width: 384px;
  height: 127px;
  border-radius: 9px;
  border: dashed 1px #ff7b00;
  width: 384px;
  margin-top: 50px;
  display: inline-block;
}
.nfl-draft-outerCenter {
  max-width: 271px;
  height: 29px;
  border-radius: 4px;
  background-color: #ffffff;
  margin-top: -16px;
  display: inline-block;
  width: 271px;
}
.nfl-draft-rd-Overall-Selectio {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: #000000;
}
.nfl-draft-text-style-outer {
  font-weight: 500;
  color: #ff7b00;
}
.nfl-draft-winner {
  font-size: 32px;
  font-weight: 400;
  text-align: center;
  color: #ff7b00;
  margin-top: 20px;
  margin-left: 8px;
}
.nfl-draft-text-style-4 {
  font-size: 14px;
  color: #ffffff;
}
.nfl-draft-Drafted-by {
  font-size: 22px;
  font-weight: normal;
  text-align: center;
  color: #ffffff;
}
.nfl-draft-live {
  display: inline-block;
  text-align: left;
  width: 490px;
  margin-right: 35px;
}
.nfl-draft-live_draft_team_White {
  font-size: 17px;
  color: #ffffff;
}
.nfl-draft-draft_manager_name {
  display: inline-block;
  font-size: 18px;
  letter-spacing: normal;
  color: #ffffff;
  max-width: 155px;
  overflow: hidden;
  vertical-align: top;
  max-height: 25px;
}
.nfl-draft-circle-wrapper {
  width: 68px;
  display: inline-block;
}
.nfl-draft-live_draft_circle {
  text-align: center;
  width: 26px;
  height: 26px;
  background-color: #ffffff;
  border-radius: 13px;
  display: inline-block;
}
.nfl-draft-circle {
  text-align: center;
  width: 26px;
  height: 26px;
  background-color: #ffffff;
  border-radius: 13px;
  display: inline-block;
  margin-right: 10px;
}

.nfl-draft-Live_Draft_Name {
  font-size: 18px;

  color: #ffffff;
}
.nfl-draft-live_draft_team {
  font-size: 18px;
  color: #424242;
  display: inline-block;
}
.nfl-draft-tbd {
  display: inline-block;
  font-size: 18px;
  letter-spacing: normal;
  color: #ffffff;
  width: 101px;
  border-radius: 3px;
  border: dashed 1px #424242;
  color: #424242;
  text-align: center;
  margin-right: 81px;
}
.nfl-draft-tbd2 {
  display: inline-block;
  font-size: 18px;
  letter-spacing: normal;
  color: #ffffff;
  width: 101px;
  height: 23px;
  border-radius: 3px;
  border: dashed 1px #424242;
  color: #424242;
  text-align: center;
  margin-right: 81px;
}
.nfl-draft-circleTBD {
  text-align: center;
  width: 26px;
  height: 26px;
  background-color: #ffffff;
  border-radius: 13px;
  display: inline-block;
  margin-right: 22px;
  margin-left: 26px;
}
.nfl-draft-num {
  font-size: 18px;
  text-align: center;
  color: #000000;
}
.nfl-draft-powerplay {
  width: 134px;
  display: inline-block;
  vertical-align: top;
  margin-top: 28px;
  text-align: left;
}

.nfl-draft-rectangle {
  width: 1363px;
  height: 85px;
  background-color: #1e1e1e;
}
.nfl-draft-Powerplay-Lotto {
  background-color: #1e1e1e;
  font-size: 18px;
  font-weight: 500;
  margin-top: 25px;
  color: #ffffff;
}
.nfl-draft-Powerplay-Live-Sport {
  background-color: #1e1e1e;
  font-size: 18px;
  font-weight: 500;
  margin-top: 25px;
  color: #ffffff;
}
.nfl-draft-Partner-With-Us {
  background-color: #1e1e1e;
  font-size: 18px;
  font-weight: 500;
  margin-top: 25px;
  color: #ffffff;
}
.nfl-draft-myGameCenter {
  background-color: #1e1e1e;
  height: 52px;
  border-radius: 34nfl-draft-5px;
  box-shadow: 0 3px 22px 5px rgba(251, 110, 0, 0nfl-draft-26);
  background-color: #fb6e00;
  max-width: 170px;
  margin-top: 15;
}
.nfl-draft-Oval {
  max-width: 43px;
  height: 41px;
  border: solid 2px #1e1e1e;
  border-radius: 50px;
  background-color: white;
  margin-top: 19px;
  margin-left: 6px;
}
.nfl-draft-My-Game-Center {
  width: 152px;
  height: 25px;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  margin-top: 11px;
  margin-left: -5px;
}
.nfl-draft-Path {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 12px solid #ffffff;
  margin-top: 37px;
  margin-left: 5px;
}

.nfl-draft-logo3 {
  font-size: 18px;
  color: #ffffff;
  float: left;
  margin-top: 88px;
}

.nfl-draft-Contest-Rules {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  margin-top: -23px;
  margin-left: 21px;
  display: inline-block;
}
.nfl-draft-prizeGrid {
  text-align: left;
  max-width: 191px;
  width: 191px;
  line-height: 38px;
  display: inline-block;
  height: 42px;
  border-radius: 21px;
  border: solid 1px #ffffff;
  margin-left: 19px;
  text-align: center;
}
.nfl-draft-Prize-Grid {
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  text-align: center;
  color: #ffffff;
  margin-top: -20px;
}

.nfl-draft-Status-In-Progress {
  font-size: 22px;
  font-weight: 600;

  text-align: center;
  color: #1bbc86;
}

.nfl-draft-text-style-2 {
  font-weight: 600;
  font-style: italic;
  color: #ff7b00;
}

.nfl-draft-Live-Draft {
  display: inline-block;
  width: 490px;
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
  color: #ffffff;
}
.nfl-draft-section-heading {
  display: inline-block;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 13px !important;
}
.nfl-draft-liveDrftCon {
  display: inline-block;
  height: 627px;
  border-radius: 0px 0px 11px 11px;
  background-color: #1a1a1a;
  text-align: center;
  width: 490px;
  text-align: center;
  overflow: auto;
}

.nfl-draft-liveBar {
  height: 64px;
  border-radius: 5px 5px 0px 0px;
  background-color: #c7c7c7;
  padding-left: 10px;
  padding-bottom: 10px;
  line-height: 64px;
  padding: 0px 30px;
  text-align: left;
}
.nfl-draft-draft-num {
  width: 68px;
  font-size: 17px;
  font-weight: 600;
  display: inline-block;
  color: #000000;
}
.nfl-draft-Name {
  width: 200px;
  font-size: 17px;
  font-weight: 600;
  color: #000000;
  margin-left: 10px !important;

  display: inline-block;
}
.nfl-draft-Team {
  font-size: 17px;
  font-weight: 600;
  color: #000000;
  margin-left: 258px;

  display: inline-block;
}
.nfl-draft-inventory-content {
  height: 627px;
  border-radius: 11px;
  background-color: #1a1a1a;
  text-align: center;
}

.nfl-draft-No-purchase-necessar {
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  margin-top: 20px;
}
.nfl-draft-text-style-5 {
  font-weight: 500;
  font-style: italic;
  color: #ff7b00;
  text-decoration: underline;
}
.nfl-draft-Full-contest-rules-h {
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  margin-top: 10px;
}
.nfl-draft-square {
  max-width: 490px;
  height: 180px;
  border-radius: 9px;
  background-color: #0c0c0c;
  margin-top: 30px;
  margin-left: 440px;
}
.nfl-draft-bullet {
  width: 10px;
  height: 10px;
  background-color: #ff7b00;
  border-radius: 100px;
  margin-top: 26px;
  position: absolute;
}
.nfl-draft-Five-5-prizes-to-b {
  font-size: 14px;
  font-weight: normal;
  color: #ffffff;
  margin-left: 21px;
  width: 455px;
  margin-top: 20px;
}
#bull {
  margin-top: 10px;
}
.nfl-draft-Path-5 {
  width: 110px;
  height: 1px;
  object-fit: contain;
  border: solid 3px #ff7b00;
  margin-left: 168px;
}

.nfl-draft-Live_draft_wrapper {
  text-align: left;
  padding: 12px 30px;
}
.nfl-draft-myDrafthr {
  width: 534px;
  height: 2px;
  border: solid 1px #424242;
  display: inline-block;
}
.nfl-draft-myhr {
  width: 420px;
  height: 1px;
  border: solid 1px #424242;
  display: inline-block;
}
#mar {
  margin-top: 100px;
}

#MDM {
  width: 85%;
}
#mypick {
  width: 131px;
}
#Powerplays {
  margin-left: 180px;
}
#Match {
  margin-left: 380px;
}
#draftMan {
  width: 85%;
}
#jBurr {
  margin-left: 55px;
  width: 95px;
}

.nfl-draft-iconsWrapper {
  width: 204px;
  display: inline-block;
}
.nfl-draft-NameWrapper {
  display: inline-block;
  width: 191px;
}

.nfl-draft-managerRowWrapper {
  padding: 28px;
  text-align: left;
}
.nfl-draft--Matched {
  line-height: 26px;
}
.nfl-draft-Matched {
  width: 120px;
  height: 25px;
  font-size: 18px;
  font-weight: 600;
  color: #fb6e00;
  vertical-align: top;
}
.nfl-draft-Powerplay-Inventory {
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: #ffffff;
}
#powerInvent {
  margin-left: -13px;
  margin-top: 92px;
}

.nfl-draft-DownActive {
  width: 51px;
  height: 51px;
  margin-top: 50px;
}
.nfl-draft-Swap {
  width: 51px;
  height: 51px;
  margin-top: 50px;
}
.nfl-draft-Undo {
  width: 51px;
  height: 51px;
  margin-top: 50px;
}
.nfl-draft-Move {
  font-size: 16px;
  margin-top: 7px;
  text-align: center;
  color: #ffffff;
}

#notMatched {
  background-color: #b0b0b0;
}
#tbd2 {
  width: 125px;
  height: 23px;
  border-radius: 3px;
  border: dashed 1px #424242;
  color: #424242;
  text-align: center;
  margin-left: 365px;
}
#num {
  margin-top: 60px;
}
.nfl-draft-chase {
  font-size: 18px;

  margin-top: 55px;
  margin-left: 375px;
  text-align: center;
  color: #ffffff;
}

#tbd3 {
  width: 101px;
  height: 23px;
  border-radius: 3px;
  border: dashed 1px #424242;
  color: #424242;
  text-align: center;
  margin-left: 260px;
}
.nfl-draft-rules {
  display: inline-block;
}
.nfl-draft-current {
  margin-top: 5px;
}
.nfl-draft-current-icon {
  background: url("../../../assets/images/draft_day/choose_2.png");
  display: inline-block;
  width: 31px;
  height: 39px;
}
.nfl-draft-current-text {
  font-family: Poppins;
  font-size: 34px;
  font-weight: 500;
  text-align: center;
  color: #ff7b00;
  display: inline-block;
}
.nfl-draft-text-style-4 {
}
.nfl-draft-manager {
  display: inline-block;
  vertical-align: top;
  width: 588px;
  text-align: left;
  margin-right: 20px;
}
.nfl-draft-manager-box {
  height: 627px;
  border-radius: 0px 0px 11px 11px;
  background-color: #1a1a1a;
  text-align: center;
  overflow: auto;
}
.nfl-draft-manager-icon {
  width: 33px;
  height: 33px;
  object-fit: contain;
  margin: 0px 5px;
  cursor: pointer;
}
.nfl-draft-button-match {
  width: 110px;
  height: 23px;
  border-radius: 3px;
  background-color: #ff7b00;
  text-align: center;
  color: #ffffff;
  display: inline-block;
  cursor: pointer;
}
.nfl-draft-text-picks {
  width: 183px;
  font-size: 17px;
  font-weight: 600;
  display: inline-block;
  color: #000000;
}
.inventory-item-wrapper {
  height: 156px;
}
.nfl-draft-powerplay-icon {
  width: 51px;
  height: 51px;
  margin-top: 48px;
  cursor: pointer;
}
.nfl-draft-powerplay-amount {
  font-size: 21px;
  font-weight: 600;
  line-height: 23px;
  text-align: center;
  color: #ff7b00;
  position: absolute;
  margin-top: 60px;
  margin-left: 5px;
}
.nfl-draft-name-wrapper {
  display: inline-block;
  width: 180px;
  margin-left: 10px;
}
.nfl-draft-team-wrapper {
  display: inline-block;
  width: 142px;
}
.nfl-draft-team-icon {
  display: inline-block;
  width: 22px;
  height: 27px;
  background: url("../../../assets/images/draft_day/choose.png");
}
.nfl-draft-live_draft_team_White {
  display: inline-block;
  width: 120px;
}
.nfl-draft-jersy {
  text-align: center;
  margin-top: 10px;
}
.nfl-draft-jersy-icon {
  display: inline-block;
  width: 21px;
  height: 17px;
  background: url("../../../assets/images/draft_day/group_24.png");
  margin-right: 5px;
  vertical-align: sub;
}
.nfl-draft-jersy-ref {
  display: inline-block;
  font-family: Poppins;
  font-size: 15px;
  color: #ff7b00;
  text-decoration: none;
}
.nfl-draft-jersy-ref:hover {
  text-decoration: underline;
  color: #ff7b00;
}
.nfl-draft-button-match-disabled {
  width: 112px;
  height: 23px;
  border-radius: 3px;
  background-color: #b0b0b0;
  font-family: Poppins;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  display: inline-block;
}
.nfl-draft-button-tbd {
  width: 120px;

  height: 23px;
  border-radius: 3px;
  border: dashed 1px #424242;
  font-family: Poppins;
  font-size: 16px;
  text-align: center;
  color: #424242;
  display: inline-block;
}
.bg-trans {
  width: 1440px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  text-align: center;
}
.nfl-draft-section-match-wrapper {
  display: inline-block;
}
.nfl-draft-section-match-icon {
  background-image: url("../../../assets/images/draft_day/verified.png");
  width: 26px;
  height: 26px;
  display: inline-block;
  vertical-align: top;
  margin-left: 20px;
  margin-right: 8px;
}
.main-dialog-wrapper {
  text-align: center;
  display: inline-block;
  margin-top: 40px;
  max-width: 100%;
}
.dialog_wrapper {
  padding: 12px;
  border-bottom: solid 1px #35363a;
}
.dialog-box_header {
  width: 360px;
  height: 70px;
  border-radius: 11px;
  background-color: #35363a;
  text-align: center;
  margin-left: 400;
}
.Swap_name {
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
  display: inline-block;
  margin-top: 17px;
}
.dialog-box_body {
  margin-top: -10px;
  width: 360px;
  max-height: 490px;
  background-color: #1a1a1a;
  margin-left: 400;
  text-align: left;
  overflow: auto;
  scroll-behavior: auto;
}
.hr-dialog-box {
  color: #35363a;
  width: 100%;
}
.checkbox {
  display: inline-block;
  vertical-align: top;
  margin: 0px;
}

.dialog-circle {
  text-align: center;
  width: 26px;
  height: 26px;
  background-color: #ffffff;
  border-radius: 13px;
  display: inline-block;
  margin-left: 10px;
  line-height: 26px;
  vertical-align: top;
}
.dialog-name {
  width: 133px;
  font-size: 16px;
  color: #ffffff;
  display: inline-block;
  margin-left: 10px;
  line-height: 26px;
  vertical-align: top;
}
.dialog-button {
  width: 360px;
  height: 50px;
  border-radius: 25px;
  cursor: pointer;
  background-color: #ff7b00;
  text-align: center;
  margin-top: 10px;
}
.dialog-button:hover {
  box-shadow: 0 3px 27px -2px rgba(22, 107, 172, 0.5);
}
.dialog-button-text {
  line-height: 50px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #f1f3f6;
  display: inline-block;
}

.draft-swap-cb {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.draft-swap-cb-label {
  position: relative;
  display: inline-block;
  padding: 0 0 0 2em;
  height: 1.5em;
  line-height: 1.5;
  cursor: pointer;
}
.draft-swap-cb-label::before,
.draft-swap-cb-label::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 1.5em;
  height: 1.5em;
}
.draft-swap-cb-label::before {
  content: " ";
  border: 1px solid white;
  border-radius: 20%;
}

.draft-swap-cb + .draft-swap-cb-label::after {
  content: "\2713";
  color: white;
  line-height: 1.5;
  text-align: center;
}

.draft-swap-cb:checked + .draft-swap-cb-label::before {
  background: #ff7b00;
  border-color: #ff7b00;
}
.draft-swap-cb + .draft-swap-cb-label::after {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
.draft-swap-cb:checked + .draft-swap-cb-label::after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.inactive{
  pointer-events: none;
  filter: grayscale(100%);
  
}