.__about-us-page{
    .__viewport{
        background-image: url('../../assets/images/about_us/bitmap@2x.jpg');
        background-position: left top;
        background-size: cover;
        padding: 6em 0;
        .__container{
            max-width: 30em;
            text-align: center;
            max-height: 900px;
        }
        .__title{
            background-color: #fb6e00;
            font-weight: bolder;
            font-size: 2.25em;
            text-align: center;
            border-radius: 6px;
            padding: 0.078em;
            width: 100%;
            text-shadow: 0 2px 14px rgba(0, 0, 0, 0.5);
            width: calc(100% + 1em);
            margin-left: -0.5em;
            margin-bottom: 1.1em;
        }
        .__content{
            margin: 0 2em;
        }
        .__subtitle{
            font-size: 18px;
        }
        .__title-2{
            font-size: 2.25em;
            font-weight: bolder;
            text-transform: uppercase;
        }
        .__subtitle-2{
            margin-top: 2em;
        }
    }
}

@media (max-width: 1290px){
    .__about-us-page{
        font-size: 14px;
        .__viewport{
            background-position: right bottom;
            .__view-port-header{
                margin: auto;
            }
            .__title{
                padding: 0.233em;
            }
        }
    }
}

@media (max-width: 600px){
    .__about-us-page{
        .__viewport{
            background-size: auto 100%;
            .__title{
                font-size: 1.72em;
                width: initial;
                margin: 0 0 0.7em 0;
            }
            .__title-2{
                font-size: 1.45em;
            }
            .__container{
                max-width: 22em;
            }
            .__subtitle-2{
                color: #f2f2f2;
                margin-top: 1.5em;
            }
        }
    }
}