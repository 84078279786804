.home-notify-wrapper {
  width: 643px;
  height: 93px;
  border-radius: 8px;
  background-color: #17181a;
  padding: 24px 0px;
  display: inline-block;
  margin-top: 40px;
  max-width: 100%;
}
.home-box-elite8 {
  margin-top: 130px;
  margin-left: -33px;
  z-index: 100;
  position: relative;
}
.home-notify-email {
  max-width: 90%;
  width: 322px;
  height: 44px;
  border: none;
  background: transparent;
  border-bottom: solid 1px #5f5f5f;
  color: white;
}
.home-notify-button {
  width: 178px;
  height: 44px;
  object-fit: contain;
  border-radius: 27.6px;
  box-shadow: 2px 8px 24px 3px rgba(251, 110, 0, 0.23);
  border: none;
  background-image: linear-gradient(to right, #fb6e00 11%, #fa3800 94%);
  margin-left: 28px;
  font-family: Poppins;
  font-size: 16px;
  display: inline-block;
  font-weight: 500;
}
.home-notify-dismiss-wrapper {
  padding-top: 30px;
}
.home-notify-img {
  background-image: url(../../assets/images/home/done.svg);
  width: 24px;
  height: 24px;
  object-fit: contain;
  display: inline-block;
  vertical-align: middle;
}
.home-notify-text1 {
  font-family: Poppins;
  font-size: 16px;
  margin-left: 8px;
  display: inline-block;
  color: white;
  vertical-align: middle;
}
.home-notify-dismiss {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  color: #fb6e00;
  display: inline-block;
  margin-left: 20px;
  vertical-align: middle;
}
@media screen and (max-width: 500px) {
  .home-notify-wrapper {
    padding: 5px 0px;
    width: 300px;
    margin-bottom: 40px;
  }
  .home-notify-button {
    margin-top: 20px;
  }
  .home-notify-img {
    width: 20px;
    height: 20px;
    background-size: cover;
  }
  .home-notify-text1 {
    font-size: 14px;
    margin-left: 0px;
  }
  .home-notify-dismiss {
    font-size: 14px;
  }
}
