body {
  font-family: Poppins !important;
}
.sub-spans {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}
.footer-contaier {
  max-width: 100%;
  background-color: #1e1e1e;
  text-align: center;
}
.footer-left {
  vertical-align: top;
  display: inline-block;
  text-align: left;
}
.footer-right {
  display: inline-block;
  text-align: left;
  margin-left: 200px;
}
.logo_img {
  margin: 0px 50px;
  width: 140.7px;
}
header > div {
  line-height: 85px;
}
.header_li_button {
  width: 193px;
  height: 52px;
  border-radius: 34.5px;
  box-shadow: 0 3px 27px -2px #fb6e00;
  background-color: #fb6e00;
  font-family: Poppins;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  margin-left: 60px !important;

  line-height: 54px !important;
}
.header_user_img {
  width: 41px;
  height: 41px;
  object-fit: contain;
}
.header_dropdown:hover {
  background-color: #fb6e00;
  color: #ffffff !important;
}
.header_dropdown_list {
  line-height: 50px;

  padding: 0px !important;
  width: 171.5px !important;
}
.header_dropdown_menu_item {
  background-color: #fb6e00;
  color: #ffffff;
  text-align: center;
}
.header_dropdown_menu_item :hover {
  background-color: #c05400;
  color: #ffffff !important;
}
.header_li_button_start {
  width: 170px;
  height: 52px;
  border-radius: 34.5px;
  box-shadow: 0 3px 27px -2px #fb6e00;
  background-color: #fb6e00;
  font-family: Poppins;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  margin-left: 10px !important;
  line-height: 54px !important;
}
.header_li_signin {
  width: 67px;
  height: 28px;
  font-family: Poppins;
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #fb6e00 !important;
}
.inventory-li {
  text-align: left;
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 12px 0px 12px 20px !important;
  color: #fefefe;
  text-transform: uppercase;
  font-size: 16px;
  width: 100%;
  font-weight: 400;
  background-size: 35px !important;
}
.m-subs {
  width: 80%;
  padding: 12px 0px 12px 20px !important;
}
.m-subs button {
  background: #ff7b00;
  padding: 0px 20px;
  border: none;
  border-radius: 5px;
}
.m-subs button:hover {
  color: #ff7c00;
  background: #edefef;
}
.box {
  margin-top: 46px;
}
.box a {
  display: block;
  font-family: Poppins;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  line-height: 30px;
}
.box h2 {
  line-height: 30px;
  font-family: Poppins;
  font-size: 14px;
  color: #ffffff;
}
@media screen and (max-width: 400px) {
  .footer-right {
    
    margin-left: 70px;
  }
}
@media screen and (max-width: 998px) {
  .c-p {
    display: block;
  }
  .header_li_button {
    margin: 0 auto !important;
  }
  .profile_dropdown {
    left: 0px !important;
  }
  .header_li_signin {
    width: 100%;
    margin-left: 0px !important;
    margin-top: 20px !important;
    margin-bottom: -20px !important;
  }
  .btn-singup {
    text-align: center !important;
    margin-left: 20px !important;
  }
  .header_li_button_start {
    display: inline-block !important;
    margin-left: 0px !important;
  }
}
._burger {
  margin-top: 30px !important;
}
.ham-burger {
  background: url("../../assets/images/group_4.png") !important;
  width: 26px;
  height: 16px;
  object-fit: contain;
  padding: 0px !important;
  border: none;
}
.global_timer_wrapper {
  width: 231px;
  border-radius: 25px 0px 0px 0px;
  box-shadow: 0 3px 27px -2px #fb6e00;
  background-color: #fb6e00;
  text-align: center;
  position: fixed;
  right: 50px;
  bottom: 0px;
  z-index: 100;
}
.global_timer_icon_close {
  width: 30px;
  height: 30px;
  object-fit: contain;
  cursor: pointer;
  background: url("../../assets/images/common/group_8.svg");
  right: -10px;
  top: -10px;
  position: absolute;
}
.icon_expand {
  width: 21px;
  height: 11px;
  margin: 5px 0px;
  display: inline-block;
  cursor: pointer;
  background: url("../../assets/images/common/up_arrow.png");
}
.icon_contract {
  width: 21px;
  height: 11px;
  margin: 5px 0px;
  display: inline-block;
  cursor: pointer;
  background: url("../../assets/images/common/up_arrow.svg");
}
.global_timer_header {
  font-weight: 500;
  letter-spacing: -0.11px;
  text-align: center;
  color: #ffffff;
  text-align: center;
  font-size: 22px;
}
.global_timer_main {
}
.global_timer_main_text {
  font-family: Poppins;
  font-size: 16px;
  color: #ffffff;
}
.global_timer_main_watch {
  text-align: center;
}
.global_timer_main_item {
  display: inline-block;
}
.global_timer_main_item_box {
  width: 39px;
  height: 32px;
  background-color: #000000;
  font-family: Poppins;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  margin: 7px;
}
.global_timer_main_item_text {
  font-family: Poppins;
  font-size: 13px;
  text-align: center;
  color: #ffffff;
}
.global_timer_button {
  width: 193px;
  height: 42px;
  border-radius: 21px;
  box-shadow: 0 3px 27px -2px #8c8c8c;
  background-color: #ffffff;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #000000;
  line-height: 42px;
  display: inline-block;
  margin-bottom: 39px;
  margin-top: 10px;
  cursor: pointer;
}
.div_hide {
  display: none;
}
