@font-face {
  font-family: "jibril";
  src: url(../../assets/fonts/Jibril.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
.do-scroll {
  overflow: auto;
  height: 390px;
  overflow-y: scroll;
  margin-top: 50px;
  padding-top: 0px !important;
}
.game-arrow-wraper {
  height: 40px;
  width: auto;
  opacity: 0.3;
  width: 20px;
}
.game-arrow-wraper:hover {
  opacity: 0.7;
}
.game-arrow {
  height: 40px;
  width: 20px;
}
.game-name-header {
  height: 180px;
  display: flex;
  max-width: 400px;
  width: 420px;
  margin: auto auto;
  align-items: center;
}
.game-name {
  max-width: 300px;
  width: 300px;
  margin: 0px 20px !important;
}
.teams-no-box {
  background: white;
  width: 50%;
  height: 50px;
  margin: 50px auto;
  border-radius: 3px;
  border: 3px solid #ff7b00;

  font-size: 15px;
  text-align: center;
  line-height: 44px;
  font-size: 18px;
  font-weight: 600;
}
.subscription-icon {
  height: 18px;
  display: inline;
}
.not-saved {
  background: black;
  animation: glow 2s steps(25, end) 0s infinite;
}
.m-balance {
  color: #ff7b00;
  margin: 0 auto;
  text-align: center;
}
.m-balance span {
  margin: 0 auto;
  font-weight: 700;
  font-size: 30px;
}
.m-balance .balance-label-wraper {
  line-height: 80px;
}
.m-balance a > button {
  display: inline;
  background: #02698f;
  color: white;
  width: 300px;
  border: none;
  margin: 10px;
}
@keyframes glow {
  /* You could think of as "step 1" */
  0% {
    background: #d0d0d0;
  }
  35% {
    background: #ffffff;
  }
  70% {
    background: rgb(59, 59, 59);
  }
  /* You could think of as "step 2" */
  100% {
    background: #d0d0d0;
  }
}
.game_center_user {
  text-align: left;
  padding-left: 60px;
  margin: 50px 0px 10px 0px;
}
.game_center_user_name {
  height: 31px;
  font-family: Poppins;
  font-size: 22px;
  font-weight: bold;

  color: #ffffff;
}
.game_center_user_date {
  height: 20px;
  font-family: Poppins;
  font-size: 14px;

  color: #ffffff;
}
.game_center_table_header {
  width: 327px;

  font-family: Poppins;
  font-size: 32px;
  font-weight: bold;
  line-height: 1.5;
  color: #fb6e00;
  margin-bottom: 27px;
}
.game_center_table_outer {
  max-height: 424px;
  height: 355px;
  overflow-y: scroll;
  overflow: auto;
}
.game-center-table {
  width: 100%;
  max-width: 100%;
  background-color: #ffffff;
  text-align: center;
  max-height: 424px;
  margin-right: 30px;
  overflow: auto;
  min-height: 290px;
}
.game-center-table-tbody {
  min-height: 100px;
}
.game-center-table-th {
  font-family: Poppins;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: rgba(51, 51, 51, 0.5);
  text-align: center;
  padding: 17px;
}
.game-center-table-th {
  display: inline-block;
  width: 155px;
}
.game-center-table-td {
  display: inline-block;
  width: 155px;
  text-align: center;
  vertical-align: middle;
}
.game-center-table {
  text-align: left;
}

.game-center-table-th:last-child,
.game-center-table-td:last-child {
  width: 270px;
  text-align: left;
}
.game-center-table-tbody .game-center-table-tr:nth-child(odd) {
  background-color: #c6cbd4;
}
.game-center-table-td:nth-child(1) a {
  font-family: Poppins;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: #fb6e00;
}
.game-center-table-td:nth-child(1) a:hover {
  font-family: Poppins;
  font-size: 16px;
  color: #fb4a11;
  text-decoration: underline;
}
.game-center-table-td {
  font-family: Poppins;
  font-size: 16px;
  line-height: 1.5;
  color: #333333;
}
.game-center-table-tbody .game-center-table-tr {
  padding: 20px 0px;
}
.game_center_first_row {
  padding-left: 60px;
  background-color: #090909;
  height: 89px;
  width: 100%;
}
.game_center_first_row_item {
  width: 216px;
  height: 54px;
  border-radius: 27px;
  background-color: #000000;
  margin: 20px 15px;
  float: left;
}
.game_center_first_row_img {
  width: 37px;
  display: inline-block;
  vertical-align: top;
}
.game_center_item_details {
  margin-left: 10px;
  display: inline-block;
}
.game_center_item_details_header {
  text-transform: capitalize;
  width: 90px;
  height: 23px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: bold;
  color: #fb6e00;
}
.game_center_item_details_sub {
  font-family: Poppins;
  font-size: 14px;
  color: #ffffff;
}
.game_center_first_row_button {
  width: 226px;
  height: 47px;
  border-radius: 23.5px;

  background-color: #ffffff;
  font-size: 16px;
  text-align: center;
  color: #090909;
  border: none;
  float: right;
  margin: 22px 0px 0px 300px;
  margin-right: 50px;
}
.game_center_first_row_button:hover {
  box-shadow: 0 3px 27px -2px #8c8c8c;
}

.game_center_action_menu {
  width: 244px;
  height: 154px;
  left: -96px;
  box-shadow: 0 2px 9px 2px rgba(0, 86, 216, 0.1);
  background-color: #ffffff;
}
.menu_up .short {
  height: 110px !important;
  top: -110px !important;
}
.menu_up {
  top: -150px;
}
.short {
  height: 110px !important;
}

.action_menu_item {
  padding: 10px 0px 0px 20px;
  display: block;
  height: 43px;
  line-height: 40px;
  margin: 5px 0px;
}
.action_menu_item_img {
  width: 23px;
  height: 23px;
  float: left;
}

.action_menu_item_text {
  margin-left: 10px;

  height: 23px;
  line-height: 23px;
  font-family: Poppins;
  font-size: 16px;
  color: #fb6e00;
  float: left;
}
.game_center_summry_body {
  width: 537px;
  height: auto;
  box-shadow: -2px 2px 14px 3px rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
}
.game_center_summry_header {
  width: 537px;
  height: 126px;
  box-shadow: -2px 2px 14px 3px rgba(0, 0, 0, 0.14);
  background-color: #fb6e00;
}
.game_center_summry_modal {
  background: RGBA(0, 0, 0, 0.7);
  width: 100%;
  overflow: auto !important;
  position: fixed;
  top: 0;
  bottom: 0;

  z-index: 1050;
}
.modal-con {
  float: right;
}
.summry_header_sport_name {
  width: 164px;
  height: 25px;
  font-family: Poppins;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  margin: 25px 0px 15px 0px;
}
.summry_header_score_button {
  width: 162px;
  height: 37px;
  border-radius: 19.5px;
  background-color: #ffffff;
  font-family: Poppins;
  font-size: 16px;
  text-align: center;
  color: #fb6e00;
  border: none;
}
.summry_header_right_col {
  float: left;
  margin: 25px 10px;
}
.summry_header_right {
  width: 200px;
}
.summry_header_right_col_text {
  width: 80px;
  height: 32px;
  font-family: Poppins;
  font-size: 14px;
  line-height: 1.14;
  text-align: center;
  color: #ffffff;
}
.summry_header_right_col_score {
  width: 36px;
  height: 36px;
  background-color: #ffffff;
  line-height: 36px;
  border-radius: 18px;
  font-family: Poppins;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #fb6e00;
  margin-top: 9px;
}
.summry-table {
  width: 100%;
  max-width: 100%;
  border-radius: 6px;
  background-color: #ffffff;
  text-align: center;
  margin-right: 30px;
  overflow: auto;
}
.summry-table th {
  opacity: 0.5;
  font-family: Poppins;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: #333333;
  text-align: center;
  padding: 17px;
}
.summry-table tbody tr {
  height: 79px;
}
.summry-table tbody tr:nth-child(odd) {
  background-color: #f2f3f5;
}
.summry_table_img {
  width: 41px;
  height: 32px;
  background: transparent;
  object-fit: contain;
}
.game_summry_results_img {
  height: 157px;
  width: 168px;
  object-fit: contain;
  margin-top: 48px;
}
.game_summry_results_text {
  width: 330px;
  height: 48px;
  font-family: Poppins;
  font-size: 16px;
  text-align: center;
  color: #fb6e00;
  margin: 15px;
}
.button_pick_teams {
  height: 37px;
  border-radius: 19.5px;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.28);
  background-color: #000000;
  font-family: Poppins;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  border: none;
  padding: 0px 30px;
}
.button_pick_teams:hover {
  box-shadow: 0 3px 27px -2px #000000;
}
.button_goto_game {
  border-radius: 19.5px;
  border: solid 1px #090909;
  background: transparent;
  height: 37px;
  font-family: Poppins;
  font-size: 13px;
  text-align: center;
  color: #000000;
  padding: 0px 10px;
  margin: 0px 10px;
}
.game_center_enter_more_wrapper {
  text-align: center;
}
.game_center_enter_more {
  font-family: Poppins;
  font-size: 18px;
  color: #ffffff;
  display: inline-block;
  margin: 0px 50px;
}
.explore_text {
  width: 242px;
  height: 49px;
  font-family: Poppins;
  font-size: 18px;
  color: #ffffff;
  float: left;
  margin-left: 10px !important;
  text-align: start;
}
.game_center_enter_more img {
  float: left;
  width: 61px;
  height: 46px;
  object-fit: contain;
}
.game_center_enter_more > div {
  margin: 16px;
  height: 46px;
  width: 313px;
}
.game_center_more_games_button {
  width: 310px;
  height: 51px;
  border-radius: 34.5px;

  background-color: #fb6e00;
  border: none;
  display: block;
  margin-top: 16px;
  font-family: Poppins;
}
.game_center_more_games_button:hover {
  box-shadow: 0 3px 27px -2px #fb6e00;
}
.game-center-tabs {
  background: #ffffff;
  border-bottom: solid 1px #cfcfcf;
  border-radius: 6px 0px 0px 0px;
}
.game-center-tab {
  width: 174px;
  height: 25px;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #999999;
  display: inline-block;
  line-height: 64px;
  height: 64px;
  cursor: pointer;
  margin: 0px 0px 0px 30px;
}
.game-center-tab.active {
  color: #fb6e00;
  border-bottom: solid 3px #fb6e00;
}
.game_center_table_wrapper {
  margin: 80px 60px;
}
.table-desktop {
  display: block;
}
.table-mobile {
  display: none;
}
@media screen and (max-width: 1290px) {
  .game_center_table_wrapper {
    margin: 60px 10px;
  }
  .game-center-tabs,
  .table-desktop {
    min-width: 1220px;
  }
  .game_center_table_outer {
    overflow-x: auto;
    
  }
}
@media screen and (max-width: 700px) {
  .table-desktop {
    display: none;
  }
  .table-mobile {
    display: block;
  }
  .game_center_table_outer {
    max-height: unset;
  }
  .game_center_table_wrapper {
    text-align: center;
    margin: 60px 15px 50px;
  }
  .game_center_table_header {
    font-size: 22px;
    font-weight: 600;
    line-height: 1.64;
    display: inline-block;
    max-width: 100%;
    margin-bottom: 0px;
  }
  .game_center_item_details_header{
    text-align: left;
    width: unset;
  }
  .game_center_table {
    background: transparent;
    padding: 0px;
  }
  .game-center-tabs {
    background: transparent;
    border: none;
    margin: 20px 0px;
    min-width: unset;
  }
  .game-center-tab {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    height: unset;
    max-width: 48%;
    line-height: unset;
  }
  .game-center-tab {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    height: unset;
    line-height: unset;
    margin: 0px;
    display: inline-block;
  }
  .game-center-mobile-box {
    background: white;
  }
  .mobile-box-row {
    padding: 9px 18px;
  }
  .mobile-box-row:nth-child(odd) {
    background-color: #f2f3f5;
  }

  .mobile-box-row-left {
    display: inline-block;
    opacity: 0.5;
    font-family: Poppins;
    font-size: 16px;
    line-height: 1.5;
    color: #333333;
    text-align: left;
    width: 50%;
  }
  .mobile-box-row-right {
    display: inline-block;
    width: 50%;
    font-family: Poppins;
    font-size: 16px;
    line-height: 1.5;
    text-align: right;
    color: #333333;
  }
  .mobile-box-row-right a {
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    text-align: right;
    color: #fb6e00;
  }

  .mobile-box-row-right a:hover {
    text-decoration: underline;
  }
  .game_center_first_row {
    text-align: center;
    padding-left: 0px;
    height: unset;
  }
  .game_center_first_row_item {
    display: inline-block;
    float: none;
  }
  .game_center_first_row_button {
    float: none;
    display: inline-block;
    margin: 0px 0px -30px 0px;
  }
  .game_center_enter_more{
    margin: 10px 0px;
    text-align: center;
  }
  .game_center_enter_more img {
    float: none;
    display: inline-block;
    width: 61px;
    height: 46px;
    object-fit: contain;
    vertical-align: top;
  }
  .game_center_more_games_button{
    display: inline-block;
    margin: 0px;
    
  }

  .explore_text{
    display: inline-block;
    float: none;
  }
}
