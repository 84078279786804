.__partner-with-us{
    background:  #1e1e1e;
    .__btn{
        border: none;
        padding: 0.8em 2.3em;
        border-radius: 6px;
        color: #fff;
        background: #fb6e00;
        font-family: inherit;
        min-width: 220px;
        min-height: 48px;
        text-transform: uppercase;
        margin: auto;
    }
    .__viewport{
        min-height: initial;
        text-align: center;
        padding: 6em 0 14em 0;
        overflow: hidden;
        background: url('../../assets/images/about_us/bitmap-copy@2x.jpg');
        background-size: cover;
        .__title{
            font-size: 2.625em;
            font-weight: 600;
        }
        .__subtitle{
            font-size: 1.125em;
        }
        .__btn{
            margin-top: 2em;
        }
    }
    .__main{
        transform: skewY(-5deg);
        transform-origin: left bottom;
        background:  #1e1e1e;
        .__reverse-rotate{
            transform: skewY(5deg);
            transform-origin: left bottom;
            position: relative;
            top: -9vw;
        }
        .__left{
            margin-top: 10vw;
            display: grid;
            grid-template-columns: auto 1fr;
            grid-gap: 3.5em 1.2em;
            align-items: center;
            width: 47%;
            .__title{
                font-size: 1.25em;
                font-weight: bold;
                margin-bottom: 0.5em;
            }
            .__title + div{
                font-size: 14px;
            }
        }
    }
    .__main .__right{
        border-radius: 7px;
        box-shadow: 0 6px 24px 18px rgba(0, 0, 0, 0.38);
        background-color: #fff;
        font-size: 14px;
        color: #000;
        padding: 2.87em 2.87em 0 2.87em;
        display: grid;
        grid-gap: 1.5em;
        min-width: 32em;
        .__input-field{
            label{
                margin-bottom: 0.4em;
                display: block;
            }
            input, textarea{
                display: block;
                width: 100%;
                border: solid 1px #e0e0e0;
                outline: none;
                padding: 1.31em;
                border-radius: 6px;
            }
        }
        .__btn{
            margin-bottom: -1.5em;
        }
    }
}

@media (min-width: 800px){
    .__partner-with-us{
        .__main .__right .__btn{
            font-size: 1.714em;
            border-radius: 12px;
        }
    }
}

@media (max-width: 800px){
    .__partner-with-us{
        font-size: 14px;
        background: #000;
        .__viewport{
            padding: 3em 0;
            background: transparent;
            .__title{
                font-size: 2.285em;
                margin-bottom: 0.4em;
                line-height: 1.4em;
            }
        }
        .__main{
            transform: initial;
            top: initial;
            position: initial;
            background: #000;
            .__reverse-rotate{
                transform: initial;
                top: initial;
                margin: 2em 0 4em 0;
                .__container-2{
                    display: block;
                }
            }
            .__title{
                font-size: 1.143em;
                font-weight: 600;
                margin-bottom: 0.2em;
            }
            .__left{
                width: 100%;
                margin-top: initial;
                margin-bottom: 3em;
                grid-gap: 2em 1.2em;
            }
        }
    }
}
@media (max-width: 600px){
    .__partner-with-us{
        text-align: left;
        .__main{
            .__left{
                grid-template-columns: auto;
                place-items: center;
                grid-gap: initial;
                img{
                    margin: 3em 0 1em 0;
                    &:first-child{
                        margin-top: 0;
                    }
                }
            }
            .__right{
                min-width: initial;
                padding: 1em;
                .__btn{
                    margin-bottom: -2.7em;
                }
            }
        }
    }
}