.sweet16_live_rows > div {
  margin: 30px 0px;
}

.winning_box_numbers_sweet16 {
  margin: 0 auto;
  max-width: 100%;
  width: 1166px;
  min-height: 277px;
  border-radius: 23px;
  background-color: #1e1e1e;
  padding: 0px 20px;
}
.winning_box_circle_sweet16 {
  background-position: center;
  background-size: cover;
  width: 90px;
  height: 90px;
  line-height: 90px;
  float: left;
  margin: 24px 20px;
  font-size: 36.8px;
  font-weight: 500;
  letter-spacing: -0.2px;
  text-align: center;
  color: #000000;
}
.winning_box_circle_sweet16_active {
  width: 90px;
  height: 90px;
  line-height: 90px;
  float: left;
  margin: 24px 25px;
  font-size: 36.8px;
  font-weight: 500;
  letter-spacing: -0.2px;
  text-align: center;
  color: #fb6e00;
}
@font-face {
  font-family: "Orbitron";
  font-style: normal;
  font-weight: 400;
  src: local("Orbitron Regular"), local("Orbitron-Regular"),
    url(https://fonts.gstatic.com/s/orbitron/v10/yMJRMIlzdpvBhQQL_Qq7dy0.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

.mleft .edit_numbers .edit_numbers_circle_sweet16 {
  margin: 5px;
  float: left;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: -0.1px;
  text-align: center;
  color: #000000;
}
.mleft .edit_numbers .edit_numbers_circle_sweet16_active {
  margin: 5px;
  float: left;
  width: 50px;
  height: 50px;
  padding: 5px;
  line-height: 20px;
  font-family: Poppins;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.1px;
  text-align: center;
  color: rgba(0, 0, 0, 0.48);
}
.edit_numbers_circle_sweet16_active img {
  margin: 0 auto;
  width: 14px;
  height: 18px;
}

.winning_box_numbers_sweet16 {
  margin: 20 auto;
  max-width: 100%;
  border-radius: 23px;
  background-color: #1e1e1e;
  padding: 0px 20px;
  width: 400px;
}
.winning_box_circle_sweet16 {
  background-position: center;
  background-size: cover;
  width: 60px;
  height: 60px;
  line-height: 60px;
  float: left;
  margin: 14px 15px;
  font-size: 26.8px;
  font-weight: 500;
  letter-spacing: -0.2px;
  text-align: center;
  color: #000000;
}
.winning_box_circle_sweet16_active {
  width: 60px;
  height: 60px;
  line-height: 60px;
  float: left;
  margin: 14px 15px;
  font-size: 26.8px;
  font-weight: 500;
  letter-spacing: -0.2px;
  text-align: center;
  color: #fb6e00;
}
