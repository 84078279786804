.__about-us-page{
    .__btn{
        font-size: 1.429em;
        background: #fb6e00;
        height: 2.4em;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 11em;
        text-transform: uppercase;
        font-weight: 600;
    }
    .__main{
        padding: 0.1px;
        background-image: url('../../assets/images/about_us/27263.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center right;
        border-top: 4px solid #fb6e00;
        .__title{
            font-size: 2.25em;
            font-weight: bolder;
            text-transform: uppercase;
        }
        .__content{
            width: 30.5em;
            flex-direction: column;
            &:first-child{
                margin: 9em 5em;
            }
            &:nth-child(2){
                margin: 20em 5em 2em auto;
            }
        }
        .__paragraph{
            >div{
                margin: 2em 0;
            }
        }
        .__btn{
            margin: 4em auto;
        }
    }
}

@media (max-width: 1290px){
    .__about-us-page{
        .__main{
            background-position: bottom center;
            .__content{
                &:first-child{
                    margin: 6em 0;
                }
                &:nth-child(2){
                    margin: 15em 0 2em auto;
                }
            }
        }
    }
}

@media (max-width: 800px){
    .__about-us-page{
        .__main{
            .__content{
                text-align: left;
                width: 20em;
            }
            .__title{
                font-size: 1.429em;
            }
        }
    }
}

@media (max-width: 600px){
    .__about-us-page{
        .__btn{
            font-size: 14px;
            background: transparent;
            border: 1px solid currentColor;
            min-width: 15.714em;
            height: 3.428em;
        }
        .__main{
            .__content{
                max-width: initial;
                width: 15em;
                &:first-child{
                    margin: 3em 0;
                }
                &:nth-child(2){
                    margin: 8em 0 3em auto;
                }
            }
            .__btn{
                margin: 1em auto 2em auto;
            }
        }
    }
}