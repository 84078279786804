body{
  margin: 0;
  color: #fff;
}
a{
  color: inherit;
}
.__brand-logo{
  width: 7.25em;
}
.__primary{
  color: #fb6e00;
}
.__container{
  width: 85%;
  margin: auto;
}
.__container-2{
  width: 80%;
  margin: auto;
}
.__flex{
  display: flex;
  align-items: center;
}
.__wrap{
  flex-wrap: wrap;
}
.__column{
  display: flex;
  flex-direction: column;
  height: 100%;
}
.__flex-start{
  align-items: flex-start;
}
.__sb{
  justify-content: space-between;
}
.__f1{
  flex: 1;
}
.__viewport{
  min-height: calc(100vh - 3.5em);
  margin-top: 3.5em;
}
.__flex-center{
  justify-content: center;
}

@media (min-width: 1001px){
  .__show-on-mediam{
    display: none;
  }
}

@media (min-width: 600px){
  .__show-on-small{
    display: none;
  }
}

@media (max-width: 1290px){
  .__viewport{
    min-height: calc(100vh - 66px);
    margin-top: 66px;
  }
  .__hide-on-large{
    display: none;
  }
}
@media (max-width: 1000px){
  .__hide-on-mediam{
    display: none;
  }
}
@media (max-width: 600px){
  .__container, .__container-2{
    width: 88%;
  }
  .__hide-on-small{
    display: none;
  }
}

@media (min-width: 1800px){
  .__page{
    font-size: 20px;
  }
}