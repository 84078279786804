.promo-content {
  text-align: center;
}
.elite8-promo-header {
  background: url("../../assets/images/elite8-promo/header.png");
  width: 100%;
  height: 480px;
  padding: 115px 0px 160px 79px;
  text-align: center;
}
.elite8-promo-header-content {
  text-align: left;
}
.elite8-promo-header-text1 {
  font-family: Poppins;
  font-size: 136px;
  font-weight: 900;
  color: #ffffff;
}
.elite8-promo-header-text2 {
  font-family: Poppins;
  font-size: 36.4px;
  font-weight: 900;
  color: #ffffff;
  margin-top: -30px;
}
.elite8-promo-header button {
  margin-top: 110px;
}
.elite8-promo-header-button {
  width: 458px;
  height: 65px;
  border-radius: 32.5px;

  background-color: #fb6e00;
  font-family: Poppins;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  border: none;
}
.elite8-promo-header-button:hover {
  box-shadow: 0 2px 18px -1px #fb6e00;
}
.elite8-promo-row1 {
  margin-top: -20px;
  width: 1030px;
  max-width: 100%;
  display: inline-block;
}
.elite8-promo-row1-img {
  display: inline-block;
  width: 494px;
  height: 494px;
  object-fit: contain;
  vertical-align: top;
  margin-right: 30px;
}
.elite8-promo-row-content {
  display: inline-block;
  margin-top: 73px;
}
.elite8-promo-row-header {
  font-size: 100px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  text-align: left;
}
.elite8-promo-row-prize {
  width: 138px;
  height: 90px;
  border-radius: 17px;
  background-color: #1e1e1e;
  display: inline-block;
  padding-top: 10px;
  text-align: center;
  margin: 0px 15px;
}
.elite8-promo-row-prize:first {
  margin: 0px;
}
.elite8-promo-row-prize-header {
  font-family: Poppins;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
}
.elite8-promo-row-prize-text {
  font-family: Poppins;
  font-size: 32px;
  font-weight: bold;
  color: #fb6e00;
}
.elite8-promo-row1 button {
  margin-top: 47px;
}
.elite8-promo-row2 {
  width: 1110px;
  display: inline-block;
  max-width: 100%;
}
.elite8-promo-row2-img {
  float: right;
  width: 720px;
  height: 657px;
  object-fit: contain;
  margin-right: -72px;
  margin-top: -77px;
}
.elite8-promo-row2-content {
  margin-top: 160px;
  width: 458px;
  display: inline-block;
}
.elite8-promo-row2-header {
  font-family: Poppins;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  width: 500px;
}
.elite8-promo-row2-sub {
  font-family: Poppins;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #fb6e00;
  width: 500px;
}
.elite8-promo-row2 .m-btn {
  line-height: 65px;
  margin-top: 50px;
}
.page747_power_heading_sub{
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.1px;
    color: #ffffff;
}
.page747_power_details{
    text-align: left;
}
@media only screen and (max-width: 1050px) {
  .elite8-promo-row1-img {
    margin-right: 0px;
  }
  .elite8-promo-row-header {
    text-align: center;
  }
  .elite8-promo-row-content {
    margin-top: 20px;
  }
  .elite8-promo-row2-img {
    float: none;
    margin: 0px -41px;
  }
  .elite8-promo-row2-content {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 700px) {
  .elite8-promo-header-text1 {
    font-size: 68px;
    font-weight: 900;
    text-align: center;
  }
  .elite8-promo-header-text2 {
    text-align: center;
    font-size: 18.2px;
    font-weight: 900;
    margin-top: -10px;
  }
  .elite8-promo-header {
    padding: 0px 0px;
    text-align: center;
    height: 348px;
    background: url("../../assets/images/elite8-promo/header_phone.png");
    background-repeat: no-repeat;
    background-origin: content-box;
    background-size: cover;
    border-top: 136px solid #282828;
  }
  .elite8-promo-header-content {
    margin-top: -136px;
  }
  .elite8-promo-header button {
    margin-top: 200px;
  }
  .elite8-promo-row1-img {
    width: 316px;
    height: 316px;
  }
  .elite8-promo-row-header {
    font-size: 50px;
  }
  .elite8-promo-row-prizes{
      display:inline-block;
      width: 272px;
      text-align: center;
  }
  .elite8-promo-row-prize{
    width: 106px;
    height: 67px;
    border-radius: 17px;
    margin: 7px 7px;
  }
  .elite8-promo-row-prize:nth-of-type(1) {
    width: 227px;
    height: 56px;
    border-radius: 17px;
    border: solid 2px #fb6e00;
    background-color: #1e1e1e;
    padding-top: 0px;
    line-height: 56px;
  }
  .elite8-promo-row-prize-header{
    font-size: 14px;
  }
  .elite8-promo-row-prize-text{
    font-size: 22px;

  }
  .elite8-promo-row-prize:nth-of-type(1) .elite8-promo-row-prize-header{
    display: inline-block;
  }
  .elite8-promo-row-prize:nth-of-type(1) .elite8-promo-row-prize-text{
    display: inline-block;
    margin-left: 10px;
  }
  .elite8-promo-header-button{
    width: 284px;
    height: 52px;
    border-radius: 32.5px;
    font-size: 16px;
  }
  .elite8-promo-row2{
      margin-bottom: 50px;
  }
  .elite8-promo-row2-img{
    width: 360px;
    height: 329px;
  }
  .elite8-promo-row2-content{
      max-width: 100%;
  }
  .elite8-promo-row2-header{
    font-size: 32px;
    width: 90%;
    margin: 0px 5%;
  }
  .elite8-promo-row2-sub{
    font-size: 20px;
    width: 50%;
    margin: 20px 25%;
  }
  .elite8-promo-row2 .m-btn{
    line-height: 52px;
    display: inline-block;
    margin-top: 0px;
  }
  .page747_power_heading_sub{
    width: 278px;
    font-family: Poppins;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    letter-spacing: -0.08px;
    text-align: center;
    color: #ffffff;
    margin: 10px auto 20px;
  }
}
