.PowerPlayGames_PricingPage {
  background-color: #1e1e1e;
}
.store-header {
  width: 80%;
  font-family: Poppins;
  font-size: 54px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin: 40px auto;
}

.store-header > span {
  font-weight: 900;
  color: #fb6e00;
}
.top-box {
  margin: 40px auto;
  width: 419px;
  height: 339px;
  border-radius: 9px;
  background-color: #ffffff;
  padding-top: 18px;
  text-align: center;
}
.top-box-header {
  width: 218px;
  height: 59px;
  margin: 0 auto;
  text-align: center;
  font-family: Poppins;
  font-size: 42px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1e1e1e;
}
.top-box-price {
  text-align: center;
  font-family: Poppins;
  font-size: 58px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fb6e00;
}
.top-box-detail {
  text-align: center;
  font-family: Poppins;
  margin-top: 12px;
  color: #1e1e1e;
}
.top-box-button {
  width: 356px;
  height: 56px;
  border-radius: 9px;
  box-shadow: 0 2px 18px -1px #fb6e00;
  background-color: #fb6e00;
  font-family: Poppins;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border: none;
  margin-top: 20px;
}
.Group-8 {
  vertical-align: baseline;
  width: 41px;
  height: 41px;
  object-fit: contain;
  margin-right: 16px;
}
.top-box-detail > p {
  margin-left: 10px;
  width: 95px;
  height: 50px;
  font-family: Poppins;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1e1e1e;
  display: inline-block;
  text-align: start;
}

.row-sport-buttons {
  margin: 100px auto;
  width: 90%;
}
.sport-button-active {
  width: 241px;
  height: 57px;
  border-radius: 28.5px;
  background-color: #ffffff;
  font-family: Poppins;
  font-size: 32px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fb6e00;
  border: none;
}
.sport-button {
  width: 241px;
  height: 57px;
  border-radius: 28.5px;
  border: solid 3px #ffffff;
  font-family: Poppins;
  font-size: 32px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  background: transparent;
}
.row-subscription {
  width: 90%;
  margin: 40px auto;
}
.sub-box {
  width: 303px;
  height: 363px;
  border-radius: 9px;
  border: solid 4px #ffffff;
  padding: 5px 5px 0px 20px;
}
.sub-box-active {
  width: 303px;
  height: 363px;
  border-radius: 9px;
  border: solid 4px #fb6e00;
  padding: 5px 5px 0px 20px;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  transform: rotateX(180);
}
.sub-box-added {
  float: right;
  width: 67px;
  height: 28px;
  opacity: 0;
  font-family: Poppins;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.sub-box-added-active {
  float: right;
  width: 67px;
  height: 28px;
  opacity: 1;
  font-family: Poppins;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.sub-box-header {
  margin-top: 15px;
  width: 87px;
  height: 45px;
  font-family: Poppins;
  font-size: 32px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.sub-box-s-header {
  margin-top: -5px;
  margin-bottom: 5px;
}
.sub-box-s-header > .span-amount {
  width: 36px;
  height: 48px;
  font-family: TitilliumWeb;
  font-size: 32px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.sub-box-s-header > .span-text {
  width: 43px;
  height: 22px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  vertical-align: super;
}
.sub-box-price {
  height: 85px;
  font-family: TitilliumWeb;
  font-size: 56px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.sub-box-price > span {
  width: 48px;
  height: 17px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.sub-box-descr {
  width: 268px;
  height: 63px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.sub-box-button {
  width: 262px;
  height: 44px;
  border-radius: 9px;
  border: solid 3px #ffffff;
  font-family: Poppins;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  background: transparent;
  margin-top: 30px;
}
.sub-box-button-active {
  width: 262px;
  height: 44px;
  border-radius: 9px;
  border: none;
  font-family: Poppins;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fb6e00;
  background: transparent;
  margin-top: 30px;
}
.pricing-box-total {
  width: 303px;
  height: 363px;
}
.pricing-box-total-header {
  font-family: Poppins;
  margin: 30px 0px 0px 0px;
  font-size: 58px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.pricing-box-total-price {
  font-family: Poppins;
  font-size: 58px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.pricing-box-total-button {
  width: 274px;
  height: 44px;
  border-radius: 9px;
  box-shadow: 0 2px 18px -1px #fb6e00;
  background-color: #fb6e00;
  border: none;
}
.footer-image {
  width: 100%;
  height: auto;
  object-fit: contain;
}
#slide {
  overflow: hidden;
  transition: transform 1s ease-in-out 0s;
  -moz-transition: -moz-transform 1s ease-in-out 0s;
  -webkit-transition: -webkit-transform 1s ease-in-out 0s;
}
.pricing-sport-tab {
  top: 0px;
  left: 400px;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  
}
.pricing-sport-tab-selected {
  visibility: visible;
  opacity: 1;
  transition: left linear .25s, opacity 1.9s, visibility 1.9s;
  display: block;
  top: 0px;
  left: 0px;
 

}
