.elite8_live_rows > div {
  margin: 30px 0px;
}

.winning_box_numbers_elite8 {
  margin: 0 auto;
  max-width: 100%;
  width: 1166px;
  min-height: 277px;
  border-radius: 23px;
  background-color: #1e1e1e;
  padding: 0px 20px;
}
.winning_box_circle_elite8 {
  cursor: pointer;
  background-position: center;
  background-size: cover;
  width: 90px;
  height: 90px;
  line-height: 90px;
  float: left;
  margin: 24px 20px;
  font-size: 36.8px;
  font-weight: 500;
  letter-spacing: -0.2px;
  text-align: center;
  color: #000000;
}
.winning_box_circle_elite8_active {
  width: 50px;
  cursor: pointer;
  height: 50px;
  line-height: 45px;
  display: inline-block;
  margin: 5px;
  font-size: 23px;
  font-weight: 500;
  letter-spacing: -0.2px;
  text-align: center;
  color: #fb6e00;
  vertical-align: middle;
}
.inner-div-select-elite8 {
  height: 50px;
  width: 50px;
  padding-top: 2px;
  pointer-events: none;
}
.lock {
  display: block;
  max-width: 66%;
  height: auto;
  height: 17px;
  margin-top: -11px;
  margin-left: 19px;
}
@font-face {
  font-family: "Orbitron";
  font-style: normal;
  font-weight: 400;
  src: local("Orbitron Regular"), local("Orbitron-Regular"),
    url(https://fonts.gstatic.com/s/orbitron/v10/yMJRMIlzdpvBhQQL_Qq7dy0.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
.edit_numbers {
  text-align: center;
}
.edit_numbers_circle_elite8 {
  cursor: pointer;
  margin: 5px;
  border-radius: 25px;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: -0.1px;
  text-align: center;
  color: #000000;
  border-radius: 25px;
  vertical-align: middle;
}
.pick_button_active_elite8 {
  border: 3px solid #fb6e00;
  line-height: 44px;
}
.edit_numbers_circle_elite8_active {
  border-radius: 25px;
  cursor: pointer;
  margin: 5px;
  width: 50px;
  height: 50px;
  padding: 5px;
  line-height: 20px;
  font-family: Poppins;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.1px;
  text-align: center;
  color: rgba(0, 0, 0, 0.48);
  vertical-align: middle;
}
.edit_numbers_circle_elite8_active img {
  margin: 0 auto;
  width: 14px;
  height: 18px;
}

.winning_box_numbers_elite8 {
  margin: 20 auto;
  max-width: 100%;
  border-radius: 23px;
  background-color: #1e1e1e;
  padding: 0px 20px;
  width: 400px;
}
.winning_box_circle_elite8 {
  background-position: center;
  background-size: cover;
  width: 60px;
  height: 60px;
  line-height: 60px;
  float: none;
  display: inline-block;
  margin: 14px 15px;
  font-size: 26.8px;
  font-weight: 500;
  letter-spacing: -0.2px;
  text-align: center;
  color: #000000;
}

.winning_box_numbers.elite8 {
  padding: 10px 0px;
  text-align: center;
}
.elite8 .box_0 {
  margin-left: 28px;
  margin-top: 4px;
}
.elite8 .box_1 {
  margin-top: 4px;
  margin-left: 13px;
}
.elite8 .box_2 {
  margin-top: 4px;
  margin-left: 69px;
}
.elite8 .box_3 {
  margin-top: 4px;
  margin-left: 132px;
}
.elite8 .box_4 {
  margin-top: 4px;
  margin-left: 190px;
}
.elite8 .box_5 {
  margin-top: 4px;
  margin-left: 250px;
}
.elite8 .box_6 {
  margin-top: 4px;
  margin-left: 231px;
}
.elite8 .box_7 {
  margin-top: 4px;
  margin-left: 291px;
}
