.__list-item{
    margin: 1em 0;
    .__star {
        background: #fb6e00;
        font-size: 16px;
        min-width: 1em;
        height: 1em;
        transform: rotate(45deg);
        position: relative;
        margin: 0.3em 0.8em auto 0; 
      }
      .__check{
          transform: rotate(-45deg);
          width: 0.8em;
          position: absolute;
          z-index: 1;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
          font-weight: bold;
          color: #fff;
      }
      .__star::before, .__star::after{
        content: '';
        position: absolute;
        top: 0;
        content: "";
        left: 0;
        width: 100%;
        height: 100%;
        background: #fb6e00;
      }
      .__star:before {
        transform: rotate(60deg);
      }
      .__star::after{
        transform: rotate(120deg);
      }
      .__list-item{
          transform: initial;
          font-size: 14px;
          margin: 0.8em 5.5em 0.8em 0.6em;
          &.__first-child{
              margin-top: 1.2em;
          }
          .__star{
              transform: initial;
              font-size: 0.5714em;
              margin: 1em;
              .__check{
                  display: none;
              }
              &::before{
                  transform: initial;
              }
              &::after{
                  content: initial;
              }
          }
      }
      @media (max-width: 500px) {
          .__star{
              font-size: 10px;
              margin-top: 0.7em;
              .__check{
                  width: 0.9em;
              }
          }
      }
}