nav.__appbar{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background: #111111;
    text-transform: uppercase;
    font-weight: 500;
    padding: 0.32em 0;
    z-index: 4;
    color: #fff;
    .__nav-links{
        margin: 0;
        list-style-type: none;
        li{
            margin: 0 2.2em;
            position: relative;
            &:last-child{
                margin-right: 0;
            }
            >a{
                display: inline-block;
                &.active, &:hover{
                    color: #fb6e00;
                }
            }
            &.__game-center{
                margin: 0 0 0 3em;
            }
        }
    }
    button{
        background: transparent;
        border: none;
        outline: none;
        padding: 0;
    }
}


@media (min-width: 1199px){
    nav.__appbar{
        .__partner-with-us-btn{
            border: 1px solid;
            padding: 0.4em 0.6em;
            border-radius: 6px;
            font-size: 0.875em;
            font-weight: 600;
        }
    }
}
@media (max-width: 1290px){
    nav.__appbar{
        padding: 0.875em 0;
        .__nav-links{
            position: absolute;
            width: 100%;
            left: 0;
            top: 100%;
            overflow: hidden;
            transition: ease-in all 0.4s;
            display: block;
            background: #111111;
            max-height: 0;
            &.open{
                max-height: 1000px;
            }
            li{
                margin: 2em 0;
                text-align: center;
            }
        }
        .__close, .__menu{
            width: 1.95em;
            height: 1.95em;
            >span{
                height: 4px;
                background: #fb6e00;
                border-radius: 4px;
                display: block;
                transition: linear 0.2s all;
            }
        }
        .__close{
            span {
                position: relative;
                &:first-child{
                    transform: rotate(45deg);
                    transform-origin: center center;
                    top: 2px;
                }
                &:nth-child(2){
                    opacity: 0;
                    position: absolute;
                }
                &:last-child{
                    transform: rotate(135deg);
                    transform-origin: center center;
                    top: -2px;
                }
            }
        }
        .__menu{
            span:nth-child(2){
                margin: 4px 0;
            }
        }
    }
}



nav.__appbar{
    .__profile-button{
        width: 3.4em;
        height: 3.4em;
        display: flex;
        margin: auto;
        &::after{
            border-right: 5px solid transparent;
            border-left: 5px solid transparent;
            border-top: 5px solid;
            content: '';
            display: inline-block;
            margin: auto -1em auto auto;
        }
        &:focus ~ .__drop-down{
            opacity: 1;
            visibility: visible;
        }
    }
    .__drop-down{
        position: absolute;
        right: 0;
        white-space: nowrap;
        background: #fb6e00;
        border-radius: 3px;
        padding: 0.5em;
        visibility: hidden;
        opacity: 0;
        transition: linear 0.3s all;
        top: calc(100% + 0.2em);
        >*{
            padding: 0.5em;
            display: block;
            border-radius: 2px;
            &:hover{
                background: #ff5722;
                cursor: pointer;
            }
        }
    }
}

@media (max-width: 1290px){
    nav.__appbar{
        font-size: 16px;
        .__profile-links{
            display: block;
            .__profile-button:focus{
                ~ .__drop-down{
                    max-height: 1000px;
                    margin: 1em 1em 0 1em;
                }
            }
        }
        .__drop-down{
            position: static;
            max-height: 0;
            overflow: hidden;
            transition: linear 0.3s all;
        }
    }
}