/* old style.css */
@import url("https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}
@font-face {
  font-family: "jibril";
  src: url(./assets/fonts/Jibril.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face{
  font-family: 'Marion';
  src: url('./assets/fonts/Marion-Regular.ttf');
}
@font-face {
  font-family: "nike_combat_stencilregular";
  /* src: url(./assets/fonts/combat_stencil-webfont.html); */
  src: url(./assets/fonts/nike_combat_stencil-webfontd41dd41d.eot?#iefix)
      format("embedded-opentype"),
    url(./assets/fonts/nike_combat_stencil-webfont.woff2) format("woff2"),
    url(./assets/fonts/nike_combat_stencil-webfont.woff) format("woff"),
    url(./assets/fonts/nike_combat_stencil-webfont.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "atleticoregular_demo";
  src: url(./assets/fonts/atletico-regulardemo-webfont.eot);
  src: url(./assets/fonts/atletico-regulardemo-webfontd41dd41d.eot?#iefix)
      format("embedded-opentype"),
    url(./assets/fonts/atletico-regulardemo-webfont.woff2) format("woff2"),
    url(./assets/fonts/atletico-regulardemo-webfont.woff) format("woff"),
    url(./assets/fonts/atletico-regulardemo-webfont.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "FontAwesome";
  font-weight: normal;
  font-style: normal;
  src: url("http://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?v=4.3.0");
  src: url("http://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?#iefix&v=4.3.0")
      format("embedded-opentype"),
    url("http://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff2?v=4.3.0")
      format("woff2"),
    url("http://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff?v=4.3.0")
      format("woff"),
    url("http://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.ttf?v=4.3.0")
      format("truetype"),
    url("http://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.svg?v=4.3.0#fontawesomeregular")
      format("svg");
}
body {
  background: #000;
}
.container-fluid {
  width: 100%;
  margin: auto;
  max-width: 100%;
  padding: 0px;
}
@media (max-width: 1200px) {
  .container {
    width: 100% !important;
    padding: 0px !important;
  }
}
a:hover {
  text-decoration: none;
}
h1,
h2,
h3,
h4,
p {
  margin: 0px !important;
}
.container {
  position: relative;
  max-width: 100% !important;
  width: 100%;
  padding: 0px !important;
}
.p-o {
  padding: 0px;
}
/**### Main Slider  ### **/
.main-top-slider {
  position: relative;
  clear: both;
  padding: 0px;
}
.main-top-slider .left-arrow {
  position: absolute;
  left: 10px;
  width: 40px;
  top: 39%;
  z-index: 100;
  cursor: pointer;
}
.main-top-slider .right-arrow {
  position: absolute;
  right: 10px;
  width: 40px;
  top: 39%;
  z-index: 100;
  cursor: pointer;
}
/**### Slide One###**/
.main-top-slider .slide-one {
  padding: 5% 0px;
  position: relative;
  text-align: right;
  background-repeat: no-repeat;
  background-size: cover !important;
}
.main-top-slider .slide-one h1 {
  color: #ff7b00;
  font-size: 120px;
  line-height: 100px;
  font-weight: 700;
  text-align: right;
  margin: 0px;
  padding: 0px 50px;
  font-family: "Titillium Web", sans-serif;
}
.main-top-slider .slide-one p {
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  text-align: right;
  margin: 0px;
  padding: 30px 50px;
  font-family: "Titillium Web", sans-serif;
}
.main-top-slider .slide-one .span1 {
  color: #ff7b00;
  font-size: 25px;
  text-transform: uppercase;
  font-weight: 600;
}
.main-top-slider .slide-one a {
  display: inline-block;
  vertical-align: top;
  background: #0b5c95;
  font-family: "Titillium Web", sans-serif;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  padding: 12px 25px;
  border-radius: 5px;
  font-size: 22px;
  margin-right: 50px;
}
@media (max-width: 1200px) {
  .main-top-slider .slide-one {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (max-width: 991px) {
  .main-top-slider .slide-one {
    padding-right: 40px;
    padding-left: 40px;
  }
  .main-top-slider .slide-one h1 {
    font-size: 80px;
    line-height: 80px;
  }
  .main-top-slider .slide-one p {
    font-size: 15px;
  }
  .main-top-slider .slide-one .span1 {
    font-size: 21px;
  }
  .main-top-slider .slide-one a {
    padding: 10px 20px;
    font-size: 20px;
  }
  .main-top-slider .left-arrow {
    width: 30px;
  }
  .main-top-slider .right-arrow {
    width: 30px;
  }
}
@media (max-width: 767px) {
  .main-top-slider .slide-one h1 {
    font-size: 60px;
    line-height: 60px;
  }
}
@media (max-width: 600px) {
  .main-top-slider .slide-one h1 {
    font-size: 50px;
    line-height: 50px;
  }
  .main-top-slider .slide-one p {
    font-size: 15px;
  }
  .main-top-slider .slide-one .span1 {
    font-size: 18px;
  }
  .main-top-slider .slide-one a {
    font-size: 18px;
  }
}
/**### Slide TWO###**/
.main-top-slider .slide-two {
  padding: 5% 0px;
  position: relative;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover !important;
}
.main-top-slider .slide-two h1 {
  color: #ff7b00;
  font-size: 120px;
  line-height: 100px;
  font-weight: 700;
  text-align: center;
  margin: 0px;
  padding: 0px 0px 75px 0px;
  font-family: "Titillium Web", sans-serif;
}
.main-top-slider .counter {
  position: absolute;
  left: -25px;
  top: 31px;
  width: 50px;
  height: 50px;
  background: #2e91ba;
  line-height: 40px;
  border: 5px solid #252525;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
}
.main-top-slider .slide-two .box1 {
  display: inline-block;
  width: 175px;
  margin-right: 50px;
  vertical-align: top;
}
.main-top-slider .slide-two .box3 {
  display: inline-block;
  width: 350px;
  margin-right: 50px;
  vertical-align: top;
}
.main-top-slider .slide-two .box4 {
  display: inline-block;
  width: 150px;
  vertical-align: top;
}
.main-top-slider .slide-two .box1 p {
  font-size: 15px;
  padding: 15px 0px;
  text-align: left;
  color: #fff;
  font-weight: 400;
  font-family: "Titillium Web", sans-serif;
}
.main-top-slider .slide-two .box1 p span {
  color: #ff7b00;
}
.main-top-slider .slide-two .box3 p {
  font-size: 15px;
  padding: 15px 0px;
  text-align: left;
  color: #fff;
  font-weight: 400;
  font-family: "Titillium Web", sans-serif;
}
.main-top-slider .slide-two .box3 p span {
  color: #ff7b00;
}
.main-top-slider .slide-two .box4 p {
  font-size: 15px;
  padding: 15px 0px;
  text-align: left;
  color: #fff;
  font-weight: 400;
  font-family: "Titillium Web", sans-serif;
}
.main-top-slider .slide-two .box4 p span {
  color: #ff7b00;
}
.main-top-slider .slide-two .boxes-iner {
  position: relative;
  width: 100%;
  display: inline-block;
  vertical-align: top;
  background: url(./assets/images/bgblack.png) no-repeat;
  background-size: cover;
  height: 120px;
  line-height: 120px;
  border: 5px solid #252525;
  text-align: center;
}
.main-top-slider .slide-two .boxes-iner .iner-main {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  line-height: normal;
}
.main-top-slider .slide-two .boxes-iner h2 {
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  line-height: normal;
}
.main-top-slider .slide-two .boxes-iner h2 span {
  color: #ff7b00;
  font-size: 25px;
  font-weight: 700;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .main-top-slider .slide-two {
    padding: 10% 70px;
  }
}
@media (max-width: 1180px) {
  .main-top-slider .slide-two h1 {
    font-size: 100px;
    line-height: 80px;
    padding: 0px 0px 50px 0px;
  }
  .main-top-slider .slide-two .box1 {
    width: 40%;
    max-width: 350px;
    margin: 5px 20px;
  }
  .main-top-slider .slide-two .box3 {
    width: 40%;
    max-width: 350px;
    margin: 5px 20px;
  }
  .main-top-slider .slide-two .box4 {
    width: 40%;
    max-width: 350px;
    margin: 5px 20px;
  }
  .main-top-slider .slide-two .boxes-iner h2 {
    font-size: 15px;
  }
  .main-top-slider .slide-two .boxes-iner h2 span {
    font-size: 20px;
  }
}
@media (max-width: 991px) {
  .main-top-slider .slide-two {
    padding: 10% 0px;
  }
  .main-top-slider .slide-two .box3 {
    width: 55%;
    max-width: 350px;
    margin: 5px 20px;
  }
  .main-top-slider .slide-two .box4 {
    width: 30%;
    max-width: 350px;
    margin: 5px 20px;
  }
}
@media (max-width: 767px) {
  .main-top-slider .slide-two h1 {
    font-size: 70px;
    line-height: 70px;
    padding: 0px 0px 50px 0px;
  }
  .main-top-slider .slide-two .box1 {
    width: 47%;
    max-width: 350px;
    margin: 5px 0px;
  }
  .main-top-slider .slide-two .box3 {
    width: 70%;
    max-width: 350px;
    margin: 5px 0px;
  }
  .main-top-slider .slide-two .box4 {
    width: 25%;
    max-width: 350px;
    margin: 5px 0px;
  }
}
@media (max-width: 480px) {
  .main-top-slider .slide-two h1 {
    font-size: 50px;
    line-height: 50px;
    padding: 0px 0px 30px 0px;
  }
  .main-top-slider .counter {
    top: 35px;
    width: 35px;
    height: 35px;
    line-height: 25px;
    font-size: 12px;
  }
  .main-top-slider .slide-two .box1 p {
    font-size: 10px;
  }
  .main-top-slider .slide-two .box3 p {
    font-size: 10px;
  }
  .main-top-slider .slide-two .box4 p {
    font-size: 10px;
  }
}
@media (max-width: 414px) {
  .main-top-slider .slide-two h1 {
    font-size: 30px;
    line-height: 50px;
    padding: 0px 0px 20px 0px;
  }
  .main-top-slider .slide-two .boxes-iner h2 {
    font-size: 10px;
  }
}
/**### Slide Three ###**/
.main-top-slider .slide-three {
  padding: 0px;
  position: relative;
  text-align: left;
  background-repeat: no-repeat;
  background-size: cover !important;
}
.main-top-slider .slide-three-left {
  position: relative;
  padding: 5% 0px;
  text-align: center;
}
.main-top-slider .slide-three-left h1 {
  color: #ffffff;
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  margin: 0px;
  padding: 0px;
  font-family: "Titillium Web", sans-serif;
}
.main-top-slider .slide-three-left h2 {
  color: #ffffff;
  font-size: 50px;
  line-height: 60px;
  font-weight: 700;
  text-align: center;
  margin: 0px;
  padding: 10px 0px 0px 0px;
  font-family: "Titillium Web", sans-serif;
}
.main-top-slider .slide-three-left h2 span {
  color: #ff7b00;
}
.main-top-slider .slide-three-right {
  width: 100%;
  background: url(./assets/images/bg-slide2.png) repeat;
  display: table;
  padding: 15px 0px 30px 0px;
  margin: 0px 0px 5% 0px;
}
.main-top-slider .slide-three-right h2 {
  padding: 15px;
  color: #ff7b00;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
  margin: 0px;
}
.main-top-slider .slide-three-right .list-item {
  position: relative;
  padding: 0px 30px;
  text-align: left;
}
.slide-three-right .list-item ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
  text-align: center;
}
.slide-three-right .list-item li {
  display: inline-block;
  position: relative;
  vertical-align: top;
  margin-bottom: 10px;
  width: 100%;
  max-width: 30%;
  font-size: 15px;
  font-weight: 400;
  line-height: 50px;
  padding: 0px 0px 0px 70px;
  text-align: left;
  color: #fefefe;
  text-transform: uppercase;
}
.slide-three-right .list-item li span {
  color: #ffc053;
}
.slide-three-right .list-item li .icons-left {
  position: absolute;
  left: 0px;
  top: 5px;
  height: 40px;
  width: 50px;
  line-height: 40px;
  text-align: center;
}
.slide-three-right .list-item li .icons-left img {
  display: inline-block;
  vertical-align: middle;
  max-width: 35px;
}
.refr {
  background: #bd0a44;
}
.refr img {
  width: 25px !important;
}
@media (max-width: 1200px) {
  .main-top-slider .slide-three {
    padding: 3% 70px;
    position: relative;
    text-align: left;
    background-repeat: no-repeat;
    background-size: cover !important;
  }
}
@media (max-width: 1024px) {
  .main-top-slider .slide-three {
    padding: 3% 30px;
  }
  .main-top-slider .slide-three-left h1 {
    font-size: 30px;
  }
  .main-top-slider .slide-three-left h2 {
    font-size: 40px;
    line-height: 50px;
  }
}
@media (max-width: 991px) {
  .main-top-slider .slide-three {
    padding: 0px 0px;
  }
  .main-top-slider .slide-three-left {
    padding: 30px 0px;
  }
  .main-top-slider .slide-three-right h2 {
    padding: 15px;
  }
  .slide-three-right .list-item ul {
    text-align: left;
  }
  .slide-three-right .list-item li {
    max-width: 48%;
  }
  .slide-three-right .list-item li {
    padding: 0px 0px 0px 40px;
    line-height: 15px;
    font-size: 13px;
  }
  .slide-three-right .list-item li .icons-left {
    height: 30px;
    width: 30px;
    line-height: 30px;
    top: 0px;
  }
  .slide-three-right .list-item li .icons-left img {
    display: inline-block;
    vertical-align: middle;
    max-width: 20px;
  }
}
@media (max-width: 768px) {
  .main-top-slider .slide-three-left h1 {
    font-size: 25px;
  }
  .main-top-slider .slide-three-left h2 {
    font-size: 30px;
    line-height: 35px;
  }
}
@media (max-width: 600px) {
  .main-top-slider .slide-three-left h2 br {
    display: none;
  }
}
@media (max-width: 480px) {
  .main-top-slider .slide-three-left h2 {
    font-size: 25px;
    line-height: 30px;
  }
  .main-top-slider .slide-three-right .list-item {
    padding: 0px 15px;
  }
}
@media (max-width: 480px) {
  .main-top-slider .slide-three-left h1 {
    font-size: 18px;
  }
  .main-top-slider .slide-three-left h2 {
    font-size: 18px;
    line-height: 25px;
  }
}
/**### Slide Foure###**/
.main-top-slider .slide-foure {
  padding: 7% 0px 9.5% 0px;
  position: relative;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover !important;
}
.main-top-slider .slide-foure h1 {
  color: #fff;
  font-size: 50px;
  line-height: 100px;
  font-weight: 700;
  text-align: center;
  margin: 0px;
  padding: 0px;
  font-family: "Titillium Web", sans-serif;
}
.main-top-slider .slide-foure h1 span {
  color: #ff7b00;
}
.main-top-slider .list-item {
  position: relative;
  padding-top: 20px;
  text-align: center;
  padding-right: 50px;
  padding-left: 50px;
}
.main-top-slider .list-item img {
  width: 100%;
}
@media (max-width: 1200px) {
  .main-top-slider .slide-foure {
    padding: 11% 70px;
  }
}
@media (max-width: 1024px) {
  .main-top-slider .slide-foure h1 {
    font-size: 40px;
    line-height: 50px;
  }
}
@media (max-width: 991px) {
  .main-top-slider .slide-foure {
    padding: 11% 30px;
  }
  .main-top-slider .slide-foure h1 {
    font-size: 30px;
    line-height: 40px;
  }
  .main-top-slider .slide-foure .col-xs-3 {
    padding: 0px 1px;
  }
}
@media (max-width: 480px) {
  .main-top-slider .slide-foure h1 {
    font-size: 20px;
    line-height: 25px;
  }
  .main-top-slider .slide-foure .col-xs-3 {
    padding: 0px 1px;
    width: 100%;
    margin-bottom: 5px;
    max-width: 150px;
    margin: auto;
    float: none;
  }
}
/**### End Main Slider  ### **/
/**### Sports Slider  ### **/
.sports-slider {
  position: relative;
  clear: both;
  width: 100%;
  padding: 75px 0px;
  background: #ffffff;
  text-align: center;
}
.sports-slider .yelow-line {
  width: 120px;
  height: 5px;
  display: inline-block;
  vertical-align: top;
  background: #ff7b00;
  clear: both;
}
.sports-slider h1 {
  color: #3e3e3e;
  font-size: 35px;
  font-weight: 600;
  margin: 0px;
  padding: 0px;
  max-width: 768px;
  width: 100%;
  display: inline-block;
  vertical-align: top;
  text-transform: uppercase;
}
.sports-slider p {
  color: #3e3e3e;
  font-size: 20px;
  font-weight: 400;
  margin: 0px;
  padding: 20px 0px;
  max-width: 768px;
  width: 100%;
  display: inline-block;
  vertical-align: top;
}
.sports-box {
  position: relative;
  clear: both;
  padding: 50px 50px 0px 50px;
}
.sports-box img {
  width: 100%;
}
.sports-box .left-arrow {
  position: absolute;
  top: 46%;
  left: 0%;
  width: 35px;
  z-index: 100;
  cursor: pointer;
}
.sports-box .right-arrow {
  position: absolute;
  top: 46%;
  right: 0%;
  width: 35px;
  z-index: 100;
  cursor: pointer;
}
.slick-slide {
  outline: none;
  cursor: pointer;
}
@media (max-width: 991px) {
  .sports-slider .yelow-line {
    width: 100px;
  }
  .sports-slider h1 {
    font-size: 30px;
  }
  .sports-slider p {
    font-size: 18px;
  }
}
/**### End Sports Slider  ### **/
/**### Featured Games  ### **/
.featured-game {
  position: relative;
  background: url(./assets/images/feature-bg.png) no-repeat;
  background-size: cover;
  padding: 75px 0px;
}
.featured-game h1 {
  text-align: center;
  color: #ff7b00;
  font-size: 45px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0px;
  padding: 0px 0px 30px 0px;
}
.features-slide {
  position: relative;
  width: 100%;
  margin: auto;
  clear: both;
  max-width: 768px;
  padding: 0px 50px;
  text-align: center;
}
.features-slide .iner-slide {
  display: inline-block !important;
  float: none !important;
  width: 100%;
  vertical-align: top;
}
.features-slide .iner-slide img {
  display: inline-block !important;
  width: 100%;
  max-width: 600px;
}
.features-slide .left-arrow {
  position: absolute;
  top: 46%;
  left: 0%;
  width: 25px;
  z-index: 100;
  cursor: pointer;
}
.features-slide .right-arrow {
  position: absolute;
  top: 46%;
  right: 0%;
  width: 25px;
  z-index: 100;
  cursor: pointer;
}
/**### End Featured Games  ### **/
/**### Advertise Banner  ### **/
.advertiser-banner {
  position: relative;
  background: #f1f1f1;
  position: relative;
  padding: 50px 0px;
  text-align: center;
}
.advertiser-banner .add-area {
  display: table;
  width: 100%;
  background: #3e3e3e;
  padding: 20px 30px;
  line-height: 100px;
}
.advertiser-banner .add-area h1 {
  text-align: left;
  color: #fff;
  font-weight: 400;
  line-height: normal;
  display: inline-block;
  vertical-align: middle;
  font-size: 30px;
  margin: 0px;
  padding: 10px 5% 10px 5%;
  text-transform: uppercase;
}
.advertiser-banner .add-area button {
  display: inline-block;
  width: 100%;
  max-width: 175px;
  padding: 12px 0px;
  color: #fff;
  background: #c5242b;
  font-weight: 500;
  font-size: 20px;
  outline: none;
  line-height: normal;
  border: none;
}
@media (max-width: 991px) {
  .advertiser-banner .add-area h1 {
    text-align: center;
    font-size: 25px;
  }
}
/**### End Advertise Banner  ### **/
/**###  Contact US  ### **/
.contact-us {
  position: relative;
  text-align: center;
  height: 255px;
  background-color: #1e1e1e;
}
.contact-top {
  position: relative;
  clear: both;
  width: 100%;
  text-align: center;
}
.contact-top .yelow-line {
  width: 120px;
  height: 5px;
  display: inline-block;
  vertical-align: top;
  background: #ff7b00;
  clear: both;
}
.contact-top h1 {
  color: #fff;
  font-size: 35px;
  font-weight: 600;
  margin: 0px;
  padding: 0px;
  max-width: 768px;
  width: 100%;
  display: inline-block;
  vertical-align: top;
  text-transform: uppercase;
}
.contact-top p {
  color: #cbcbcb;
  font-size: 18px;
  font-weight: 200;
  margin: 0px;
  padding: 20px 0px;
  width: 100%;
}
/***Contact left***/
.contact-left {
  position: relative;
  padding: 50px 0px 0px 0px;
  text-align: left;
}
.contact-left .box {
  margin-bottom: 30px;
  position: relative;
  width: 100%;
}
.contact-left h2 {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  margin: 0px;
  padding: 0px 0px 10px 0px;
}
.contact-left a {
  display: block;
  margin: 5px 0px;
  color: #cbcbcb;
  font-size: 18px;
  font-weight: 300;
}
.social-contact {
  position: relative;
  text-align: left;
}
.social-contact ul {
  margin: 0px;
  padding: 0px;
  text-align: left;
  list-style: none;
}
.social-contact li {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}
.social-contact a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: block;
  text-align: center;
  color: #fff;
  font-size: 22px;
  border-radius: 50%;
}
a.fb {
  background: #004a80;
}
a.twt {
  background: #00aeef;
}
a.lnkdin {
  background: #038bb2;
}
.contact-right {
  position: relative;
  width: 100%;
  margin-top: 50px;
}
.contact-right:before {
  display: block;
  content: "";
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 10px;
  left: 10px;
  border: 1px solid #fff;
}
.contact-right h1 {
  color: #ff7b00;
  font-size: 30px;
  background: url(./assets/images/visit-bg.png) repeat;
  font-weight: 300;
  margin: 0px;
  padding: 20px 0px;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 45%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
  text-transform: uppercase;
}
@media (max-width: 991px) {
  .contact-right h1 {
    font-size: 25px;
  }
}
@media (max-width: 480px) {
  .contact-right h1 {
    font-size: 20px;
  }
}
/**### End Contact US  ### **/
/**### Footer ### **/
footer {
  position: relative;
  padding: 40px 0px 0px 0px;
  text-align: center;
  display: table;
  width: 100%;
}
footer p {
  font-family: Poppins;
  font-size: 16px;
  letter-spacing: 2px;
  text-align: center;
  color: #ffffff;
}
/**### End Footer US  ### **/
/****Live Score***/
#livescore {
  background-image: url(./assets/images/livescore/bg.png);
  height: 100%;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.live_logo {
  position: relative;
  text-align: center;
  padding: 5% 0px;
}
.live_logo img {
  display: inline-block;
  vertical-align: top;
  max-width: 250px;
  width: 100%;
}
.top-text {
  position: relative;
  text-align: center;
  padding: 0px;
}
.top-text h1 {
  color: #fefefe;
  font-size: 40px;
  font-weight: 500;
  letter-spacing: 5px;
  margin: 0px;
  padding: 0px;
  text-align: center;
  text-transform: uppercase;
}
.top-text h2 {
  padding: 30px 0px;
  color: #ff7500;
  font-size: 30px;
  font-weight: 500;
  margin: 0px;
  text-align: center;
  letter-spacing: 5px;
}
.top-text p {
  color: #fefefe;
  font-size: 20px;
  font-weight: 300;
  margin: 0px;
  padding: 0px;
  display: inline-block;
  vertical-align: top;
  letter-spacing: 5px;
}
.top-text p .infobtn {
  display: inline-block;
  vertical-align: middle;
  color: #ff7500;
  font-size: 30px;
  margin-right: 10px;
}
@media (max-width: 991px) {
  .top-text h1 {
    font-size: 30px;
    letter-spacing: 3px;
  }
  .top-text h2 {
    font-size: 25px;
    letter-spacing: 3px;
    padding: 20px 0px;
  }
  .top-text p {
    font-size: 20px;
    letter-spacing: 3px;
  }
  .top-text p .infobtn {
    font-size: 25px;
  }
}
@media (max-width: 480px) {
  .top-text h1 {
    font-size: 25px;
    letter-spacing: 2px;
  }
  .top-text h2 {
    font-size: 20px;
    letter-spacing: 2px;
  }
  .top-text p {
    font-size: 17px;
    line-height: 30px;
    letter-spacing: 2px;
  }
}
/****Left Side***/
.live_midle_cont {
  position: relative;
  clear: both;
  width: 100%;
  display: table;
  padding: 50px 0px 100px 0px;
}
.left_side_live {
  position: relative;
  padding: 0px 0px 10px 0px;
  text-align: center;
}
.left_side_live h1 {
  color: #fefefe;
  font-size: 18px;
  font-weight: 500;
  margin: 0px;
  padding: 0px 0px 0px 50px;
  vertical-align: top;
  letter-spacing: 5px;
  text-align: left;
  display: inline-block;
  vertical-align: top;
  max-width: 700px;
  position: relative;
  text-transform: uppercase;
  line-height: 25px;
}
.left_side_live h1 .infobtn {
  display: inline-block;
  vertical-align: middle;
  color: #ff7500;
  font-size: 20px;
  line-height: 30px;
  position: absolute;
  left: 5px;
}
.left_side_live ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
  padding: 20px 0px;
}
.left_side_live li {
  text-align: left;
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 20px 0px 20px 50px;
  color: #fefefe;
  text-transform: uppercase;
  font-size: 16px;
  width: 100%;
  font-weight: 400;
  background-size: 35px !important;
}
.left_side_live li .spans {
  font-size: 17px;
  font-weight: 600;
  color: #fff;
}
li#booster {
  background: url(./assets/images/step/booster.png) no-repeat left center;
}
li#line-up {
  background: url(./assets/images/step/line-up.png) no-repeat left center;
}
li#goals {
  background: url(./assets/images/step/no-entry.png) no-repeat left center;
}
li#power-play {
  background: url(./assets/images/step/play.png) no-repeat left center;
}
li#favourite {
  background: url(./assets/images/step/favourite.png) no-repeat left center;
}
li#refersh {
  background: url(./assets/images/livescore/referesh.png) no-repeat left center;
}
li#lock {
  background: url(./assets/images/livescore/lock.png) no-repeat left center;
}
li#bust {
  background: url(./assets/images/livescore/bust.png) no-repeat left center;
}
li#superbust {
  background: url(./assets/images/livescore/super-bust.png) no-repeat left
    center;
}
li#add {
  background: url(./assets/images/livescore/add.png) no-repeat left center;
}
li#minus {
  background: url(./assets/images/livescore/drop.png) no-repeat left center;
}
li#undo {
  background: url(./assets/images/livescore/undo.png) no-repeat left center;
}
.left_side_live button {
  width: 100%;
  display: inline-block;
  max-width: 300px;
  letter-spacing: 2px;
  text-shadow: 4px 4px 2px rgba(150, 150, 150, 1);
  text-align: center;
  height: 50px;
  color: #fefefe;
  font-size: 15px;
  font-weight: 400;
  background: -webkit-linear-gradient(
    340deg,
    rgb(255, 110, 2) 0%,
    rgb(255, 141, 1) 100%
  );
  background: -o-linear-gradient(
    340deg,
    rgb(255, 110, 2) 0%,
    rgb(255, 141, 1) 100%
  );
  background: -ms-linear-gradient(
    340deg,
    rgb(255, 110, 2) 0%,
    rgb(255, 141, 1) 100%
  );
  background: -moz-linear-gradient(
    340deg,
    rgb(255, 110, 2) 0%,
    rgb(255, 141, 1) 100%
  );
  background: linear-gradient(
    110deg,
    rgb(255, 110, 2) 0%,
    rgb(255, 141, 1) 100%
  );
  outline: none;
  border: none;
  text-transform: uppercase;
}
.right_side_live {
  position: relative;
  padding: 0px;
  text-align: center;
}
.right_top_head {
  position: relative;
}
.right_top_head h1 {
  color: #f5f5f5;
  font-size: 20px;
  font-weight: 400;
  margin: 0px;
  padding: 0px 0px 50px 0px;
  letter-spacing: 2px;
  text-align: center;
  line-height: 35px;
}
.right_top_head h1 .span1 {
  color: #fff3a0;
}
.right_top_head h1 .span2 {
  color: #ffb22b;
}
.score-all {
  position: relative;
  padding: 0px;
  max-height: 830px;
  overflow-y: auto;
  text-align: center;
}
.right_side_live h4 {
  display: inline-block;
  vertical-align: top;
  background: #f7941d;
  color: #fff;
  font-size: 30px;
  font-weight: 600;
  border-radius: 20px;
  padding: 10px 20px;
}
.score_live_box {
  position: relative;
  padding: 0px 50px;
  width: 100%;
  max-width: 900px;
  margin: 0px 0px 70px 0px;
  display: inline-block;
  vertical-align: top;
}
.score_live_box .boxes {
  border-left: 5px solid #1ca4ba;
  background: url(./assets/images/livescore/01.png) no-repeat;
  background-size: cover;
  margin-bottom: 15px;
  display: table;
  width: 100%;
  padding: 10px;
  position: relative;
}
.boxes-cont {
  line-height: 136px;
  border: 1px solid #959595;
  background: url(./assets/images/livescore/02.png) no-repeat;
  background-size: cover;
  text-align: center;
  position: relative;
}
.ad-team {
  position: absolute;
  left: 0px;
  width: 100%;
  cursor: pointer;
  top: 0px;
  height: 136px;
  text-align: center;
  line-height: 136px;
  background: url(./assets/images/swap/team-bg.png) repeat;
}
.ad-team img {
  display: inline-block;
  vertical-align: middle;
}
.boxes-cont .iner-box {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  line-height: normal;
  padding: 15px 0px 0px 0px;
}
.p10 {
  padding: 0px 10px;
}
.boxes-cont ul {
  padding: 20px 0px 0px 0px;
  text-align: center;
  margin: 0px;
  list-style: none;
}
.boxes-cont li {
  display: inline-block;
  vertical-align: middle;
  margin: 0px 5px;
}
.boxes-cont li img {
  width: 30px;
}
.boxes-cont a {
  text-decoration: none;
}
img#locked {
  width: 27px;
}
.boxes-cont h1 {
  color: #333333;
  font-size: 20px;
  font-weight: 600;
  margin: 0px;
  padding: 0px;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  text-shadow: 2px 2px 4px rgba(150, 150, 150, 1);
}
.boxes-cont h2 {
  color: #d91b1b;
  font-size: 40px;
  text-shadow: 2px 2px 4px rgba(150, 150, 150, 1);
  font-weight: 600;
  margin: 0px;
  padding: 5px 0px 0px 0px;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
}
.boxes-cont p {
  color: #333333;
  font-size: 17px;
  font-weight: 600;
  margin: 0px;
  padding: 10px 0px 0px 0px;
  width: 100%;
  text-shadow: 2px 2px 4px rgba(150, 150, 150, 1);
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
}
.minus-icons {
  position: absolute;
  left: -50px;
  top: 53px;
  height: 50px;
  width: 50px;
  background: #1ca4ba;
  color: #fff;
  font-size: 30px;
  line-height: 50px;
  text-align: center;
}
.plus-icons {
  position: absolute;
  right: -50px;
  bottom: -25px;
  height: 50px;
  width: 50px;
  background: #1ca4ba;
  color: #fff;
  font-size: 25px;
  line-height: 50px;
  font-weight: 700;
  text-align: center;
  z-index: 100;
}
button.scor_live {
  width: 100%;
  background: url(./assets/images/livescore/bot-bg.png) no-repeat;
  background-size: cover;
  height: 50px;
  border: none;
  color: #fefefe;
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 4px 4px 2px rgba(150, 150, 150, 1);
  letter-spacing: 4px;
}
.not-select h1 {
  color: grey;
}
.not-select h2 {
  color: grey;
}
@media (max-width: 1024px) {
  .boxes-cont h1 {
    font-size: 15px;
  }
  .boxes-cont h2 {
    font-size: 45px;
  }
  .boxes-cont p {
    font-size: 15px;
  }
}
@media (max-width: 991px) {
  .live_midle_cont .container {
    padding: 0px;
    min-width: 1347px;
  }
  .score-all {
    height: 500px;
  }
  .boxes .col-xs-5 {
    width: 100%;
    padding: 0px;
  }
  .boxes .col-xs-2 {
    width: 100%;
  }
  .minus-icons {
    top: 45%;
  }
  .plus-icons {
    top: 43.5%;
  }
  button.scor_live {
    font-size: 16px;
  }
  .live_midle_cont {
    position: relative;
    clear: both;
    width: 100%;
    display: table;
    padding: 50px 0px;
  }
  .left_side_live {
    padding: 30px 20px 0px 20px;
    text-align: left;
  }
  .left_side_live h1 .infobtn {
    top: -5px;
  }
  .left_side_live li {
    max-width: 48%;
  }
}
@media (max-width: 600px) {
  .left_side_live li {
    max-width: 100%;
  }
}
/***Bottom ButtonS****/
.bottom_btn_live {
  position: relative; /*
	padding: 100px 20px 0px 0px;*/
  text-align: center;
}
button.btn_one_live {
  position: relative;
  width: 100%;
  color: #fff5a5;
  font-size: 45px;
  text-transform: uppercase;
  border: none;
  background: url(./assets/images/livescore/total-score.png) no-repeat;
  background-size: cover;
  height: 100px;
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
  text-shadow: 2px 2px 2px #000;
}
button {
  outline: none;
}
button.btn_two_live {
  position: relative;
  width: 100%;
  color: #fff;
  margin-top: 20px;
  font-size: 45px;
  text-transform: uppercase;
  border: none;
  background: url(./assets/images/livescore/score-bg.png) no-repeat;
  background-size: cover;
  height: 100px;
  text-align: center;
  font-weight: 700;
  text-shadow: 1px 1px 1px #000;
}
button.btn_three_live {
  position: relative;
  width: 100%;
  color: #373737;
  font-size: 45px;
  text-transform: uppercase;
  border: none;
  background: url(./assets/images/livescore/poll.png) no-repeat;
  background-size: cover;
  height: 100px;
  text-align: center;
  font-weight: 600;
  letter-spacing: 5px;
  margin-top: 20px;
  text-shadow: 1px 1px 1px #000;
}
button.btn_foure_live {
  position: relative;
  width: 100%;
  color: #373737;
  font-size: 50px;
  text-transform: uppercase;
  border: none;
  background: url(./assets/images/livescore/pool-bg.png) no-repeat;
  background-size: cover;
  height: 100px;
  text-align: center;
  font-weight: 700;
  text-shadow: 1px 1px 1px #000;
  margin-top: 20px;
}
@media (max-width: 991px) {
  .bottom_btn_live {
    padding: 0px 0px 50px 0px;
  }
}
@media (max-width: 767px) {
  .bottom_btn_live .col-xs-4 {
    width: 100%;
  }
  .bottom_btn_live .col-xs-8 {
    width: 100%;
  }
}
@media (max-width: 480px) {
  button.btn_one_live {
    font-size: 25px;
  }
  button.btn_two_live {
    font-size: 30px;
  }
  button.btn_three_live {
    font-size: 25px;
  }
  button.btn_foure_live {
    font-size: 30px;
  }
}
/**### End Live Score  ### **/
/**### Select Games ### **/
.select-game-slider {
  position: relative;
  clear: both;
  width: 100%;
  clear: both;
  padding: 0px;
}
.select-game-slider img {
  width: 100%;
  max-height: 1200px;
}
.arrow-wraper {
  opacity: 0.1;
}
.arrow-wraper:hover {
  opacity: 1;
}
.arrow-wraper:hover,
.sport-arrow {
  opacity: 1;
}
.select-game-slider .left-arrow {
  position: absolute;

  left: 30%;
  top: 83%;
  width: 35px;
  z-index: 10;
  cursor: pointer;
}
.select-game-slider .right-arrow {
  position: absolute;
  right: 30%;
  top: 83%;
  width: 35px;
  z-index: 10;
  cursor: pointer;
}
@media (max-width: 768px) {
  .select-game-slider .left-arrow {
    width: 30px;
  }
  .select-game-slider .right-arrow {
    width: 30px;
  }
}
@media (max-width: 600px) {
  .select-game-slider .left-arrow {
    width: 25px;
  }
  .select-game-slider .right-arrow {
    width: 25px;
  }
}
@media (max-width: 414px) {
  .select-game-slider .left-arrow {
    width: 20px;
  }
  .select-game-slider .right-arrow {
    width: 20px;
  }
}
.game_prod_slide {
  position: relative;
  clear: both;
  padding: 30px 0px;
  background: #f1f1f1;
  text-align: center;
}
.game_prod_slide .yelow-line {
  width: 120px;
  height: 5px;
  display: inline-block;
  vertical-align: top;
  background: #ff7b00;
  clear: both;
  margin-bottom: 50px;
}
.game_prod_slide h1 {
  color: #3e3e3e;
  font-size: 35px;
  font-weight: 600;
  padding: 0px 0px 15px 0px;
  text-align: center;
  margin: 0px;
  text-transform: uppercase;
}
.game_prod_slide h1 span {
  color: #ff7b00;
}
.slider-nav .slick-active img {
  opacity: 0.5;
}
.slider-nav .slick-current img {
  opacity: 1;
}
.game-slide {
  padding: 5px;
  float: none !important;
  display: inline-block;
  vertical-align: top;
  margin: 0px -2px;
}
.game-slide img {
  border-radius: 15px;
}
.game_prod_slide .iner {
  position: relative;
  padding: 0px 50px;
}
.game_prod_slide .iner img {
  width: 100%;
}
.game_prod_slide .iner .left-arrow {
  position: absolute;
  top: 28%;
  left: 0%;
  width: 30px;
  z-index: 100;
  cursor: pointer;
}
.game_prod_slide .iner .right-arrow {
  position: absolute;
  top: 28%;
  right: 0%;
  width: 30px;
  z-index: 100;
  cursor: pointer;
}
@media (max-width: 1024px) {
  .game_prod_slide .arrow-up {
    left: 45%;
  }
  .game_prod_slide .arrow-down {
    left: 45%;
  }
}
@media (max-width: 600px) {
  .game_prod_slide .arrow-up {
    left: 23%;
  }
  .game_prod_slide .arrow-down {
    left: 65%;
  }
}
@media (max-width: 480px) {
  .game_prod_slide .arrow-up {
    left: 40%;
  }
  .game_prod_slide .arrow-down {
    left: 40%;
  }
  .game_prod_slide .iner .left-arrow {
    width: 20px;
    left: 10px;
  }
  .game_prod_slide .iner .right-arrow {
    width: 20px;
    right: 10px;
  }
}
/****Table Games***/
.games_tables {
  position: relative;
  padding: 50px 0px; /*
	background: url(./assets/images/game-table-bg.png) no-repeat;*/
  background-size: cover;
  text-align: center;
  background-position: top 13% center;
}
.games_tables h1 {
  text-align: center;
  color: #fff;
  padding: 0px 0px 50px 0px;
  color: #fff;
  font-size: 35px;
  font-weight: 700;
  text-transform: uppercase;
}
.games_tables h1 .span1 {
  color: #ff7b00;
  font-weight: 400;
}
.games_tables h1 .span2 {
  color: #ff7b00;
  font-weight: 600;
}
.games_tables table {
  border: none;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}
.games_tables table th {
  height: 100px;
  background: #ff7b00;
  text-align: center;
  padding: 10px;
  font-size: 25px;
  color: #fff;
  font-weight: 700;
  font-family: "Titillium Web", sans-serif;
}
.games_tables table th:first-child {
  border-radius: 30px 0px 0px 0px;
}
.games_tables table th:last-child {
  border-radius: 0px 30px 0px 0px;
}
.games_tables table tr {
  background: url(./assets/images/evenbg.png) repeat;
  height: 100px;
}
.games_tables table tr:nth-child(even) {
  background: url(./assets/images/oddbg.png) repeat;
  height: 100px;
}
.games_tables table td {
  padding: 10px;
  vertical-align: middle;
  text-align: center;
  border: 0px;
}
.games_tables table td p {
  text-align: center;
  font-size: 25px;
  color: #fff;
  font-weight: 700;
  font-family: "Titillium Web", sans-serif;
  margin: 0px;
  padding: 0px;
}
button.entries {
  width: 90%;
  max-width: 140px;
  line-height: 50px;
  height: 50px;
  text-align: center;
  color: #fff;
  font-weight: 700;
  border: none;
  display: inline-block;
  vertical-align: middle;
  background: #0c9dc1;
  border-radius: 30px;
  font-size: 25px;
}
@media (max-width: 991px) {
  .games_tables table th {
    font-size: 20px;
  }
  .games_tables table td p {
    font-size: 20px;
  }
  button.entries {
    font-size: 22px;
  }
}
@media (max-width: 768px) {
  .games_tables table tr {
    height: 75px;
  }
  .games_tables table th {
    font-size: 15px;
    height: 75px;
  }
  .games_tables table td p {
    font-size: 15px;
  }
  button.entries {
    font-size: 20px;
  }
}
@media screen and (max-width: 680px) {
  .games_tables table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .games_tables table tr {
    display: block;
    margin-bottom: 20px;
    height: auto !important;
  }
  .games_tables table td {
    display: block;
    padding: 0px 0px;
    line-height: 60px;
    text-align: center;
    background: #000;
    height: 60px;
    font-size: 15px !important;
    vertical-align: middle;
  }
  .games_tables table td p {
    font-size: 18px;
  }
  .games_tables table td::before {
    content: attr(data-label);
    float: left;
    width: 40%;
    background: #ff7b00;
    color: #fff;
    text-align: left;
    height: 60px;
    line-height: 60px;
    padding: 0px 0px 0px 10px;
    font-size: 15px;
    font-weight: 700;
  }
  .games_tables table tr:last-child {
    margin-bottom: 0px;
  }
  button.entries {
    font-size: 20px;
  }
}
/***How To use****/
.how_game_sec {
  position: relative;
  clear: both;
  background: #fff;
  text-align: center;
  padding: 100px 0px 100px 0px;
}
.how_game_sec a {
  display: block;
}
.how_game_sec button {
  position: relative;
  clear: both;
  height: 75px;
  text-align: center;
  width: 100%;
  max-width: 300px;
  color: #fff;
  font-weight: 700;
  font-size: 25px;
  outline: none;
  border: none;
  font-family: "Titillium Web", sans-serif;
}
.how_game_sec .btn1 {
  background: url(./assets/images/swap/how-one.png) no-repeat;
  background-size: cover;
}
.how_game_sec .btn2 {
  background: url(./assets/images/swap/how-two.png) no-repeat;
  background-size: cover;
}
.how_game_sec .btn3 {
  background: url(./assets/images/swap/how-three.png) no-repeat;
  background-size: cover;
}
.how_game_sec .up {
  position: absolute;
  left: 0px;
  width: 100%;
  bottom: -100px;
  text-align: center;
  display: none;
}
.how_btn_active .up {
  display: block;
}
.how_game_sec .how_btn1 .arrow-up {
  display: inline-block;
  vertical-align: bottom;
  width: 0;
  height: 0;
  border-left: 70px solid transparent;
  border-right: 70px solid transparent;
  border-bottom: 50px solid #0b9b96;
}
.how_game_sec .how_btn2 .arrow-up {
  display: inline-block;
  vertical-align: bottom;
  width: 0;
  height: 0;
  border-left: 70px solid transparent;
  border-right: 70px solid transparent;
  border-bottom: 50px solid #0ca6a9;
}
.how_game_sec .how_btn3 .arrow-up {
  display: inline-block;
  vertical-align: bottom;
  width: 0;
  height: 0;
  border-left: 70px solid transparent;
  border-right: 70px solid transparent;
  border-bottom: 50px solid #1299a1;
}
@media (max-width: 767px) {
  .how_game_sec {
    padding: 75px 0px;
  }
  .how_game_sec button {
    height: 60px;
    font-size: 20px;
  }
  .how_game_sec .how_btn1 .arrow-up {
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-bottom: 30px solid #0b9b96;
  }
  .how_game_sec .how_btn2 .arrow-up {
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-bottom: 30px solid #0ca6a9;
  }
  .how_game_sec .how_btn3 .arrow-up {
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-bottom: 30px solid #1299a1;
  }
  .how_game_sec .up {
    bottom: -75px;
  }
}
@media (max-width: 480px) {
  .how_game_sec {
    padding: 30px 0px;
  }
  .how_game_sec .col-xs-4 {
    padding: 0px 2px;
  }
  .how_game_sec button {
    height: 40px;
    font-size: 17px;
  }
  .how_game_sec .how_btn1 .arrow-up {
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-bottom: 20px solid #0b9b96;
  }
  .how_game_sec .how_btn2 .arrow-up {
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-bottom: 20px solid #0ca6a9;
  }
  .how_game_sec .how_btn3 .arrow-up {
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-bottom: 20px solid #1299a1;
  }
  .how_game_sec .up {
    bottom: -30px;
  }
}
@media (max-width: 414px) {
  .how_game_sec button {
    font-size: 12px;
  }
}
/*****How To main****/
.howto_main {
  position: relative;
  padding: 50px 0px;
  text-align: left;
  background: url(./assets/images/swap/howtobg.png) no-repeat;
  background-size: cover;
}
.howto_iner {
  position: relative;
  clear: both;
  padding: 0px 0px 0px 200px;
  margin-bottom: 30px;
  min-height: 150px;
}
.howto_iner .counter {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 140px;
  height: 140px;
  background: url(./assets/images/swap/sclips.png) no-repeat;
  background-size: cover;
  border-radius: 50%;
  border: 12px solid #0bb4a5;
  font-size: 80px;
  line-height: 110px;
  font-weight: 700;
  font-family: "Titillium Web", sans-serif;
  text-align: center;
  color: #e6e6e6;
}
.howto_iner h1 {
  text-align: left;
  color: #fff;
  font-size: 25px;
  font-weight: 700;
  margin: 0px;
  padding: 10px 0px 0px 0px;
  text-transform: uppercase;
}
.howto_iner p {
  text-align: left;
  color: #e7e7e7;
  font-size: 20px;
  font-weight: 300;
  margin: 0px;
  padding: 15px 0px 0px 0px;
  max-width: 900px;
  width: 100%;
}
@media (max-width: 991px) {
  .howto_iner {
    padding: 0px 0px 0px 150px;
  }
  .howto_iner .counter {
    width: 100px;
    height: 100px;
    border: 10px solid #0bb4a5;
    font-size: 60px;
    line-height: 75px;
  }
  .howto_iner h1 {
    font-size: 22px;
  }
  .howto_iner p {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .howto_main {
    padding: 50px 0px 0px 0px;
  }
  .howto_iner {
    padding: 0px;
  }
  .howto_iner .counter {
    width: 100px;
    height: 100px;
    border: 10px solid #0bb4a5;
    font-size: 60px;
    line-height: 75px;
    margin: auto auto 20px auto;
    position: relative;
  }
  .howto_iner h1 {
    font-size: 20px;
    text-align: center;
  }
  .howto_iner p {
    font-size: 16px;
    text-align: center;
  }
}
/***Point Tables***/
.game_points {
  position: relative;
  background: url(./assets/images/swap/gamerules.png) no-repeat;
  background-size: cover;
  padding: 50px 0px;
  font-family: "Prompt", sans-serif;
}
.game_points_table {
  position: relative;
  width: 100%;
}
.game_points_table table {
  border: none;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
}
.game_points_table th {
  background: #012223;
  text-align: center;
  padding: 10px;
  font-size: 25px;
  height: 100px;
  color: #ffffff;
  font-weight: 600;
  border: 0px;
}
.game_points_table th span {
  color: #ff7b00;
}
.game_points_table tr {
  background: #062020;
}
.game_points_table tr:nth-child(odd) {
  background: #012a2b;
}
.game_points_table tr:nth-child(odd) td:nth-child(even) {
  background: #023737;
}
.game_points_table tr:nth-child(odd) td:nth-child(7) {
  background: #0c9dc1;
}
.game_points_table tr:nth-child(even) td:nth-child(even) {
  background: #082828;
}
.game_points_table tr:nth-child(even) td:nth-child(7) {
  background: #0e657b;
}
.game_points_table td {
  height: 60px;
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  color: #fff;
}
@media (max-width: 1400px) {
  .game_points_table th {
    font-size: 17px;
  }
  .game_points_table td {
    font-size: 17px;
  }
}
@media (max-width: 1024px) {
  .game_points_table th {
    font-size: 15px;
  }
  .game_points_table td {
    font-size: 15px;
  }
}
@media (max-width: 991px) {
  .game_points {
    overflow-x: auto;
  }
  .game_points_table {
    width: 961px;
  }
}
/***Game Rules***/
.game_rules {
  position: relative;
  background: url(./assets/images/swap/gamerules.png) no-repeat;
  background-size: cover;
  padding: 50px 0px;
  font-family: "Prompt", sans-serif;
}
.game_rules_tables {
  position: relative;
  width: 100%;
}
.game_rules_tables table {
  border: none;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}
.game_rules_tables th {
  background: #0a8688;
  text-align: center;
  padding: 10px;
  font-size: 17px;
  height: 100px;
  line-height: 100px;
  color: #ffffff;
  cursor: pointer;
  font-weight: 500;
  border-top: 1px solid #d7d7d7;
  border-left: 1px solid #d7d7d7;
}
.game_rules_tables th a {
  color: #fff;
  line-height: normal;
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
}
.game_rules_tables th a:hover {
  color: #fff;
  text-decoration: none;
}
.game_rules_tables th:last-child {
  border-right: 1px solid #d7d7d7;
}
th.active_rules {
  background: #ff7b00;
}
.game_rules .tab-content {
  background: #fafafa;
  color: #444444;
  line-height: 30px;
  font-size: 19px;
  padding: 20px;
}
.game_rules ul,
ol {
  margin: 0px;
  padding: 10px 20px;
  border: 10px solid #fff;
  background: #fafafa;
  line-height: normal;
  list-style: none;
  padding: 0;
}
.game_rules li {
  display: inline-block;
  vertical-align: top;
  padding-left: 2em;
  width: 100%;
  color: #444444;
  line-height: 30px;
  font-size: 17px;
}
.game_rules li:before {
  color: #0c9dc1;
  content: "\f111"; /* FontAwesome Unicode */
  font-family: FontAwesome;
  display: inline-block;
  margin-left: -1.3em; /* same as padding-left set on li */
  width: 1.3em; /* same as padding-left set on li */
}
.game_rules li span {
  color: #0c9dc1;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}
@media (max-width: 1024px) {
  .game_rules_tables th {
    font-size: 14px;
  }
}
@media (max-width: 991px) {
  .game_rules {
    overflow-x: auto;
  }
  .game_rules_tables {
    width: 932px;
  }
  .game_rules ul {
    width: 932px;
  }
  .game_rules li {
    font-size: 15px;
  }
}
/**### End Slect US  ### **/
.google-add {
  position: relative;
  padding: 0px;
}
.google-add img {
  width: 100%;
}
/****Chnage Sports****/
.chnage-sports {
  position: relative;
  clear: both;
  padding: 20px 0px;
  background: #ffffff;
}
.chnage-sports h1 {
  padding: 0px 50px;
  text-align: center;
  font-size: 50px;
  text-transform: uppercase;
  /*color:#ff7c00;*/
  color: #000000;
  margin: 0px;
  font-weight: 900;
}
@media (max-width: 480px) {
  .chnage-sports h1 {
    font-size: 30px;
  }
}
@media (max-width: 414px) {
  .chnage-sports h1 {
    font-size: 20px;
  }
}
a {
  outline: none !important;
}
.slick-slide {
  outline: none;
}
.chnage-sports .left-arrow {
  position: absolute;
  left: 15px;
  top: 0%;
  width: 30px;
  z-index: 10;
  cursor: pointer;
}
.chnage-sports .right-arrow {
  position: absolute;
  right: 15px;
  top: 0%;
  width: 30px;
  z-index: 10;
  cursor: pointer;
}
@media (max-width: 600px) {
  .chnage-sports .left-arrow {
    width: 25px;
  }
  .chnage-sports .right-arrow {
    width: 25px;
  }
}
@media (max-width: 414px) {
  .chnage-sports .left-arrow {
    width: 20px;
  }
  .chnage-sports .right-arrow {
    width: 20px;
  }
}
/*** New Css ***/
body,
#livescore {
  background: #000;
  background-color: #000;
}
.contact-us {
  width: 100%;
}
.slick-slider .container-fluid {
  width: 100%;
  max-width: 100%;
}
/*
.select-game-slider {
    max-width:100%;
}*/

.container-fluid {
  max-width: 100%;
}
.stay-tuned {
  background: #000 !important;
}
.contact-us .contact-left {
  padding-left: 50px;
}
._faq_wrap .contact-right h1 {
  color: #ff7b00;
  font-size: 30px;
  background: url(./assets/images/visit-bg.png) repeat;
  font-weight: 300;
  margin: 0px;
  padding: 20px 0px;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 45%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
}

/*** Media Css ***/
@media (max-width: 767px) {
  .contact-us .contact-left {
    padding-left: 0px;
  }
  ._faq_wrap .contact-right h1 {
    font-size: 20px;
  }
}
/****Game Center 21s*****/
.game_center {
  position: relative;
  padding: 50px 0px;
  background: #000;
}
.game_center h1 {
  text-align: center;
  font-size: 35px;
  font-weight: 900;
  color: #ecdccd;
  margin: 0px;
  padding: 0px;
}
.game_center h1 span {
  color: #ff7b00;
}
.game_center h2 {
  width: 327px;
  height: 48px;
  font-family: Poppins;
  font-size: 32px;
  font-weight: bold;
  line-height: 1.5;
  color: #fb6e00;
  margin-left: 60px;
}

.drop-tab {
  cursor: pointer;
}
.progrs-list {
  top: 53px;
  padding: 0px;
  margin: 0px;
  border-radius: 0px;
  box-shadow: none !important;
  width: 100%;
}
.progrs-list ul {
  margin: 0px;
  padding: 0px;
  text-align: center;
  list-style: none;
}
.progrs-list li {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  text-align: center;
}
.progrs-list a {
  color: #333333;
  font-size: 17px;
  padding: 15px 0px !important;
  display: block;
  font-weight: 700 !important;
  text-decoration: none;
}
.progrs-list a:hover {
  background: none !important;
}
.progrs-list a:focus {
  background: none !important;
}
.progrs-list li:nth-child(1) {
  background: #01739e;
}
.progrs-list li:nth-child(1) a {
  color: #fff;
}
.progrs-list li:nth-child(2) {
  background: #ebebeb;
}
.progrs-list li:nth-child(3) {
  background: #dedede;
}
.progg {
  top: 43px;
}
.progg a {
  font-size: 22px;
  padding: 18px 0px;
}
@media (max-width: 991px) {
  .game_center h1 {
    font-size: 30px;
  }
  .game_center h2 {
    color: #ecdccd;
    font-size: 22px;
  }
  .game_center_table {
    position: relative;
    padding-top: 50px;
    overflow-x: auto;
  }
  .game_center_table table {
    width: 900px;
  }
  .game_center_table table th {
    font-size: 18px;
  }
  .game_center_table table td {
    font-size: 20px;
  }
  .progg a {
    font-size: 20px;
  }
}
/****CTA WKI****/
.cta_wki {
  position: relative;
  padding: 0px;
  background: url(./assets/images/contact-bg.png) no-repeat;
  background-size: cover;
}
.cta_wki .top-text h1 {
  text-align: center;
  color: #ff7b00;
  font-size: 35px;
  font-weight: 700;
  margin: 0px;
  padding: 50px 0px;
}
.cta_wki_table {
  position: relative;
  text-align: center;
}
.cta_wki_table table {
  border: none;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
}
.cta_wki_table table th {
  height: 100px;
  background: #02698f;
  text-align: center;
  padding: 10px;
  height: 65px;
  font-size: 25px;
  color: #fff;
  font-weight: 600;
  font-family: "Titillium Web", sans-serif;
}
.cta_wki_table table tr {
  background: rgba(17, 17, 17, 0.7);
  border-bottom: 2px solid #464646;
}
.cta_wki_table table tr:first-child {
  border: none;
}
.cta_wki_table table tr:last-child {
  border: none;
}
.cta_wki_table table td {
  padding: 10px;
  height: 65px;
  vertical-align: middle;
  text-align: center;
  border: 0px;
  font-size: 25px;
  color: #d5d5d5;
  font-weight: 600;
  font-family: "Titillium Web", sans-serif;
  border-left: 2px solid #464646;
}
.cta_wki_table table td:first-child {
  border-left: none;
}
.cta_wki .left-score {
  text-align: left;
}
.cta_wki .left-score .balance {
  position: relative;
  clear: both;
  text-align: left;
  margin-top: -12px;
}
.cta_wki .left-score .balance h4 {
  text-align: center;
  color: #ff7b00;
  font-size: 35px;
  font-weight: 700;
  margin: 0px;
  padding: 0px 0px 20px 0px;
  display: inline-block;
  max-width: 300px;
  width: 100%;
}
.cta_wki .left-score button {
  height: 60px;
  font-weight: 700;
  font-size: 20px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
}
.cta_wki .left-score .balance button {
  background: #02698f;
  width: 100%;
  display: inline-block;
  max-width: 300px;
  letter-spacing: 2px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
  text-align: center;
  color: #fefefe;
  font-size: 35px;
  font-weight: 700;
  outline: none;
  border: none;
  text-transform: uppercase;
}
@media (max-width: 991px) {
  .cta_wki .top-text h1 {
    font-size: 30px;
  }
  .cta_wki_table {
    position: relative;
    text-align: center;
  }
  .cta_wki_table table {
    border: none;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .cta_wki_table table th {
    font-size: 22px;
  }
  .cta_wki_table table td {
    font-size: 22px;
  }
  .cta_wki .left-score .balance {
    float: left;
    width: 100%;
    clear: both;
    margin-top: 30px;
    padding: 0px 20px 0px 20px;
  }
  .cta_wki .left-score .balance h4 {
    font-size: 30px;
  }
}
/****Current Score Game ****/
.current_score_game {
  position: relative;
  padding: 40px 0px;
  clear: both;
}
.current_score_game h1 {
  text-align: center;
  color: #ff7500;
  font-size: 45px;
  font-weight: 500;
  margin: 0px;
  padding: 0px;
  text-transform: uppercase;
}
.current_score_game h2 {
  text-align: center;
  color: #fff;
  font-size: 40px;
  font-weight: 200;
  margin: 0px;
  padding: 20px 0px;
}
.current_score_game h2 .infobtn {
  color: #ff7500;
}
.current_score_game button {
  position: relative;
  width: 100%;
  color: #373737;
  font-size: 30px;
  text-transform: uppercase;
  border: none;
  background: url(./assets/images/livescore/poll.png) no-repeat;
  background-size: cover;
  height: 80px;
  text-align: center;
  font-weight: 500;
  letter-spacing: 5px;
  margin-top: 15px;
  text-shadow: 1px 1px 1px #000;
}
@media (max-width: 991px) {
  .current_score_game h1 {
    font-size: 38px;
  }
  .current_score_game h2 {
    font-size: 32px;
  }
  .current_score_game button {
    font-size: 25px;
  }
}
@media (max-width: 767px) {
  .current_score_game h1 {
    font-size: 32px;
  }
  .current_score_game h2 {
    font-size: 27px;
  }
  .current_score_game button {
    font-size: 20px;
  }
}
/*****PowerTable*****/
.power_wki_table {
  position: relative;
  text-align: center;
  margin-bottom: 50px;
}
.power_wki_table h2 {
  background: #37a76d;
  padding: 12px 0px;
  text-align: center;
  color: #fff;
  font-size: 30px;
  font-family: "Titillium Web", sans-serif;
  font-weight: 600;
}
.power_tble_bnt {
  position: relative;
  padding: 20px 0px;
  background: #d8d8d8;
  text-align: center;
  display: table;
  width: 100%;
}
.power_tble_bnt h3 {
  color: #262626;
  font-size: 30px;
  font-weight: 600;
  font-family: "Titillium Web", sans-serif;
  margin: 0px;
  padding: 10px 20px;
  text-align: left;
  line-height: 30px;
}
.power_tble_bnt h3 span {
  float: right;
  color: #6d3500;
  font-size: 50px;
  font-weight: 600;
}
.power_tble_bnt button {
  width: 100%;
  display: inline-block;
  max-width: 250px;
  letter-spacing: 2px;
  text-shadow: 4px 4px 2px rgba(150, 150, 150, 1);
  text-align: center;
  height: 50px;
  color: #fefefe;
  font-size: 15px;
  font-weight: 400;
  background: -webkit-linear-gradient(
    340deg,
    rgb(255, 110, 2) 0%,
    rgb(255, 141, 1) 100%
  );
  background: -o-linear-gradient(
    340deg,
    rgb(255, 110, 2) 0%,
    rgb(255, 141, 1) 100%
  );
  background: -ms-linear-gradient(
    340deg,
    rgb(255, 110, 2) 0%,
    rgb(255, 141, 1) 100%
  );
  background: -moz-linear-gradient(
    340deg,
    rgb(255, 110, 2) 0%,
    rgb(255, 141, 1) 100%
  );
  background: linear-gradient(
    110deg,
    rgb(255, 110, 2) 0%,
    rgb(255, 141, 1) 100%
  );
  outline: none;
  border: none;
  text-transform: uppercase;
}
.power_wki_table table {
  border: none;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
}
.power_wki_table table th {
  height: 100px;
  background: #02698f;
  text-align: center;
  padding: 10px;
  height: 65px;
  font-size: 25px;
  color: #fff;
  font-weight: 600;
  border-radius: 0px;
  font-family: "Titillium Web", sans-serif;
}
.power_wki_table table th:first-child {
  border: none;
  border-radius: 0px 0px 0px 0px;
}
.power_wki_table table tr {
  background: rgba(17, 17, 17, 0.7);
  border-bottom: 2px solid #5f5f5f;
}
.power_wki_table table tr:first-child {
  border: none;
}
.power_wki_table table td {
  padding: 10px 0px;
  height: 65px;
  vertical-align: middle;
  text-align: center;
  border: 0px;
  font-size: 25px;
  color: #d5d5d5;
  font-weight: 600;
  font-family: "Titillium Web", sans-serif;
  border-left: 2px solid #5f5f5f;
}
.power_wki_table table td:first-child {
  border-left: none;
}
.drop-sport-list {
  padding: 0px;
  margin: 0px;
  border-radius: 0px;
  box-shadow: none !important;
  width: 100%;
}
.drop-sport-list ul {
  margin: 0px;
  padding: 0px;
  text-align: center;
  list-style: none;
}
.drop-sport-list li {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  text-align: center;
}
.drop-sport-list a {
  color: #333333;
  font-size: 17px;
  padding: 15px 0px !important;
  display: block;
  font-weight: 700 !important;
  text-decoration: none;
  background: #3ab475;
}
.drop-sport-list a:hover {
  background: none !important;
}
.drop-sport-list a:focus {
  background: none !important;
}

.power_wki_iner {
  position: relative;
  width: 100%;
  overflow-x: auto;
}
.power_wki_iner select {
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  background: url(./assets/images/chevron-down.png) right center no-repeat;
  width: 50px;
  border: none;
  outline: none;
  cursor: pointer;
}
.discount_offer {
  position: relative;
  padding: 15px;
  text-align: right;
  background: rgba(17, 17, 17, 0.7);
}
.discount_offer h4 {
  color: #f3ffa3;
  font-size: 30px;
  font-weight: 600;
  font-family: "Titillium Web", sans-serif;
  margin: 0px;
  padding: 5px 0px;
}
.discount_offer h4 span {
  color: #ff7500;
  display: inline-block;
  vertical-align: middle;
  margin: 0px 50px;
}
@media (max-width: 991px) {
  .power_wki_table {
    position: relative;
    text-align: center;
  }
  .power_wki_table table {
    border: none;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .power_wki_table table th {
    font-size: 22px;
  }
  .power_wki_table table td {
    font-size: 22px;
  }
  .power_wki_table h2 {
    font-size: 25px;
  }
  .power_tble_bnt h3 {
    font-size: 25px;
  }
  .power_tble_bnt h3 span {
    font-size: 40px;
  }
  .discount_offer h4 {
    font-size: 25px;
  }
}
@media (max-width: 767px) {
  .power_wki_table h2 {
    font-size: 20px;
  }
  .power_tble_bnt h3 {
    font-size: 20px;
    line-height: 40px;
  }
  .power_tble_bnt h3 span {
    font-size: 30px;
  }
  .discount_offer h4 {
    font-size: 20px;
  }
}
@media (max-width: 600px) {
  .power_wki_table table {
    border: none;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 600px;
  }
}
/*======= Login Page =======*/

.login-bg {
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  display: inline-block;
  padding: 75px 0px;
  vertical-align: top;
  width: 100%;
  background: url(./assets/images/login-bg.jpg) no-repeat;
  font-family: "Lato", sans-serif;
}
#login-sec-id .container {
  width: 100%;
  max-width: 100% !important;
  padding: 0px 15px;
  min-width: 1347px;
}
.login-inner-section {
  background: rgba(255, 255, 255, 0.5) none repeat scroll 0 0;
  height: 515px;
  width: 100%;
}
.welcome-sec {
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  float: left;
  height: 100%;
  padding: 30px;
  width: 50%;
  color: #fff;
  background: url(./assets/images/login-inner-bg.jpg) no-repeat;
}
.welcome-sec-content h3 {
  font-family: nike_combat_stencilregular;
  font-size: 40px;
  font-weight: 700;
  color: #fff;
}
.login-sec-form {
  width: 50%;
  float: left;
}
.login-sec-logo {
  padding: 20px;
  text-align: right;
  vertical-align: top;
}
.login-sec-logo > img {
  width: 120px;
}
.login-form {
  padding: 0 20px;
  color: #fff;
}
.form-title > h2 {
  margin-top: 0;
  vertical-align: top;
  font-family: "atleticoregular_demo";
}
.login-form-input-fields {
  padding: 55px 0;
}
.login-form-input-fields .form-group {
  margin-bottom: 10px;
  max-width: 62%;
}
.login-form-input-fields .form-control {
  background-color: transparent;
  border-bottom: 1px solid #fff;
  border-left: medium none !important;
  border-radius: 0;
  border-right: medium none !important;
  border-top: medium none !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  color: #fff;
  font-family: lato;
  font-size: 18px;
  font-style: oblique;
  font-weight: 300;
  height: 40px;
  padding: 5px 0px;
  vertical-align: top;
  width: 100%;
}
.login-form-input-fields input::-moz-placeholder {
  color: #fff !important;
  font-size: 18px;
  font-style: oblique;
  font-family: lato;
  font-weight: 300px;
}
.login-btn a.btn {
  background: #ff7c00 none repeat scroll 0 0;
  border: medium none;
  border-radius: 10px;
  display: inline-block;
  font-size: 24px;
  margin: 0;
  padding: 2px 10px;
  text-align: center;
  transition: all 0.8s ease 0s;
  vertical-align: middle;
  width: 315px;
  font-family: atleticoregular_demo;
}
.login-btn button.btn {
  background: #ff7c00 none repeat scroll 0 0;
  border: medium none;
  border-radius: 10px;
  display: inline-block;
  font-size: 24px;
  margin: 0;
  padding: 2px 10px;
  text-align: center;
  transition: all 0.8s ease 0s;
  vertical-align: middle;
  max-width: 315px;
  width: 100%;
  font-family: atleticoregular_demo;
}
.login-btn {
  padding-top: 20px;
  text-align: center;
}
.login-btn a.btn:hover,
.login-btn a.btn:focus {
  box-shadow: 320px 0 0 0 #fff inset;
  color: #ff7c00;
  border: 0px;
}
.login-btn > p {
  font-size: 15px;
}
.login-btn > p > a {
  color: #fff;
}
.login-btn a {
  color: #fff;
}
.other-page-link a {
  border-bottom: 2px solid #fff;
  font-family: lato;
  font-weight: 900;
}
.other-page-link a:hover {
  border-bottom: 2px solid #ff7c00;
  color: #ff7c00;
}
.register-bg {
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  display: inline-block;
  padding: 75px 0px;
  vertical-align: top;
  width: 100%;
  background: url(./assets/images/register-bg.jpg) no-repeat;
}
#register-sec-id .container {
  width: 100%;
  max-width: 100% !important;
  padding: 0px 15px;
  min-width: 1347px;
}
.register-inner-section {
  background: rgba(255, 255, 255, 0.5) none repeat scroll 0 0;
  height: 950px;
  width: 100%;
}
.register-title-sec {
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  float: left;
  height: 950px;
  padding: 30px;
  width: 50%;
  background: url(./assets/images/register-inner-bg.jpg) no-repeat;
}
.title-sec-content h2 {
  color: #fff;
  font-size: 48px;
  font-family: "atleticoregular_demo";
}
@media (max-width: 991px) {
  .title-sec-content h2 {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .title-sec-content h2 {
    font-size: 35px;
  }
}
@media (max-width: 600px) {
  .title-sec-content h2 {
    font-size: 30px;
  }
}
@media (max-width: 480px) {
  .title-sec-content h2 {
    font-size: 25px;
  }
}
.register-sec-form {
  float: left;
  width: 50%;
}
.forms-whole-fields {
  padding-top: 30px;
}
.register-form-input-fields .form-group {
  margin-bottom: 10px;
  max-width: 62%;
}
input::placeholder {
  color: grey;
  font-size: 18px;
  font-style: oblique;
  font-family: lato;
  font-weight: 300px;
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: grey;
  font-size: 18px;
  font-style: oblique;
  font-family: lato;
  font-weight: 300px;
  opacity: 1;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff !important;
  font-size: 18px;
  font-style: oblique;
  font-family: lato;
  font-weight: 300px;
  opacity: 1;
}
.register-form-input-fields input::-moz-placeholder {
  color: #fff !important;
  font-size: 18px;
  font-style: oblique;
  font-family: lato;
  font-weight: 300px;
}
.register-form-input-sub-fields input::-moz-placeholder {
  color: #fff !important;
  font-size: 18px;
  font-style: oblique;
  font-family: lato;
  font-weight: 300;
}
.country-field input::-moz-placeholder {
  color: #fff !important;
  font-size: 18px;
  font-style: oblique;
  font-family: lato;
  font-weight: 300px;
}
.register-form-input-fields .form-control {
  background-color: transparent;
  border-bottom: 1px solid #fff;
  border-left: medium none !important;
  border-radius: 0;
  border-right: medium none !important;
  border-top: medium none !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  color: #fff;
  font-family: lato;
  font-size: 18px;
  font-style: oblique;
  font-weight: 300;
  height: 40px;
  padding: 5px 0px;
  vertical-align: top;
  width: 100%;
}
.register-form-input-sub-fields .form-group {
  margin-bottom: 10px;
  width: 40%;
}
.register-form-input-sub-fields .form-control {
  background-color: transparent;
  border-bottom: 1px solid #fff;
  border-left: medium none !important;
  border-radius: 0;
  border-right: medium none !important;
  border-top: medium none !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  color: #fff;
  font-family: lato;
  font-size: 18px;
  font-style: oblique;
  font-weight: 300;
  height: 40px;
  padding: 5px 0px;
  vertical-align: top;
  width: 100%;
}
.country-field {
  padding: 15px 0 0;
}
.country-field .form-group {
  margin-bottom: 10px;
  width: 100%;
}
.country-field .form-control {
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: #2e4dae;
  background-image: url("./assets/images/chevron-down.png");
  background-position: 90% center;
  background-repeat: no-repeat;
  border: medium none;
  border-radius: 10px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  color: #fff;
  display: inline-block;
  font-family: lato;
  font-size: 18px;
  font-style: oblique;
  font-weight: 300;
  padding: 5px 10px;
  vertical-align: top;
  width: 40%;
}
.terms-and-conditions {
  padding-top: 10px;
}
.terms-and-conditions .checkbox-btn-option label::before {
  top: 3px;
}
.terms-and-conditions .checkbox-btn-option {
  display: inline-block;
  margin-left: -10px;
  text-align: left;
  vertical-align: middle;
}
.form-group > label {
  display: inline-block;
  font-weight: 300;
  width: 22%;
}
.terms-and-conditions > label {
  font-weight: 300;
}
.other-page-link {
  border-bottom: 1px solid #fff;
  font-family: lato;
  font-size: 18px;
  font-style: oblique;
  font-weight: 700;
}
.other-page-link a {
  text-decoration: none;
}
.other-page-link a:hover {
  text-decoration: none !important;
}
.other-page-link:hover {
  border-bottom: 1px solid #ff7c00;
  color: #ff7c00;
}
.redirect-to-sign-page {
  padding: 25px 10px;
  text-align: right;
}
.redirect-to-sign-page > p {
  font-weight: 300;
  margin: 0;
  text-align: center;
}
.redirect-to-sign-page > p > a {
  color: #fff;
}
.checkbox-btn-option {
  display: inline-block;
  vertical-align: middle;
}
.checkbox-btn-option > a {
  color: #fff;
}
.checkbox-btn-option input[type="radio"],
.checkbox-btn-option input[type="checkbox"] {
  cursor: pointer;
  height: 28px;
  margin: 4px 4px 0;
  opacity: 0;
  position: absolute;
  z-index: 1;
  width: 28px;
}
.checkbox-btn-option label::before {
  background: rgba(0, 0, 0, 0) url("./assets/images/check-box-unchecked.png")
    no-repeat scroll 0 0;
  content: "";
  display: inline-block;
  height: 28px;
  left: 10px;
  margin-right: 15px;
  position: relative;
  top: 8px;
  width: 28px;
  background-repeat: no-repeat !important;
}
.checkbox-btn-option input[type="radio"]:checked + label:before,
.checkbox-btn-option input[type="checkbox"]:checked + label:before {
  background: url(./assets/images/check-box-checked.png);
  height: 28px;
  width: 28px;
}
@media (max-width: 767px) {
  .welcome-sec {
    height: 170px;
    width: 100%;
    text-align: center;
  }
  .login-sec-form {
    width: 100%;
  }
  .form-title {
    text-align: center;
  }
  .login-inner-section {
    height: auto;
    display: table;
    width: 100%;
    padding-bottom: 30px;
  }
  .login-sec-logo {
    text-align: center;
  }
  .register-title-sec {
    height: 300px;
    padding: 30px;
    width: 100%;
  }
  .title-sec-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 240px;
    justify-content: center;
    width: 100%;
    text-align: center;
  }
  .register-sec-form {
    float: left;
    width: 100%;
  }
  .register-form-input-sub-fields .form-group {
    width: 30%;
    margin: 0 5px 5px;
  }
  .register-inner-section {
    height: 1080px;
    margin-top: 40px;
  }
  .country-field .form-control {
    width: 50%;
  }
  .country-field {
    padding: 10px 0 0;
  }
  .terms-and-conditions > label {
    font-weight: 300;
    font-size: 16px;
  }
  .register-form-input-fields .form-group {
    width: 100%;
    max-width: 100%;
  }
  .terms-and-conditions .checkbox-btn-option {
    vertical-align: top;
  }
}
a.white {
  color: #fff;
}
@media (max-width: 480px) {
  .sign-out-form {
    text-align: right;
  }
  .welcome-sec-content > h2 {
    font-size: 26px;
  }
  .welcome-sec-content h3 {
    font-size: 24px;
  }
  .login-btn a.btn {
    font-size: 20px;
    padding: 5px 10px;
    width: 150px;
  }
  .login-btn > p {
    font-size: 14px;
  }
  .register-bg {
    height: 1350px;
  }
  .register-title-sec {
    height: 220px;
    padding: 0;
  }
  .title-sec-content {
    height: 220px;
  }
  .title-sec-content > h2 {
    font-size: 22px;
  }
  .form-group > label {
    width: 32%;
  }
  .country-field .form-control {
    width: 65%;
  }
  .register-form-input-sub-fields .form-group {
    margin: 0 0 5px;
    width: 100%;
  }
  .register-inner-section {
    height: 1100px;
    margin-top: 40px;
  }
  .redirect-to-sign-page {
    padding: 0 15px 0 0;
  }
  .redirect-to-sign-page > p {
    font-size: 14px;
  }
  .other-page-link {
    font-size: 14px;
  }
  .checkbox-btn-option {
    display: inline-block;
    vertical-align: middle;
    width: 20%;
  }
  .terms-and-conditions > label {
    width: 78%;
  }
  .results-title {
    padding-top: 10px;
    vertical-align: top;
  }
  .results-title h3 {
    font-size: 20px;
  }
  .results-title a.btn {
    font-size: 16px;
    padding: 5px 10px;
    width: 180px;
  }
  .team-score-des h6 {
    font-size: 16px;
  }
}

/****Game Zones ****/
.game_zones {
  position: relative;
  padding: 20px 0px;
  background: url(./assets/images/game-zones/bg.jpg) no-repeat;
}
.game_zones .top-area {
  position: relative;
  text-align: center;
}
.game_zones .top-area h1 {
  color: #ecdccd;
  font-size: 35px;
  font-weight: 700;
  margin: 0px;
  padding: 0px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.game_zones .top-area h1 span {
  color: #ff7b00;
}
.game_zones .top-area h2 {
  color: #fff47a;
  font-size: 50px;
  font-weight: 700;
  margin: 0px;
  padding: 30px 0px;
  text-align: center;
  text-transform: uppercase;
}
.game_zones .top-area img {
  width: 100%;
  margin-bottom: 30px;
}
.game_zones .top-area .top-boxes {
  position: relative;
  text-align: center;
  clear: both;
  margin-top: 30px;
}
.game_zones .top-area .top-boxes .cont {
  display: inline-block;
  vertical-align: top;
  width: 20%;
  max-width: 150px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: #fff;
  font-weight: 700;
  font-size: 35px;
  margin: 10px 2%;
  background: #00bff3;
  border-radius: 20px;
  position: relative;
}
.game_zones .top-area .top-boxes .cont .counter {
  position: absolute;
  top: -25px;
  left: -25px;
  background: #ff4f4f;
  border-radius: 50%;
  border: 1px solid #000;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  font-size: 25px;
}
@media (max-width: 991px) {
  .game_zones .top-area h1 {
    font-size: 25px;
  }
  .game_zones .top-area h2 {
    font-size: 40px;
  }
  .game_zones .top-area .top-boxes .cont {
    width: 25%;
    max-width: 250px;
    margin-bottom: 40px;
    font-size: 30px;
  }
  .game_zones .top-area .top-boxes .cont .counter {
    font-size: 22px;
  }
}
@media (max-width: 600px) {
  .game_zones .top-area .top-boxes {
    padding: 0px 10%;
  }
  .game_zones .top-area h1 {
    font-size: 22px;
  }
  .game_zones .top-area h2 {
    font-size: 30px;
  }
  .game_zones .top-area .top-boxes .cont {
    width: 45%;
    max-width: 300px;
  }
}

/****Game Zone Score Area ****/
.game_zones_main {
  position: relative;
  clear: both;
  overflow-y: auto;
  max-height: 1235px;
  padding: 0px 15px;
  width: 100%;
  margin-bottom: 50px;
  margin-top: 30px;
}
.game_zones_score {
  position: relative;
  clear: both;
  margin-bottom: 30px;
}
.game_zones_score .left_box {
  position: relative;
  padding: 20px 0px;
  background: #fff;
  text-align: center;
}
.game_zones_score .right_box {
  position: relative;
  padding: 20px 0px;
  background: #f5f5f5;
}
.game_zones_score .boxes-left {
  font-size: 14px;
  position: relative;
  padding: 0px 0px 0px 20px;
  border-right: 1px solid #e1e1e1;
  text-align: center;
}
.game_zones_score .boxes-left .box-one {
  position: relative;
  padding: 10px 30px 0px 0px;
}
.game_zones_score .boxes-left .box-one:last-child {
  border-bottom: 0px;
  padding-bottom: 0px;
}
.game_zones_score .boxes-left .box-one .zones {
  position: relative;
  background: #e1e1e1;
  padding: 0px 10px;
  text-align: center;
  color: #6a6a6a;
  font-weight: 600;
  font-size: 15px;
  line-height: 75px;
  cursor: pointer;
}
.game_zones_score .boxes-left .box-one .zones-det {
  position: relative;
  text-align: center;
  padding: 0px;
  line-height: 75px;
  margin-bottom: 20px;
}
.game_zones_score .boxes-left .box-one .zones-det p {
  color: #0085bd;
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
  text-align: center;
  margin: 0px;
  text-transform: uppercase;
}
.game_zones_score .boxes-left .box-one .zones-det p .span1 {
  color: #ff7500;
  font-weight: 600;
  text-transform: none;
}
.game_zones_score .boxes-left .box-one .zones-det p .span2 {
  color: #0f0f0f;
  font-weight: 600;
  text-transform: none;
}
.game_zones_score .boxes-left .box-one .proj-run {
  background: #f0f0f0;
  padding: 0px;
  border: 1px solid #e1e1e1;
  line-height: 45px;
  height: 45px;
  text-align: center;
  color: #282828;
  font-size: 16px;
  font-weight: 600;
}
.game_zones_score .boxes-left .box-one .proj-totl {
  background: #0072bc;
  padding: 0px;
  border: 1px solid #0072bc;
  line-height: 45px;
  height: 45px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
.iner-zones {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  width: 100%;
}
.game_zones_score .boxes-right {
  position: relative;
  line-height: 240px;
  padding: 0px 20px;
}
.game_zones_score .boxes-right p {
  color: #0085bd;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0px;
  padding: 0px;
  text-align: center;
}
.game_zones_score .boxes-right p .span {
  color: #282828;
  font-size: 20px;
  font-weight: 600;
  text-transform: none;
}
.show_me {
  background: #ff7500 !important;
  border: 1px solid #ff7500 !important;
}
.zones_box {
  background: #39b54a !important;
  color: #fff !important;
}
.profrs_zone {
  background: #ff5252 !important;
  color: #fff !important;
}
.bottom_btn_live {
  position: relative; /*
	padding: 100px 20px 0px 0px;*/
  text-align: center;
}
button.btn_one_zones {
  position: relative;
  width: 100%;
  color: #fff5a5;
  font-size: 35px;
  text-transform: uppercase;
  border: none;
  background: url(./assets/images/livescore/total-score.png) no-repeat;
  background-size: cover;
  height: 100px;
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
  text-shadow: 2px 2px 2px #000;
}
button {
  outline: none;
}
button.btn_two_zones {
  position: relative;
  width: 100%;
  color: #fff;
  margin-top: 20px;
  font-size: 35px;
  text-transform: uppercase;
  border: none;
  background: url(./assets/images/livescore/score-bg.png) no-repeat;
  background-size: cover;
  height: 100px;
  text-align: center;
  font-weight: 700;
  text-shadow: 1px 1px 1px #000;
}
.game_zone_sub {
  position: relative;
  clear: both;
}
.game_zone_sub button {
  position: relative;
  width: 100%;
  color: #fff;
  font-size: 45px;
  text-transform: uppercase;
  border: none;
  background: url(./assets/images/game-zones/submit.jpg) no-repeat;
  background-size: cover;
  height: 100px;
  text-align: center;
  font-weight: 700;
  letter-spacing: 5px;
  margin-top: 15px;
  text-shadow: 1px 1px 1px #000;
}
.game_zone_btn {
  position: relative;
  clear: both;
  margin-top: 35px;
}
.game_zone_btn button {
  position: relative;
  width: 100%;
  color: #373737;
  font-size: 35px;
  text-transform: uppercase;
  border: none;
  background: url(./assets/images/livescore/poll.png) no-repeat;
  background-size: cover;
  height: 100px;
  text-align: center;
  font-weight: 500;
  letter-spacing: 5px;
  margin-top: 15px;
  text-shadow: 1px 1px 1px #000;
}
@media (max-width: 991px) {
  button.btn_two_zones {
    font-size: 30px;
  }
  button.btn_one_zones {
    font-size: 30px;
  }
  .game_zone_btn button {
    font-size: 30px;
  }
  .game_zone_sub button {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .game_zone_btn button {
    font-size: 25px;
  }
  .game_zone_sub button {
    font-size: 35px;
  }
  button.btn_two_zones {
    font-size: 25px;
  }
  button.btn_one_zones {
    font-size: 25px;
  }
}
.gamzone_price {
  position: relative;
  padding: 30px 0px 15px 0px;
  text-align: center;
  clear: both;
}
.gamzone_price h2 {
  text-align: center;
  color: #fff;
  font-size: 50px;
  font-weight: 200;
  margin: 0px;
  padding: 20px 0px;
}
.gamzone_price h2 .infobtn {
  color: #ff7500;
}
@media (max-width: 1170px) {
  .game_zones_score .left_box {
    width: 100%;
    display: table;
  }

  .game_zones_score .right_box {
    width: 100%;
    display: table;
  }

  .game_zones_score .boxes-left .box-one .zones {
    font-size: 13px;
  }
  .game_zones_score .boxes-left .box-one .zones-det p {
    font-size: 14px;
    line-height: 22px;
  }
  .game_zones_score .boxes-left .box-one .proj-run {
    font-size: 14px;
  }
  .game_zones_score .boxes-left .box-one .proj-totl {
    font-size: 14px;
  }
  .game_zones_score .boxes-right p {
    font-size: 12px;
  }
  .game_zones_score .boxes-right p .span {
    font-size: 18px;
  }
  .gamzone_price h2 {
    font-size: 40px;
  }
}
@media (max-width: 991px) {
  .gamzone_price h2 {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .game_zones_score .boxes-left {
    width: 100%;
    display: table;
    border: none !important;
    padding: 0px 15px;
  }
  .game_zones_score .boxes-right {
    display: table;
    width: 100%;
    clear: both;
    padding: 30px 0px;
    line-height: normal;
  }
  .game_zones_score .boxes-left .box-one {
    height: auto !important;
    margin-bottom: 20px;
    padding: 0px;
    width: 100%;
    display: table;
  }
  .game_zones_score .boxes-left .box-one .zones {
    line-height: normal;
    padding: 15px 0px;
    font-size: 15px;
  }
  .game_zones_score .boxes-left .box-one .zones-det {
    width: 100%;
    clear: both;
    line-height: normal;
    padding: 20px 0px;
    border: 1px solid #e1e1e1;
    margin: 0px;
    border-bottom: none;
  }
  .gamzone_price h2 {
    font-size: 25px;
  }
}
/***NEW Css****/
.add-btn {
  background: #ff7500;
  color: #fff;
  padding: 2px 15px;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}
.dropdown {
  cursor: pointer;
  width: 100%;
}
.drop-count {
  padding: 0px;
  margin: 15px 0px 0px 0px;
  border-radius: 0px;
  box-shadow: none !important;
  width: 100%;
  background: rgba(17, 17, 17, 0.1);
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}
.drop-count ul {
  margin: 0px;
  padding: 0px;
  text-align: center;
  list-style: none;
}
.drop-count li {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  text-align: center;
}
.drop-count li > a {
  color: #d5d5d5;
  font-size: 20px;
  padding: 15px 0px !important;
  display: block;
  font-weight: 600 !important;
  text-decoration: none;
  background: #000;
}
.al_east {
  position: relative;
  padding-bottom: 20px;
  text-align: center;
  width: 100%;
}
.al_east p {
  background: #7d4900;
  padding: 5px 20px;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  margin: 0px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
}

/****Power Play Page ****/
.power_live_box {
  padding: 0px 20px 0px 110px;
}
.score-icons {
  position: absolute;
  left: -110px;
  top: 53px;
  height: 50px;
  width: 50px;
  background: #f7941d;
  color: #000000;
  font-size: 25px;
  line-height: 50px;
  font-weight: 600;
  text-align: center;
  z-index: 100;
}

/****CTA WKI****/
.power_play_step2 {
  position: relative;
  padding: 0px;
  background: #000;
  background-size: cover;
}
.power_play_step2 .top-text h1 {
  text-align: center;
  color: #ff7b00;
  font-size: 35px;
  font-weight: 700;
  margin: 0px;
  padding: 50px 0px;
}
.power_play_table {
  position: relative;
  text-align: center;
}
.power_play_table table {
  border: none;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}
.power_play_table table th {
  height: 100px;
  background: #02698f;
  text-align: center;
  padding: 10px;
  height: 72px;
  font-size: 25px;
  color: #fff;
  font-weight: 600;
  font-family: "Titillium Web", sans-serif;
}
.power_play_table table tr:first-child {
  border-bottom: 2px solid #02698f;
}
.power_play_table table tr {
  background: #000;
  border-bottom: 2px solid #cdcdcd;
}
.power_play_table table td {
  padding: 10px;
  height: 72px;
  vertical-align: middle;
  text-align: center;
  border: 0px;
  font-size: 25px;
  color: #d5d5d5;
  font-weight: 600;
  font-family: "Titillium Web", sans-serif;
  border-left: 2px solid #cdcdcd;
}
.power_play_table table td:first-child {
  border-left: none;
}

.power_play_step2 .right-btm {
  position: relative;
  clear: both;
  padding: 20px 0px;
  text-align: center;
}
.power_play_step2 .right-btm h3 {
  text-align: center;
  color: #ff7b00;
  font-size: 35px;
  font-weight: 700;
  margin: 0px;
  padding: 30px 0px 20px 0px;
  width: 100%;
}
.power_play_step2 .right-btm button {
  width: 100%;
  display: inline-block;
  max-width: 400px;
  letter-spacing: 2px;
  text-shadow: 4px 4px 2px rgba(150, 150, 150, 1);
  text-align: center;
  color: #fefefe;
  background: -webkit-linear-gradient(
    340deg,
    rgb(255, 110, 2) 0%,
    rgb(255, 141, 1) 100%
  );
  background: -o-linear-gradient(
    340deg,
    rgb(255, 110, 2) 0%,
    rgb(255, 141, 1) 100%
  );
  background: -ms-linear-gradient(
    340deg,
    rgb(255, 110, 2) 0%,
    rgb(255, 141, 1) 100%
  );
  background: -moz-linear-gradient(
    340deg,
    rgb(255, 110, 2) 0%,
    rgb(255, 141, 1) 100%
  );
  background: linear-gradient(
    110deg,
    rgb(255, 110, 2) 0%,
    rgb(255, 141, 1) 100%
  );
  outline: none;
  border: none;
  text-transform: uppercase;
  height: 60px;
  font-weight: 700;
  font-size: 20px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
}
.power_play_step2 .right-btm button.btn-power {
  max-width: 300px;
  margin: 50px 0px;
}
.power_play_step2 .left-score {
  text-align: left;
}
.power_play_step2 .left-score .balance {
  position: relative;
  clear: both;
  text-align: left;
  margin-top: -12px;
}
.power_play_step2 .left-score .balance h4 {
  text-align: center;
  color: #ff7b00;
  font-size: 35px;
  font-weight: 700;
  margin: 0px;
  padding: 0px 0px 30px 0px;
  display: inline-block;
  max-width: 300px;
  width: 100%;
}
.power_play_step2 .left-score button {
  height: 60px;
  font-weight: 700;
  font-size: 20px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
}
.power_play_step2 .left-score .balance button {
  background: #02698f;
  width: 100%;
  display: inline-block;
  max-width: 300px;
  letter-spacing: 2px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
  text-align: center;
  color: #fefefe;
  font-size: 35px;
  font-weight: 700;
  outline: none;
  border: none;
  text-transform: uppercase;
}
.power-pla-nav {
  position: relative;
  width: 100%;
  text-align: center;
  border: 1px solid #ffa800;
  display: table;
}
.power-pla-nav ul {
  margin: 0px;
  padding: 0px;
  text-align: center;
  list-style: none;
}
.power-pla-nav li {
  float: left;
  width: 50%;
  text-align: center;
  cursor: pointer;
  color: #fff;
  font-size: 22px;
  font-weight: 500;
}
.power-pla-nav a {
  color: #fff;
  text-decoration: none;
  height: 50px;
  line-height: 50px;
  display: block;
}
li.power-sel {
  background: #ffa800;
}
li.power-sel a {
  color: #000000;
}
@media (max-width: 991px) {
  .power_play_step2 .left-score .balance {
    float: left;
    width: 100%;
    clear: both;
    margin-top: 30px;
    padding: 0px 20px 0px 20px;
  }
  .power_play_step2 .left-score .balance h4 {
    font-size: 30px;
  }
  .power_play_step2 .top-text h1 {
    font-size: 30px;
  }
  .power_play_table table th {
    font-size: 22px;
  }
  .power_play_table table td {
    font-size: 22px;
  }
  .score-icons {
    top: 45%;
  }
}

/****PowerPlay Syep 1****/
.power_play_step1 {
  position: relative;
  clear: both;
  padding: 50px 0px;
}
.power_play_step1 .steps-top {
  position: relative;
  text-align: center;
}
.power_play_step1 .steps-top .box1 {
  float: left;
  width: 175px;
  text-align: center;
  background: #000;
  z-index: 100;
  position: relative;
}
.power_play_step1 .steps-top .box1 p {
  text-align: center;
  color: #ffa800;
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
  padding: 10px 0px;
}
.power_play_step1 .steps-top .box1 .boxes {
  display: inline-block;
  vertical-align: top;
  width: 75px;
  height: 75px;
  line-height: 75px;
  border-radius: 50%;
  background: #ff7a02;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}
.power_play_step1 .steps-top .box2 {
  float: right;
  width: 175px;
  text-align: center;
  background: #000;
  z-index: 100;
  position: relative;
}
.power_play_step1 .steps-top .box2 p {
  text-align: center;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
  padding: 10px 0px;
}
.power_play_step1 .steps-top .box2 .boxes {
  display: inline-block;
  vertical-align: top;
  width: 75px;
  height: 75px;
  line-height: 75px;
  border-radius: 50%;
  background: #cfcfcf;
  color: #535050;
  font-size: 18px;
  font-weight: 500;
}
.power_play_step1 .steps-top .dots-top {
  border-top: 10px dotted #ff7802;
  position: absolute;
  top: 35px;
  left: 0px;
  width: 100%;
  z-index: 99;
}
.power-play-top {
  position: relative;
  background: #999999;
  width: 100%;
  border-radius: 5px;
}
.power-play-label {
  display: inline-block;
  vertical-align: middle;
  padding: 0px 10px;
  margin-right: 15px;
  width: 100%;
  max-width: 165px;
  font-weight: 600;
  text-align: center;
  color: #1a1818;
  border-right: 2px solid #818181;
}
.power-play-top .iners {
  display: inline-block;
  vertical-align: middle;
  line-height: 30px;
  width: 100%;
  max-width: 150px;
}
.power-play-top label {
  font-size: 14px;
  color: #fff;
  font-weight: 500;
}
.power-play-top .checkbox-btn-option {
  display: inline-block;
  margin-left: 0px;
  text-align: left;
  vertical-align: middle;
  color: #fff;
}
.power-play-main {
  position: relative;
  width: 100%;
  background: #fff;
  display: table;
  padding: 0px 5%;
}
.power-play-main .top-search {
  position: relative;
  width: 100%;
  margin: 50px 0px;
  background: #e1e1e1;
  height: 50px;
  padding: 0px 50px 0px 0px;
  border-radius: 5px;
}
.power-play-main .top-search input {
  position: relative;
  width: 100%;
  background: none;
  height: 50px;
  text-indent: 15px;
  font-weight: 500;
  font-size: 20px;
  border: none;
  outline: none;
  color: #ff8601;
}
.power-play-main .top-search input::-webkit-input-placeholder {
  color: #ff8601;
  opacity: 1;
}
.power-play-main .top-search input::-moz-placeholder {
  color: #ff8601;
  opacity: 1;
}
.power-play-main .top-search input:-ms-input-placeholder {
  color: #ff8601;
  opacity: 1;
}
.power-play-main .top-search input:-moz-placeholder {
  color: #ff8601;
  opacity: 1;
}
.power-play-main .top-search button {
  width: 50px;
  line-height: 50px;
  height: 50px;
  text-align: center;
  color: #ff8601;
  outline: none;
  border: none;
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 20px;
  background: none;
}
.power-play-main .left-games {
  position: relative;
  width: 100%;
  background: #f2f2f2;
  margin-top: 35px;
  overflow-y: auto;
  height: 700px;
  padding: 50px 20px 30px 20px;
}
.power-play-main .left-games .iner-box {
  background: url(./assets/images/step/box1.png) no-repeat;
  background-size: cover;
  padding: 10px 5px;
  display: table;
  width: 100%;
  border: 1px solid #c1c1c1;
  margin-bottom: 10px;
}
.rgh-pow-bord {
  border-right: 1px solid #a7a7a7;
}
.power-play-main .left-games .iner-box .box {
  position: relative;
  width: 100%;
  height: 120px;
  line-height: 120px;
  padding: 0px 0px 0px 90px;
}
.power-play-main .left-games .iner-box .box .iner-lengt {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  width: 100%;
}
.power-play-main .left-games .iner-box .box .left {
  position: absolute;
  left: 0px;
  top: 25px;
  display: inline-block;
  vertical-align: middle;
  height: 70px;
  width: 70px;
  line-height: 70px;
  border: 1px solid #9a9a9a;
  color: #000;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
}
.sel-power {
  background: #f7941d;
}
.right {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  resize: vertical;
}
.right span {
  color: #ff7802;
  font-size: 18px;
  font-weight: 600;
}
.yel-bord {
  border-right: 2px solid #f7992a;
}
.power-play-main .right-games {
  position: relative;
  width: 100%;
  background: #e1e1e1;
  display: table;
}
.power-play-main .right-games h1 {
  text-align: left;
  padding: 15px 0px 15px 30px;
  color: #f7992a;
  font-size: 20px;
  font-weight: 700;
  margin: 0px;
  border-bottom: 1px solid #8f8f8f;
}
.power-play-main .right-games .iner-box {
  position: relative;
  border-bottom: 1px solid #d3d3d3;
}
.power-play-main .right-games .iner-box h2 {
  text-align: left;
  margin: 0px;
  font-weight: 500;
  font-size: 17px;
  padding: 15px 0px 15px 35px;
  border-bottom: 1px solid #8f8f8f;
  color: #000000;
}
.power-play-main .right-games .iner-box .box {
  height: 84px;
  line-height: 84px;
  padding: 0px 50px 20px 35px;
  position: relative;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
}
.power-play-main .right-games .iner-box .box .iner-lengt {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  width: 100%;
}
.power-play-main .right-games .iner-box .box .del {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  text-align: right;
  line-height: 40px;
  border-left: 2px solid #818181;
  color: #f7992a;
  font-size: 20px;
}
.add-btn-power {
  position: absolute;
  top: 0px;
  z-index: 100;
}
.team-player {
  position: relative;
  text-align: center;
  padding: 50px 0px 30px 0px;
}
.team-player h1 {
  text-align: center;
  color: #fffefe;
  font-size: 32px;
  font-weight: 600;
  margin: 0px;
  padding: 0px;
  text-transform: uppercase;
}
.next-btn {
  position: relative;
  text-align: center;
  padding: 50px 0px 20px 0px;
}
.next-btn button {
  background: url(./assets/images/step/next.png) no-repeat;
  background-size: cover;
  text-align: center;
  height: 65px;
  width: 100%;
  max-width: 400px;
  color: #fff;
  font-size: 35px;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  outline: none;
  border: none;
  border-radius: 5px;
}

@media (max-width: 991px) {
  .power_play_step1 .steps-top .box1 {
    width: 125px;
  }
  .power_play_step1 .steps-top .box1 p {
    font-size: 12px;
  }
  .power_play_step1 .steps-top .box1 .boxes {
    font-size: 16px;
  }
  .power_play_step1 .steps-top .box2 {
    width: 125px;
  }
  .power_play_step1 .steps-top .box2 p {
    font-size: 12px;
  }
  .power_play_step1 .steps-top .box2 .boxes {
    font-size: 16px;
  }
  .team-player h1 {
    font-size: 27px;
  }
  .next-btn button {
    height: 60px;
    font-size: 28px;
  }
  .right {
    font-size: 15px;
  }
  .right span {
    font-size: 16px;
  }
  .yel-bord {
    border: none;
  }
}
@media (max-width: 767px) {
  .rgh-pow-bord {
    border-bottom: 1px solid #a7a7a7;
    border-right: 0px;
  }
  .power-play-main {
    padding: 0px;
  }
  .power-play-main .left-games .iner-box .box {
    padding-top: 20px;
    padding-bottom: 20px;
    line-height: normal;
    height: auto;
  }
  .power-play-main .left-games {
    padding: 50px 10px 30px 10px;
    margin-top: 55px;
  }
  .power-play-main .left-games .iner-box .box .left {
    top: 25px;
  }
}
@media (max-width: 480px) {
  .power-play-top .checkbox-btn-option {
    margin-right: 15px;
  }

  .right {
    font-size: 12px;
  }
  .right span {
    font-size: 13px;
  }
}

/* slick.css */
/* Slider */
.slick-slider {
  position: relative;

  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;

  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  display: table;

  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}

/* slick-theme.css */
/* @charset 'UTF-8'; */
/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;

  position: absolute;
  top: 50%;

  display: block;

  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);

  cursor: pointer;

  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;

  opacity: 0.75;
  color: white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir="rtl"] .slick-prev {
  right: -25px;
  left: auto;
}
.slick-prev:before {
}
[dir="rtl"] .slick-prev:before {
}

.slick-next {
  right: -25px;
}
[dir="rtl"] .slick-next {
  right: auto;
  left: -25px;
}
.slick-next:before {
}
[dir="rtl"] .slick-next:before {
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;

  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}
.slick-dots li {
  position: relative;

  display: inline-block;

  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;

  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 20px;
  height: 20px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 20px;
  height: 20px;

  content: "•";
  text-align: center;

  opacity: 0.25;
  color: black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}

.c-p {
  cursor: pointer;
}
.modal-title {
  font-family: Poppins;
  font-size: 26px;
  font-weight: 500;
  color: #ffffff;
}
